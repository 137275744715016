const Free = () => (
  <svg
    width="177"
    height="177"
    viewBox="0 0 177 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="home-plan-image"
  >
    <circle cx="88.5" cy="88.5" r="88.5" fill="white" />
    <circle cx="88.6438" cy="88.6438" r="72.1438" stroke="#C3C3C3" />
    <path
      d="M42.1675 106.104L74.0083 38.123H83.8125L88.4886 48.1038L93.2486 58.2664L115.653 106.104H106.128L88.4886 68.4355L83.7285 58.2728L78.9104 47.9869L51.6932 106.104H42.1675Z"
      fill="#58C1F5"
    />
    <path
      d="M61.2778 106.104L93.1182 38.123H102.922L107.599 48.1038L112.359 58.2664L134.758 106.091L125.248 106.123L107.599 68.4355L102.838 58.2728L98.0203 47.9869L70.8031 106.104H61.2778Z"
      fill="#58C1F5"
    />
    <path
      d="M64.8024 126.023H72.3824V126.943H64.8024V126.023ZM64.9224 133.123H63.9024V119.123H73.2624V120.043H64.9224V133.123ZM76.5196 133.123V119.123H81.5396C82.7129 119.123 83.7196 119.31 84.5596 119.683C85.3996 120.056 86.0463 120.596 86.4996 121.303C86.9529 121.996 87.1796 122.843 87.1796 123.843C87.1796 124.816 86.9529 125.656 86.4996 126.363C86.0463 127.056 85.3996 127.596 84.5596 127.983C83.7196 128.356 82.7129 128.543 81.5396 128.543H77.0796L77.5396 128.063V133.123H76.5196ZM86.2396 133.123L82.5996 128.043H83.7596L87.3996 133.123H86.2396ZM77.5396 128.123L77.0796 127.643H81.5396C83.0463 127.643 84.1863 127.31 84.9596 126.643C85.7463 125.976 86.1396 125.043 86.1396 123.843C86.1396 122.63 85.7463 121.696 84.9596 121.043C84.1863 120.376 83.0463 120.043 81.5396 120.043H77.0796L77.5396 119.543V128.123ZM91.8141 125.563H99.3941V126.463H91.8141V125.563ZM91.9341 132.203H100.574V133.123H90.9141V119.123H100.274V120.043H91.9341V132.203ZM105.193 125.563H112.773V126.463H105.193V125.563ZM105.313 132.203H113.953V133.123H104.293V119.123H113.653V120.043H105.313V132.203Z"
      fill="#C3C3C3"
    />
  </svg>
);

export default Free;
