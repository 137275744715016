/**
 * Downloads a file from a Blob or a URL.
 * @param {Blob|string} blob - The Blob or URL of the file to download.
 * @param {string} filename - The name of the file to download.
 */
const downloadFile = (blob, filename) => {
  let fileUrl;
  if (blob instanceof Blob) {
    fileUrl = URL.createObjectURL(blob);
  } else {
    fileUrl = blob;
  }
  const fileLink = document.createElement('a');
  fileLink.href = fileUrl;
  fileLink.setAttribute('download', filename);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
  if (blob instanceof Blob) {
    URL.revokeObjectURL(fileUrl);
  }
};

/**
 * Converts a Base64 string to a Uint8Array.
 * @param {string} base64 - The Base64 string to decode.
 * @returns {Uint8Array} - The resulting Uint8Array.
 */
/**
 * Converts a Base64 string to a Uint8Array.
 * @param {string} base64 - The Base64 string to decode.
 * @returns {Uint8Array} - The resulting Uint8Array.
 */
const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

/**
 * Downloads a PDF file from a Base64 string.
 * @param {string} base64 - The Base64 string of the PDF file.
 * @param {string} filename - The name of the file to download.
 */
const downloadBase64Pdf = (base64, filename) => {
  const uint8Array = base64ToUint8Array(base64);
  const blob = new Blob([uint8Array], { type: 'application/pdf' }); // Create a Blob
  downloadFile(blob, filename);
};

export { downloadFile, base64ToUint8Array, downloadBase64Pdf };
