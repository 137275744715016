import { useParams, useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { useEffect, useMemo, useState } from 'react';
import { notification, Select, Radio, Collapse, Button } from 'antd';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import GroupSlider from 'antd-group-slider';
import { FaExclamationCircle } from 'react-icons/fa';
import AddQuestions from '../../components/AddQuestions/AddQuestions';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import TestGroupsItem from '../../components/TestGroupsItem/TestGroupsItem';
import DropDownSelect from '../../components/DropdownSelect/DropdownSelect';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import CreateTestGroupSuccess from '../../components/CreateTestGroupSuccess/CreateTestGroupSuccess';
import TimePicker from '../../components/TimePicker/TimePicker';
import GenericToggle from '../../components/GenericToggle/GenericToggle';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import useCreateTestGroup from '../../hooks/useCreateTestGroup';
import Spinner from '../../components/spinner/Spinner';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import Modal from '../../components/Modal/Modal';
import './CreateTestGroup.scss';
import useImportCsv from '../../hooks/useImportCsv';
import ModalCustom from '../../components/ModalCustom/ModalCustom';
import upload from '../../assets/sentScreening/upload.svg';
import {
  AnswerTypeEnum,
  MechanografyTypeEnum,
  TAGS_TYPE,
  TestEditionAction,
  TestFileType,
  languagesKey,
} from '../../constants/enums';
import {
  validateIfContainsBasicData,
  validateTestOptions,
  validBooleanConversion,
} from '../../utils/validations/importTestExcel';
import useExportCsv from '../../hooks/useExportCsv';
import BlockRedirection from '../../components/BlockRedirection/BlockRedirection';
import { escapeStrings } from '../../utils/stringUtils';
import Checkbox from '../../components/Checkbox/Checkbox';
import UploadTestFiles from '../../components/UploadTestFiles/UploadTestFiles';
import { setNewScreeningData } from '../../redux/actions/screeningActions';
import { formatOrder } from '../../utils/formatArrayOrder';
import { languagesList } from '../../components/DropdownLanguage/DropdownLanguage';
import CountryFlag from '../../components/CountryFlag/CountryFlag';
import useTranslations from '../../hooks/useTranslations';
import 'antd-group-slider/dist/index.css';
import TreeMultiSelect from '../../components/TreeMultiSelect/TreeMultiSelect';
import { GenericTextArea } from '../../components/GenericTextArea/GenericTextArea';
import EvaluationPreview from '../../components/EvaluationPreview/EvaluationPreview';
import SafeContainer from '../../components/SafeContainer/SafeContainer';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';

const { Panel } = Collapse;
const { Option } = Select;
const CreateTestGroup = () => {
  const { importRef, readFile, result, setResult } = useImportCsv([
    'id',
    'difficulty',
    'answerType',
    'answerTitle',
    'weightScore',
    'theme',
    'text',
    'textFileNameAttached',
    'option',
    'optionFileNameAttached',
    'weight',
    'correct',
    'pinned',
    'maxScore',
    'maxAttempts',
    'preparationTime',
    'totalTime',
  ]);
  const { exportFile, setTestDifficulty } = useExportCsv();
  const [showModal, setShowModal] = useState(false);
  const [valueFile, setValueFile] = useState('');
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    newScreening,
    testGroupLevelsFilterOptions,
    testGroupCategoriesFilterOptions,
    customTestGroupTotalTime,
    setEditData,
    handleDelete,
    handleCustomTestGroupTime,
  } = useCreateScreening();
  const {
    handleAddExcelQuestions,
    handleModifyData,
    handleModifyLangData,
    handleCreateQuestionsSet,
    handleSelectDifficulty,
    handleSelectCategoryOption,
    handleSelectCategoryOptionMultiple,
    handleSelectLevelOption,
    handleSelectMinimumTierOption,
    handleSelectLanguageOption,
    handleSelectObjetivesOption,
    handleDeleteSetTestGroup,
    handleOrderSetQuestions,
    handlePostData,
    setTestgroupWithThemes,
    testgroupWithThemes,
    disabledCreateSet,
    disabled,
    creationSuccess,
    setChallengeAlkemy,
    handleFilterChange,
    onBlurInputNumber,
    testGroupData,
    challengeAlkemy,
    technologies,
    setTechnologies,
    setTestGroupQuantityQuestions,
    testGroupQuantityQuestions,
    testGroupDifficult,
    testGroupQuestions,
    submitLoading,
    isLoading,
    isEdit,
    handlePatchData,
    testGroupRolesFilterOptions,
    testGroupTagsFilterOptions,
    endorsementCreatorsOptions,
    endorsementCompaniesOptions,
    handleSelectTagsOptions,
    handleSelectEndorsementOptions,
    handleSelectCompanyUsageOptions,
    handleSelectRolesOptionsMultiple,
    techChallengeAlkemy,
    testDifficulty,
    availableThemes,
    handleSelectTheme,
    testGroupTheme,
    formatDifficultyConfig,
    testQuantityToMap,
    testGroupInitialData,
    customTestgroupInitialData,
    testgroupInitialDuration,
    patchSuccess,
    setPatchSuccess,
    testGroupListeningTest,
    handleAudioDifficulty,
    handleHideTitle,
    handleIsAutomaticContinue,
    handleWithoutTime,
    handleScoreTags,
    groupSliderInitialData,
    toggleScoreTags,
    setToggleScoreTags,
    testFormats,
    handleSelectTestFormats,
    step,
    handleSetAssignment,
    testGroupScoreTags,
  } = useCreateTestGroup(id);
  const { key } = useTranslations();
  const [show, setShow] = useState(false);
  const [selectedLang, setSelectedLang] = useState(key);
  const [modalImage, setModalImage] = useState(false);
  const [itemDelete, setItemDelete] = useState();
  const [modifiedCustomTestGroup, setModifiedCustomTestGroup] = useState([]);
  const [imageUploadMasive, setImageUploadMasive] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [haveImportTestFile, setHaveImportTestFile] = useState(false);
  const [imagesImportFileBatch, setImagesImportFileBatch] = useState([]);
  const maxBytesFile = 1000000;

  const history = useHistory();

  useEffect(() => {
    setPatchSuccess(false);
    // fetchTags({ types: 'MARKETING' });
  }, []);

  const translateExcelToTest = (excelResult) => {
    // Here we receive the excel with the custom fields and go through every row translating it into tests
    let importError = false;
    const translatedTests = [];
    let tempTest;

    const optionsAreValid = () => {
      const invalidOptions = validateTestOptions(tempTest);
      if (invalidOptions) {
        importError = true;
        notification.open({
          message: t(invalidOptions)
            .replaceAll('{score}', tempTest.weight)
            .replaceAll('{index}', translatedTests.length + 1),
          type: 'error',
        });
        setHaveImportTestFile(false);
        return false;
      }
      return true;
    };

    const tryAddTempTest = () => {
      if (!tempTest.eachScore) {
        if ([AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(tempTest.answerType)) {
          tempTest.answerType =
            tempTest.options.filter((item) => item.correct).length > 1
              ? AnswerTypeEnum.CHECK
              : AnswerTypeEnum.RADIO;
        }
        if ([AnswerTypeEnum.WRITE].includes(tempTest?.answerType) && tempTest?.options) {
          tempTest.writeResponse = tempTest?.options[0]?.text;
        }
      } else {
        tempTest.answerType = AnswerTypeEnum.RADIO;
      }
      if (tempTest.theme && testGroupData?.withThemes) {
        setTestgroupWithThemes(true);
      }
      translatedTests.push(tempTest);
    };

    for (let i = 0, l = excelResult.length; i < l; i += 1) {
      const row = excelResult[i];

      if (row.text !== '') {
        /* In case we have the text of the question in the row, we know we are at the beginning of a test,
        so we save the tempTest in case we already have one and proceed to store the new one */
        if (tempTest) {
          if (!optionsAreValid()) break;
          tryAddTempTest();
        }

        const invalidBasic = validateIfContainsBasicData(row, testDifficulty);

        if (invalidBasic) {
          importError = true;
          notification.open({
            message: t(invalidBasic).replaceAll('{index}', translatedTests.length + 1),
            type: 'error',
          });
          break;
        }
        tempTest = {
          id: typeof row.id === 'string' ? row.id : undefined,
          name: escapeStrings(row?.answerTitle) ?? '',
          difficultyId: testDifficulty.find((item) => item.name === row.difficulty?.toUpperCase())
            .id,
          weight:
            Number(row.maxScore) ??
            testDifficulty.find((item) => item.name === row.difficulty?.toUpperCase()).score,
          answerType: row.answerType,
          theme: escapeStrings(row.theme),
          text: escapeStrings(row.text),
          eachScore: validBooleanConversion(row.weightScore),
          hasGlobalScore: !validBooleanConversion(row.weightScore),
          textFileNameAttached: row?.textFileNameAttached ?? undefined,
          options: [],
          ...(row.answerType === AnswerTypeEnum.CODE || row.answerType === AnswerTypeEnum.CODE_AI
            ? {
                expectedResult: [],
              }
            : {}),
        };
      }
      const lastIndex = tempTest.options.length;
      tempTest.options.push({
        text: escapeStrings(row?.option?.toString()),
        optionFileNameAttached: row?.optionFileNameAttached ?? undefined,
        ...(tempTest.eachScore
          ? {
              weight: Number(row.weight),
            }
          : {
              correct: validBooleanConversion(row.correct),
            }),
        ...(validBooleanConversion(row.pinned)
          ? {
              pinnedIndex: lastIndex,
            }
          : {}),
      });
      switch (tempTest.answerType) {
        case AnswerTypeEnum.WRITE:
          tempTest.writeResponse = row?.option;
          tempTest.isTyping = row?.weightScore === MechanografyTypeEnum.MECHANOGRAFY;
          break;
        case AnswerTypeEnum.RADIO:
        case AnswerTypeEnum.CHECK:
          tempTest.answerType =
            tempTest.options.filter((item) => item.correct).length > 1
              ? AnswerTypeEnum.CHECK
              : AnswerTypeEnum.RADIO;
          break;
        case AnswerTypeEnum.FILE:
          tempTest.fileTypes = row?.option.split(';');
          break;
        case AnswerTypeEnum.VIDEO:
          tempTest.totalTime = tempTest?.totalTime ?? row?.option;
          tempTest.preparationTime = tempTest?.preparationTime ?? row?.optionFileNameAttached;
          tempTest.maxAttempts = tempTest.maxAttempts ?? row?.weight;
          break;
        case AnswerTypeEnum.TEXT:
          tempTest.maxChar = Number(row?.weight);
        case AnswerTypeEnum.TEXT_AI:
          tempTest.concepts = escapeStrings(row?.option);
          tempTest.maxChar = Number(row?.weight);
          tempTest.weight = '1';
          break;
        case AnswerTypeEnum.CODE:
        case AnswerTypeEnum.CODE_AI:
          tempTest.existingCode = escapeStrings(tempTest.existingCode ?? row.weight);
          tempTest.expectedResult.push({
            input: escapeStrings(row?.option),
            output: escapeStrings(row?.optionFileNameAttached),
          });
          tempTest.langCode = tempTest.langCode ?? languagesKey[row?.weightScore];
          tempTest.langVersion = tempTest.langCode;
          tempTest.langDisplayName = tempTest.langDisplayName ?? row?.weightScore;
          tempTest.options[0].correct = true;
          if (tempTest.langCode === 'sql') {
            tempTest.expectedResult = tempTest.expectedResult?.map((caseTest) => ({
              ...caseTest,
              input: '',
            }));
          }
          break;
        default:
          break;
      }
    }
    /* We push the last test */
    if (!importError && tempTest) {
      optionsAreValid();
      tryAddTempTest();
    }

    if (importError) {
      setValueFile('');
      return [];
    }
    return translatedTests;
  };

  useEffect(() => {
    if (testDifficulty?.length) {
      setTestDifficulty(testDifficulty);
    }
  }, [testDifficulty]);

  useEffect(() => {
    if (result?.length) {
      // We ignore the first row since it contains the headers.
      result.splice(0, 1);
      const translatedTests = translateExcelToTest(result);
      setValueFile('');
      if (translatedTests.length > 0) {
        handleAddExcelQuestions(translatedTests, isEdit);
      } else {
        setResult(null);
      }
    }
  }, [result]);

  useEffect(() => {
    if (!showModal) {
      setValueFile('');
    }
  }, [showModal]);

  useEffect(() => {
    if (!testGroupData?.languages?.includes(selectedLang) && testGroupData?.languages?.length) {
      setSelectedLang(testGroupData?.languages[0]);
    }
  }, [testGroupData?.languages]);

  useEffect(() => {
    setModifiedCustomTestGroup(
      newScreening.customTestGroup.map((item) => ({ ...item, selected: false }))
    );
  }, [newScreening.customTestGroup]);

  const handleSelectTest = (id) => {
    setModifiedCustomTestGroup(
      modifiedCustomTestGroup.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleAddMasiveTestFile = (file, fileType) => {
    if (file.size > maxBytesFile) {
      notification.open({
        message: t('UPLOAD_FILE_ERROR'),
        type: 'error',
      });
    } else {
      let flagFirstFile = true;
      const newOptions = modifiedCustomTestGroup.map((item) => {
        if (!item.selected) {
          return item;
        }
        const formattedFile = {
          ...file,
          id: v4(),
          fileType,
        };
        if (flagFirstFile) {
          setImageUploadMasive([formattedFile]);
          flagFirstFile = false;
        }

        return {
          ...item,
          testFiles: [formattedFile],
          action: TestEditionAction.EDIT,
        };
      });

      setModifiedCustomTestGroup(newOptions);
      notification.open({
        message: 'Imagen cargada correctamente.',
        type: 'success',
      });
    }
  };

  const handleAddFilesInBatch = (files, fileType) => {
    const filesToAttach = [...imagesImportFileBatch];
    files?.forEach((file) => {
      if (file.size > maxBytesFile) {
        notification.open({
          message: `${file.name}: ${t('UPLOAD_FILE_ERROR')}`,
          type: 'error',
        });
      } else {
        const formattedFile = {
          ...file,
          id: v4(),
          fileType,
        };
        filesToAttach.push(formattedFile);
      }
    });
    setImagesImportFileBatch(filesToAttach);
  };

  const handleRemoveFileInBatch = (fileId) => {
    const filesInBatchFiltered = imagesImportFileBatch.filter((x) => x.id !== fileId);
    setImagesImportFileBatch(filesInBatchFiltered);
  };

  const saveFilesInBatch = () => {
    const newOptions = modifiedCustomTestGroup.map((item) => {
      const fileQuestionToAttached = imagesImportFileBatch.find(
        (x) => x.name.split('.')[0] === item.textFileNameAttached
      );
      const optionsUpdated = item?.options?.map((opt) => {
        const fileOptionToAttached = imagesImportFileBatch.find(
          (y) => y.name.split('.')[0] === opt?.optionFileNameAttached
        );
        if (fileOptionToAttached) {
          const formattedOptionFile = {
            ...fileOptionToAttached,
            id: v4(),
            ...(fileOptionToAttached.type ?? {}),
          };
          return {
            ...opt,
            files: [formattedOptionFile],
          };
        }
        return opt;
      });
      if (fileQuestionToAttached) {
        const formattedQuestionFile = {
          ...fileQuestionToAttached,
          id: v4(),
          ...(fileQuestionToAttached.type ?? {}),
        };
        return {
          ...item,
          options: optionsUpdated ?? item?.options,
          testFiles: [formattedQuestionFile],
          action: TestEditionAction.EDIT,
        };
      }
      return item;
    });
    setModifiedCustomTestGroup(newOptions);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: newOptions,
        isEdit: false,
        currentQuestionData: undefined,
        allTests: formatOrder([...newScreening.allTests]),
      })
    );
    notification.open({
      message: 'Imagen/es cargada/s correctamente.',
      type: 'success',
    });
  };
  const handleUpdateMasiveTestFile = () => {
    setImageUploadMasive([]);
    handleAddMasiveTestFile();
  };

  const handleEditMassive = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: modifiedCustomTestGroup,
        isEdit: false,
        currentQuestionData: undefined,
        allTests: formatOrder([...newScreening.allTests]),
      })
    );
  };
  const removeEditMassive = () => {
    setImageUploadMasive([]);
    setModifiedCustomTestGroup(
      newScreening.customTestGroup.map((item) => ({ ...item, selected: false }))
    );
    setModalImage(false);
    notification.open({
      message: 'Se restauran los cambios para que vuelvas a seleccionar los test a modificar.',
      type: 'warning',
    });
  };

  const optionsCategorySelect = useMemo(() => {
    const optionsOnlyParent = testGroupCategoriesFilterOptions
      ?.filter((item) => !item?.parentId)
      ?.filter((item) => testGroupData?.categories?.includes(item?.id));

    const optionSelected = testGroupCategoriesFilterOptions?.filter((item) =>
      testGroupData?.categories?.includes(item?.id)
    );
    return optionsOnlyParent?.length === 0 ? optionSelected : optionsOnlyParent;
  }, [testGroupData?.categories]);

  const onSelectCategories = (values = []) => {
    const existCategory = values?.map((item) => item?.value)?.includes(testGroupData?.category);
    let newOption = { id: testGroupData?.category };
    if (!existCategory) {
      newOption = testGroupCategoriesFilterOptions
        .filter((category) => category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID)
        .find((category) => category?.id === values[0]);
    }
    handleSelectCategoryOptionMultiple(values, newOption);
  };

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  return (
    <BlockRedirection
      route={history.location}
      conditionToBlock={
        (!isEqual(testGroupInitialData, testGroupData) ||
          !isEqual(newScreening?.customTestGroup, customTestgroupInitialData) ||
          newScreening?.currentQuestionData !== undefined ||
          !isEqual(newScreening?.customTestGroupDuration, testgroupInitialDuration)) &&
        !isLoading &&
        Boolean(id) &&
        !patchSuccess
      }
      message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
    >
      <div className="w-100 mx-auto h-100">
        {isLoading ? (
          <Spinner show />
        ) : (
          <>
            <BackgroundTop
              title={
                isEdit ? t('EDIT_TEST_GROUP_NAME_TITLE') : t('CREATE_TEST_GROUP_SECTION_TITLE')
              }
              showBase
              className="position-relative"
              titleClassname="text-title"
            />
            <ModalCustom
              title={t('CREATE_SEARCH_ MODAL_SELECT_IMPORT')}
              onOkDisabled={!valueFile}
              show={showModal}
              setShow={setShowModal}
              onOkText={t('CREATE_SEARCH_MODAL_BUTTON_ADD')}
              onOk={() => {
                readFile();
                setShowModal(false);
              }}
              classCross="text-base text-blue-principal"
              input={
                <div className="outline-none custom-file-button ml-2 mt-3 py-2 px-4 font-weight-600 text-xs mx-auto w-75">
                  <input
                    type="file"
                    name="file"
                    id="fileEmails"
                    hidden
                    ref={importRef}
                    onChange={(e) => setValueFile(e.target.value.slice(12))}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  {valueFile && (
                    <p className="my-0 float-left font-weight-600 text-xs">
                      {`${valueFile.slice(0, 30)}...`}
                    </p>
                  )}
                  <label htmlFor="fileEmails" className="float-right">
                    <img src={upload} alt={t('CREATE_SEARCH_UPLOAD_ICON')} />
                  </label>
                </div>
              }
            />
            <Modal
              className="pt-3 pb-2 px-4 modal-masive-images-tg-container overflow-auto b-radius-8"
              show={haveImportTestFile}
              setShow={setHaveImportTestFile}
              onClose={() => setHaveImportTestFile(false)}
              showCloseButton
              closeBtnClassname="text-purple modal-close-icon"
            >
              <div className="w-100 d-flex flex-column align-items-center mt-4  font-weight-600 text-description-screening">
                <span className="text-medium">{t('LOAD_IMAGES_IN_BATCH_TITLE')}</span>
                <span className=" font-weight-500 text-xs text-description-screening mt-3 mb-4 text-center px-5">
                  {t('LOAD_IMAGES_IN_BATCH_DESCRIPTION')}
                  <b>
                    <br />
                    {t('LOAD_IMAGES_IN_BATCH_IMPORTANT')}
                  </b>
                </span>
              </div>
              <UploadTestFiles
                fileTypes={[TestFileType.IMAGE, TestFileType.AUDIO]}
                handleAddFile={handleAddFilesInBatch}
                handleRemoveFile={handleRemoveFileInBatch}
                maxFiles={modifiedCustomTestGroup?.length}
                testFiles={imagesImportFileBatch ?? []}
                id="test"
                multipleFiles
              />
              <div className="d-flex justify-content-center pb-3">
                <button
                  type="button"
                  className="bg-transparent border-0 modal-subtitle text-xs font-weight-700 "
                  onClick={() => {
                    setHaveImportTestFile(false);
                  }}
                >
                  {t('LOAD_IMAGES_IN_BATCH_CANCEL_BUTTON')}
                </button>
                <button
                  type="button"
                  className="modal-subtitle text-xs border-0 font-weight-700  bg-button-modal-massive-accept ml-4 text-white"
                  disabled={imagesImportFileBatch.length === 0}
                  onClick={() => {
                    saveFilesInBatch();
                    setHaveImportTestFile(false);
                  }}
                >
                  {t('Confirmar')}
                </button>
              </div>
            </Modal>
            <div className="px-2">
              <Modal
                className="pt-3 pb-2 px-4 modal-delete-tg-container b-radius-8"
                show={show}
                setShow={setShow}
                onClose={() => setShow(false)}
                showCloseButton
                closeBtnClassname="text-purple modal-close-icon"
              >
                <div className="w-100 d-flex flex-column align-items-center mt-4  font-weight-600 text-description-screening">
                  <span className="text-medium">
                    {t('MODAL_TG_WANT_DELETE')} “{itemDelete?.text?.slice(0, 17) ?? ''}
                    {itemDelete?.text?.length >= 17 ? '...' : ''}”?
                  </span>
                  <span className=" font-weight-500 text-xs text-description-screening mt-3 mb-4 text-center px-5">
                    {t('MODAL_TG_DESCRIPTION_DELETE')}
                  </span>
                </div>
                <div className="d-flex justify-content-center pb-3">
                  <button
                    type="button"
                    className="bg-transparent border-0 modal-subtitle text-xs font-weight-700 "
                    onClick={() => {
                      setShow(false);
                      setItemDelete({});
                    }}
                  >
                    {t('MODAL_TG_DO_NOT_DELETE')}
                  </button>
                  <button
                    type="button"
                    className="modal-subtitle text-xs border-0 font-weight-700  bg-button-modal-close ml-4 text-white"
                    onClick={() => {
                      if (itemDelete.isDifficulty) {
                        handleDeleteSetTestGroup(itemDelete.id);
                      } else {
                        handleDelete(itemDelete?.id);
                      }
                      setShow(false);
                      notification.open({
                        message: t('MODAL_TG_DELETE_SUCCESSFULLY'),
                        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
                      });
                    }}
                  >
                    {t('MODAL_TG_DELETE')}
                  </button>
                </div>
              </Modal>
              <Modal
                className="pt-3 pb-2 px-4 modal-delete-tg-container b-radius-8"
                show={modalImage}
                setShow={setModalImage}
                onClose={() => setModalImage(false)}
                showCloseButton
                closeBtnClassname="text-purple modal-close-icon"
              >
                <div className="w-100 d-flex flex-column align-items-center mt-4  font-weight-600 text-description-screening">
                  <span className="text-medium">¿Querés agregar imagen de forma masiva?</span>
                  <span className=" font-weight-500 text-xs text-description-screening mt-3 mb-4 text-center px-5">
                    Selecciona unicamente una imagen, se te notificara si se carga de forma
                    satisfactoria y al confirmar se agregará de forma masiva en los test
                    seleccionados. Podes confirmar entrando a editar uno de los test y vas a ver la
                    imagen cargada.
                    <b>
                      <br />
                      Importante: elimina las imagenes previas que tenían los test y los archivos de
                      audio.
                    </b>
                  </span>
                </div>
                <UploadTestFiles
                  fileTypes={[TestFileType.IMAGE]}
                  handleAddFile={handleAddMasiveTestFile}
                  handleRemoveFile={removeEditMassive}
                  handleUpdateFile={handleUpdateMasiveTestFile}
                  testFiles={imageUploadMasive ?? []}
                  id="test"
                />
                <div className="d-flex justify-content-center pb-3">
                  <button
                    type="button"
                    className="bg-transparent border-0 modal-subtitle text-xs font-weight-700 "
                    onClick={() => {
                      setModalImage(false);
                      setImageUploadMasive([]);
                      setModifiedCustomTestGroup(
                        newScreening.customTestGroup.map((item) => ({ ...item, selected: false }))
                      );
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="modal-subtitle text-xs border-0 font-weight-700  bg-button-modal-massive-accept ml-4 text-white"
                    disabled={imageUploadMasive.length === 0}
                    onClick={() => {
                      handleEditMassive();
                      setModalImage(false);
                      notification.open({
                        message: 'Se modificaron correctamente los test.',
                        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
                      });
                    }}
                  >
                    {t('Confirmar')}
                  </button>
                </div>
              </Modal>
              <div className="description-container create-screening-container w-100 bg-white position-relative mx-auto p-4 rounded d-flex flex-column">
                {creationSuccess ? (
                  <CreateTestGroupSuccess name={testGroupData?.name} />
                ) : (
                  <>
                    <div className="d-flex my-2 mx-4 flex-column create-screening-section-container">
                      <div className="d-flex justify-content-start w-100">
                        <span className=" font-weight-bold text-base my-1">
                          {t('CREATE_TEST_GROUP_NAME_TITLE')}
                        </span>
                        <FaExclamationCircle
                          className={`mx-2 mt-2 error-icon-color ${
                            !testGroupData?.name ? '' : 'd-none'
                          }`}
                          title={t('INCOMPLETE')}
                        />
                      </div>
                      <input
                        maxLength={50}
                        value={testGroupData?.name}
                        name="name"
                        onChange={handleModifyData}
                        className={`p-2  rounded test-group-name-input mt-2 text-xs ${
                          testGroupData?.name?.length >= 1 && 'completed'
                        }`}
                        placeholder={t('CREATE_TEST_GROUP_NAME_PLACEHOLDER')}
                      />
                    </div>
                    <Collapse
                      expandIconPosition="end"
                      bordered={false}
                      className="px-4 py-1 w-100 align-items-center justify-content-between mb-2 bg-white"
                      activeKey={activeKey}
                      onChange={handleCollapseChange}
                    >
                      <Panel
                        header={t('CREATE_TESTGROUP_TITLE_COLLAPSE_SETTINGS')}
                        key="1"
                        className="panel-container bg-gray border-none "
                        extra={
                          <FaExclamationCircle
                            className={`mx-2 error-icon-color ${
                              !testGroupData?.level ||
                              (challengeAlkemy &&
                                !technologies.find((tech) => tech.selected)?.id &&
                                Boolean(!techChallengeAlkemy)) ||
                              !customTestGroupTotalTime ||
                              (testgroupWithThemes &&
                                !newScreening?.customTestGroup?.every((item) => item.theme)) ||
                              testGroupData?.rolesIds?.length === 0 ||
                              testGroupData?.endorsementId === ''
                                ? ''
                                : 'd-none'
                            }`}
                            title={t('INCOMPLETE')}
                          />
                        }
                      >
                        <div className="d-flex align-items-center">
                          <DropDownSelect
                            selectedPlaceholder
                            className="mr-4"
                            placeholder={t('SELECT_MINIMUM_TIER_PLACEHOLDER')}
                            setState={handleSelectMinimumTierOption}
                            selected={testGroupData?.minimumTier}
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item) => ({
                              key: item,
                              id: item,
                              name: `Tier N° ${item}`,
                            }))}
                          />
                          <DropDownSelect
                            selectedPlaceholder
                            className="mr-2"
                            placeholder={t('MULTIPLE_SELECT_LEVELS_PLACEHOLDER')}
                            setState={handleSelectLevelOption}
                            selected={testGroupData?.level}
                            options={testGroupLevelsFilterOptions?.map((item) => ({
                              key: item.id,
                              id: item.id,
                              name: item.displayName,
                            }))}
                          />
                          <TreeMultiSelect
                            options={testGroupCategoriesFilterOptions.filter(
                              (category) => category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID
                            )}
                            value={testGroupData?.categories}
                            textSize="14px"
                            width="150"
                            treeCheckStrictly
                            onChange={(values) =>
                              onSelectCategories(values?.map((item) => item?.value))
                            }
                            className=" drops-filter flex-row mr-2 text-xs"
                            style={{ fontSize: 12, minWidth: 120 }}
                            label={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER')}
                            dropdownWidth="300px"
                          />
                          <div style={{ minWidth: 170 }} className="mx-2 ">
                            <DropDownSelect
                              selectedPlaceholder
                              placeholder={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER_DEFAULT')}
                              setState={handleSelectCategoryOption}
                              selected={testGroupData?.category}
                              options={[
                                {
                                  value: '-1',
                                  label: t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER_DEFAULT'),
                                },
                                ...optionsCategorySelect,
                              ]}
                              width="100%"
                            />
                          </div>

                          <div className="d-flex align-items-center my-2">
                            <span className=" font-italic text-muted font-weight-bold text-xs">
                              {t('CREATE_SCREENING_YOUR_QUESTIONS_TOTAL_TIME')}
                            </span>
                            <TimePicker
                              onChange={handleCustomTestGroupTime}
                              values={newScreening?.customTestGroupDuration}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {!challengeAlkemy && (
                            <div className="mx2">
                              <TreeMultiSelect
                                treeCheckStrictly
                                options={testGroupRolesFilterOptions}
                                value={testGroupData?.rolesIds}
                                textSize="12px"
                                onChange={(values) =>
                                  handleSelectRolesOptionsMultiple(
                                    values?.map((item) => item?.value)
                                  )
                                }
                                className="drops-filter flex-row mr-2 text-xs"
                                label={t('ROLES_FILTERS')}
                                labelName="displayName"
                                width="100%"
                                dropdownWidth="300px"
                                style={{ minWidth: 160 }}
                              />
                            </div>
                          )}
                          {!challengeAlkemy && (
                            <MultipleSelect
                              title={t('TAGS')}
                              classNames="mx-2 bg-white"
                              onSelect={(e) => handleSelectTagsOptions(e.id)}
                              selectedList={testGroupData?.tagsIds}
                              options={testGroupTagsFilterOptions?.filter(
                                (item) => item?.type === TAGS_TYPE.MARKETING
                              )}
                              isCreateTestGroup
                            />
                          )}
                          {!challengeAlkemy && (
                            <DropDownSelect
                              selectedPlaceholder
                              className="mx-2"
                              placeholder={t('ENDORSEMENT_CREATOR')}
                              setState={handleSelectEndorsementOptions}
                              selected={testGroupData?.endorsementId}
                              options={endorsementCreatorsOptions}
                            />
                          )}

                          {!challengeAlkemy && (
                            <MultipleSelect
                              title={t('Compañia')}
                              classNames="mx-2 bg-white"
                              onSelect={(e) => handleSelectCompanyUsageOptions(e.id)}
                              selectedList={testGroupData?.companyUsageIds}
                              options={endorsementCompaniesOptions?.map((item) => ({
                                ...item,
                                displayName: item?.name,
                              }))}
                              isCreateTestGroup
                            />
                          )}
                          <div className="d-flex mb-2 align-items-center">
                            <GenericToggle
                              actionFunction={() => setTestgroupWithThemes((state) => !state)}
                              defaultActive={testgroupWithThemes}
                              isEditTG={isEdit || challengeAlkemy}
                            />
                            <span
                              className={` text-xs font-weight-500 ${
                                testgroupWithThemes ? 'text-dark' : 'text-muted'
                              }`}
                            >
                              {t('ABM_THEME_SWITCH_TEXT')}
                            </span>
                          </div>
                        </div>
                        {!challengeAlkemy && (
                          <div className="d-flex align-items-center">
                            <span className=" font-italic text-muted font-weight-bold text-xs mx-2">
                              {t('WORKSPACE_ID')}
                            </span>
                            <input
                              maxLength={5}
                              value={testGroupData?.workspaceId}
                              name="workspaceId"
                              onChange={handleModifyData}
                              className={`p-2  rounded workspace-id-input mt-2 text-xs ${
                                testGroupData?.workspaceId?.length >= 1 && 'completed'
                              }`}
                            />

                            <span className=" font-italic text-muted font-weight-bold text-xs mx-2">
                              Ocultar título
                            </span>
                            <GenericToggle
                              actionFunction={() => {
                                if (testGroupData?.hideTitle) {
                                  handleHideTitle(false);
                                } else {
                                  handleHideTitle(true);
                                }
                              }}
                              defaultActive={testGroupData?.hideTitle}
                            />

                            <span className=" font-italic text-muted font-weight-bold text-xs mx-2">
                              Confir. Automatica
                            </span>
                            <GenericToggle
                              actionFunction={() => {
                                if (testGroupData?.isAutomaticContinue) {
                                  handleIsAutomaticContinue(false);
                                } else {
                                  handleIsAutomaticContinue(true);
                                }
                              }}
                              defaultActive={testGroupData?.isAutomaticContinue}
                            />
                            <span className=" font-italic text-muted font-weight-bold text-xs mx-2">
                              {t('WITHOUT_TIME_LIMIT')}
                            </span>
                            <GenericToggle
                              actionFunction={() => {
                                handleWithoutTime(!testGroupData?.withoutTime);
                              }}
                              defaultActive={testGroupData?.withoutTime}
                            />
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center my-4">
                          {isEdit && testGroupData?.description ? (
                            <div className="d-flex my-3 flex-column w-100 mr-5">
                              <span className=" font-weight-bold text-base my-1">
                                {t('CREATE_TEST_GROUP_DESCRIPTION_TITLE')}
                              </span>
                              <textarea
                                disabled
                                name="description"
                                className={`border-thin-blue-principal outline-none w-100 p-2 text-xs  min-height-textarea-description ${
                                  testGroupData?.description?.length
                                    ? 'selected-border'
                                    : 'unselected-border'
                                }`}
                                rows="5"
                                placeholder={t('CREATE_TEST_GROUP_NAME_PLACEHOLDER')}
                                onChange={handleModifyData}
                                value={testGroupData?.description ?? ''}
                                maxLength={5000}
                              />
                            </div>
                          ) : null}

                          <div className="w-50 d-flex flex-column">
                            <div className="d-flex flex-column justify-content-between w-100 screening-switch border-thin-blue-principal">
                              <div className="d-flex justify-content-between">
                                <span className="font-weight-500  text-xs">
                                  {t('CHALLENGE_ALKEMY_TOGGLE_TEXT')}
                                </span>
                                <GenericToggle
                                  actionFunction={() => {
                                    setChallengeAlkemy((state) => !state);
                                    if (testgroupWithThemes) setTestgroupWithThemes(() => false);
                                  }}
                                  defaultActive={challengeAlkemy}
                                  isEditTG={isEdit}
                                />
                              </div>
                              <div>
                                <DropDownSelect
                                  className="b-radius-8 mr-4 technologies-dropdown"
                                  options={technologies}
                                  disabled={!challengeAlkemy || isEdit}
                                  arrowDisabled={!challengeAlkemy}
                                  selected={
                                    challengeAlkemy &&
                                    (technologies.find((technology) => technology?.selected)?.id ||
                                      techChallengeAlkemy)
                                  }
                                  setState={(selectedOption) =>
                                    handleFilterChange(
                                      technologies,
                                      setTechnologies,
                                      selectedOption
                                    )
                                  }
                                  placeholder={
                                    challengeAlkemy
                                      ? technologies.find((tech) => tech.selected)?.name ||
                                        t('CHALLENGE_ALKEMY_SELECT_DEFAULT')
                                      : t('CHALLENGE_ALKEMY_SELECT_DEFAULT')
                                  }
                                  selectedPlaceholder
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        header={t('CREATE_TESTGROUP_TITLE_COLLAPSE_ASSIGNMENT')}
                        key="2"
                        className="panel-container bg-gray border-none "
                      >
                        <div className="d-flex flex-column flex-lg-row">
                          <div className="mr-1 flex-column justify-content-between w-60">
                            <p className=" font-weight-600 text-base mb-0">
                              {`${t('CREATE_TESTGROUP_TITLE_EDIT_ASSIGNMENT')}:`}
                            </p>
                            <div className="d-flex flex-column justify-content-between">
                              <GenericTextArea
                                placeholder={t('CREATE_TESTGROUP_ASSIGNMENT_PLACEHOLDER')}
                                onChange={(e) => {
                                  const normalizedValue = e === '<p><br></p>' ? '' : e;
                                  handleSetAssignment('subtitle', normalizedValue);
                                }}
                                richTextClassName="h-75 mb-2 pb-5"
                                value={step.subtitle}
                                maxLength={5000}
                                richText
                                fontsType={['bold', 'italic', 'underline']}
                                link={['link']}
                              />
                            </div>
                            <div className="d-flex align-content-center">
                              <Button
                                type="button"
                                disabled={step.subtitle?.trim() === ''}
                                className={step.subtitle?.trim() === '' ? 'd-none' : 'px-3'}
                                onClick={() => handleSetAssignment('subtitle', '')}
                              >
                                <span className="px-3 py-1 text-button-left-modal  d-flex justify-content-center align-items-center">
                                  {t('RESET_TESTGROUP_ASSIGNMENT')}
                                </span>
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex flex-column w-50">
                            <p className=" font-weight-600 text-blue-principal text-base mb-0">
                              {`${t('PREVIEW')}:`}
                            </p>
                            <EvaluationPreview
                              className="mt-1"
                              key={`start-assigment-${
                                step.subtitle?.length
                              }-${step.subtitle.codePointAt()}`}
                            >
                              <div className="d-flex flex-column justify-content-between align-items-left bg-white">
                                <SafeContainer
                                  content={
                                    step.subtitle?.trim() !== ''
                                      ? step.subtitle
                                      : t('CREATE_TESTGROUP_ASSIGNMENT_DEFAULT').replace(
                                          'company',
                                          getLocalStorageWorkspace()?.companyName
                                        )
                                  }
                                  readOnly
                                  theme="bubble"
                                />
                              </div>
                            </EvaluationPreview>
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        header={t('CREATE_TESTGROUP_TITLE_COLLAPSE_DETAILS')}
                        key="3"
                        className="panel-container bg-gray border-none "
                        extra={
                          <FaExclamationCircle
                            className={`mx-2 error-icon-color ${
                              !testGroupData.languages?.length ||
                              (toggleScoreTags && !testGroupScoreTags?.isFullRange)
                                ? ''
                                : 'd-none'
                            }`}
                            title={t('INCOMPLETE')}
                          />
                        }
                      >
                        <div className="my-2">
                          <span className=" font-weight-bold text-base my-2">
                            {t('CREATE_TEST_GROUP_LANGUAGES_TITLE')}
                          </span>
                          <Select
                            mode="multiple"
                            style={{
                              width: '100%',
                            }}
                            className=""
                            placeholder={t('CREATE_TEST_GROUP_LANGUAGES_PLACEHOLDER')}
                            onChange={(value) => {
                              if (!value || value === '') return handleSelectLanguageOption([]);
                              const langs = String(value).split(',');
                              handleSelectLanguageOption(langs);
                            }}
                            value={testGroupData?.languages}
                            popupClassName=""
                          >
                            {languagesList.map((x) => (
                              <Option value={x.value}>
                                <CountryFlag countryIso={x.iso} size="sm" className="mr-2" />
                                {x.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="w-100 border-thin-blue-principal p-4 my-2">
                          <div className="d-flex justify-content-between w-100">
                            <div />

                            <Radio.Group
                              value={selectedLang ?? key}
                              onChange={(e) => setSelectedLang(e.target.value)}
                            >
                              {testGroupData?.languages?.map((lang) => (
                                <Radio.Button value={lang}>
                                  <CountryFlag
                                    countryIso={languagesList?.find((y) => y.value === lang)?.iso}
                                    size="sm"
                                  />
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </div>

                          <div className="">
                            <div className="d-flex justify-content-between">
                              <span className=" font-weight-bold text-base my-1">
                                {t('CREATE_TEST_GROUP_OBJETIVES_TITLE')}
                              </span>
                            </div>

                            <Select
                              mode="tags"
                              style={{
                                width: '100%',
                              }}
                              placeholder={t('CREATE_TEST_GROUP_OBJETIVES_PLACEHOLDER')}
                              onChange={(value) => {
                                if (!value || value === '')
                                  return handleSelectObjetivesOption(selectedLang, []);
                                const objs = String(value).split(',');
                                handleSelectObjetivesOption(selectedLang, objs);
                              }}
                              value={
                                testGroupData?.objetives
                                  ? selectedLang
                                    ? testGroupData?.objetives[selectedLang] ?? []
                                    : testGroupData?.objetives[key]
                                  : []
                              }
                              disabled={
                                !testGroupData?.languages || !testGroupData?.languages?.length
                              }
                            >
                              {testGroupData.objetives
                                ? selectedLang
                                  ? (testGroupData?.objetives[selectedLang] ?? []).map((tag) => (
                                      <Option value={tag}>{tag}</Option>
                                    )) ?? null
                                  : (testGroupData?.objetives[key] ?? []).map((tag) => (
                                      <Option value={tag}>{tag}</Option>
                                    ))
                                : null}
                            </Select>
                          </div>
                          <div className="d-flex my-3 flex-column w-100 mr-5">
                            <span className=" font-weight-bold text-base my-1">
                              {t('CREATE_TEST_GROUP_DESCRIPTION_TITLE')}
                            </span>
                            <textarea
                              name="summary"
                              className={`border-thin-blue-principal outline-none w-100 p-2 text-xs  min-height-textarea-description ${
                                testGroupData?.summary?.length
                                  ? 'selected-border'
                                  : 'unselected-border'
                              }`}
                              rows="5"
                              placeholder={t('CREATE_TEST_GROUP_SUMMARY_PLACEHOLDER')}
                              onChange={(e) => handleModifyLangData(selectedLang, e)}
                              value={
                                testGroupData?.summary
                                  ? selectedLang
                                    ? testGroupData?.summary[selectedLang] ?? ''
                                    : testGroupData?.summary[key]
                                  : ''
                              }
                              disabled={
                                !testGroupData.languages || !testGroupData.languages?.length
                              }
                              maxLength={350}
                            />
                          </div>
                          <div className="d-flex my-3 flex-column w-100 mr-5">
                            <span className=" font-weight-bold text-base my-1">
                              {t('CREATE_TEST_GROUP_DETAIL_TITLE')}
                            </span>
                            <textarea
                              name="detail"
                              className={`border-thin-blue-principal outline-none w-100 p-2 text-xs  min-height-textarea-description ${
                                testGroupData?.detail?.length
                                  ? 'selected-border'
                                  : 'unselected-border'
                              }`}
                              rows="5"
                              placeholder={t('CREATE_TEST_GROUP_DETAIL_PLACEHOLDER')}
                              onChange={(e) => handleModifyLangData(selectedLang, e)}
                              value={
                                testGroupData?.detail
                                  ? selectedLang
                                    ? testGroupData?.detail[selectedLang] ?? ''
                                    : testGroupData?.detail[key]
                                  : ''
                              }
                              disabled={
                                !testGroupData?.languages || !testGroupData.languages?.length
                              }
                              maxLength={550}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <span className=" font-weight-bold text-base my-4">
                            {t('CREATE_TEST_GROUP_TEST_FORMATS_TITLE')}
                          </span>
                          <Select
                            mode="multiple"
                            style={{
                              width: '100%',
                            }}
                            className=""
                            placeholder={t('CREATE_TEST_GROUP_TEST_FORMATS_PLACEHOLDER')}
                            onChange={(value) => {
                              handleSelectTestFormats(value);
                            }}
                            value={testGroupData?.testFormatIds}
                            popupClassName=""
                            size="middle"
                          >
                            {testFormats.map((x) => (
                              <Option value={x?.id}>
                                <img
                                  src={x?.iconUrl}
                                  alt="Format test logo"
                                  className="text-blue-principal mr-2"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                                {t(x?.label)}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="w-100 border-thin-blue-principal p-4 my-2">
                          <Collapse
                            activeKey={toggleScoreTags ? '1' : null}
                            accordion
                            ghost
                            onChange={() => {
                              setToggleScoreTags(!toggleScoreTags);
                            }}
                          >
                            <Panel
                              key="1"
                              header={
                                <GenericToggle
                                  leftText={t('CREATE_TEST_SCORE_TAGS_TITLE')}
                                  leftTextClassName="text-sm  font-weight-bold my-2"
                                  actionFunction={() => {}}
                                  actionFunctionValue={setToggleScoreTags}
                                  value={toggleScoreTags}
                                />
                              }
                              showArrow={false}
                            >
                              <GroupSlider
                                initialValues={groupSliderInitialData}
                                descriptionConfig={{
                                  leftAddonText: `${t('SCORE_TAGS_LABEL_FROM')} {{range}}: `,
                                  type: 'input',
                                }}
                                onChange={handleScoreTags}
                                addButtonText={t('SCORE_TAGS_ADD_BUTTON_LABEL')}
                                removeButtonText={t('SCORE_TAGS_REMOVE_BUTTON_LABEL')}
                                showDivider
                              />
                            </Panel>
                          </Collapse>
                        </div>
                      </Panel>
                      <Panel
                        header={t('CREATE_TESTGROUP_TITLE_COLLAPSE_ADD_TEST')}
                        key="4"
                        className="panel-container bg-gray border-none "
                      >
                        <AddQuestions testGroupABM testgroupWithThemes={testgroupWithThemes} />
                      </Panel>
                    </Collapse>

                    <div>
                      <div className="my-2 my-sm-0 mr-0 mr-sm-2 pr-md-5 mr-md-5 mx-4 d-flex align-items-center w-60">
                        <span className=" font-weight-600 text-muted font-italic text-xs pr-0 text-nowrap">
                          {t('CREATE_TEST_GROUP_QUANTITY_TESTS')}
                        </span>
                        <input
                          className="ml-2 border-thin-blue-principal font-weight-bold  py-1 max-char-input-create-testgroup px-2 outline-none h-25 col-1"
                          type="number"
                          onKeyDown={(e) => {
                            const excludedKeys = ['e', '+', '-', '.'];
                            if (excludedKeys.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={testGroupQuantityQuestions ?? ''}
                          name="weight"
                          onChange={(e) => {
                            setTestGroupQuantityQuestions(e.target.value);
                          }}
                          onBlur={(e) => {
                            onBlurInputNumber(e);
                          }}
                        />
                        <DropDownSelect
                          selectedPlaceholder
                          className="mx-3 py-0"
                          placeholder={t('MULTIPLE_SELECT_DIFFICULTY_PLACEHOLDER')}
                          setState={handleSelectDifficulty}
                          selected={testGroupDifficult}
                          options={testDifficulty
                            ?.sort((a, b) => a.order - b.order)
                            ?.map((item) => ({
                              id: item.id,
                              name: t(`CREATE_TEST_GROUP_QUESTION_${item.name}`),
                              key: item.id,
                            }))}
                        />
                        {testgroupWithThemes && (
                          <DropDownSelect
                            selectedPlaceholder
                            className="mx-3 py-0"
                            placeholder={t('ABM_THEME_INPUT_PLACEHOLDER')}
                            disabled={!availableThemes.length}
                            setState={handleSelectTheme}
                            selected={testGroupTheme}
                            options={availableThemes?.map((item) => ({
                              id: item,
                              name: item,
                              key: item.id,
                            }))}
                          />
                        )}
                        <div
                          data-testid="difficulty-config-with-audio-container"
                          className="d-flex align-items-center mr-4"
                        >
                          <Checkbox
                            testid="difficulty-config-with-audio-checkbox"
                            checked={testGroupListeningTest}
                            onClick={handleAudioDifficulty}
                            className="test-checkbox align-self-start d-flex align-items-center justify-content-between"
                          />
                          <span
                            data-testid="difficulty-config-with-audio-checkbox-text"
                            className=" text-nowrap ml-2 font-weight-500 text-xs"
                          >
                            {t('TESTGROUP_ABM_QUESTION_WITH_AUDIO')}
                          </span>
                        </div>
                        <button
                          onClick={handleCreateQuestionsSet}
                          disabled={disabledCreateSet}
                          className={`px-4 py-1 rounded-pill  text-white text-xs font-weight-bold border-0 outline-none ${
                            disabledCreateSet ? 'disabled-button' : 'bg-maya-blue'
                          }`}
                          type="button"
                        >
                          {t('CREATE_TEST_GROUP_CREATE_BUTTON')}
                        </button>
                      </div>
                      <div className="my-3">
                        {testGroupQuestions
                          ?.sort((a, b) => a.order - b.order)
                          ?.filter((item) => (testgroupWithThemes ? item.theme : !item.theme))
                          ?.map((item, index) => (
                            <TestGroupsItem
                              key={item.ordererId}
                              test={{
                                text: `${item.quantity} ${
                                  Number(item.quantity) === 1
                                    ? t('CREATE_TEST_GROUP_QUESTION')
                                    : t('CREATE_TEST_GROUP_QUESTIONS')
                                }`,
                                questionDifficulty: t(
                                  `CREATE_TEST_GROUP_QUESTION_${
                                    testDifficulty.find((d) => d.id === item.difficultyId)?.name ||
                                    'NOT_DIFFICULTY'
                                  }`
                                ),
                                order: index,
                                theme: item?.theme,
                                listeningTest: item?.listeningTest,
                                langDisplayName: item?.langDisplayName,
                              }}
                              handleDelete={() => {
                                setShow(true);
                                setItemDelete({
                                  text: `${item.quantity} ${
                                    Number(item.quantity) === 1
                                      ? t('CREATE_TEST_GROUP_QUESTION')
                                      : t('CREATE_TEST_GROUP_QUESTIONS')
                                  }`,
                                  id: item.ordererId,
                                  isDifficulty: true,
                                });
                              }}
                              handleOrder={handleOrderSetQuestions}
                              disableOrderUp={index === 0}
                              disableOrderDown={index === testGroupQuestions.length - 1}
                              testGroupABM
                            />
                          ))}
                      </div>
                      <div className="d-flex mt-3">
                        {testQuantityToMap.map((test) => (
                          <p className=" font-weight-500 gray-subtitle text-xs" key={test?.id}>
                            {t(
                              `${
                                test.name !== 'undefined'
                                  ? `QUESTION_${test.name}`
                                  : `QUESTION_PENDING`
                              }`
                            )}
                            <span className=" font-weight-600 text-blue-principal mx-2">
                              {test.quantity}
                            </span>
                          </p>
                        ))}
                        {modifiedCustomTestGroup.filter((item) => item.selected)?.length > 0 && (
                          <button
                            onClick={() => setModalImage(true)}
                            className="align-self-end px-3 py-1 rounded-pill  text-white text-xs font-weight-bold border-0 outline-none bg-violet ml-auto"
                            type="button"
                          >
                            Agregar Imagen Masiva
                          </button>
                        )}
                      </div>
                      {modifiedCustomTestGroup
                        .sort((a, b) => a.order - b.order)
                        .map((item) => (
                          <TestGroupsItem
                            key={item.deleteId || item.id}
                            test={{
                              ...item,
                              questionDifficulty: t(
                                `CREATE_TEST_GROUP_QUESTION_${
                                  testDifficulty.find((d) => d.id === item.difficultyId)?.name ??
                                  'NOT_DIFFICULTY'
                                }`
                              ),
                              ...(testgroupWithThemes ? { theme: item?.theme } : { theme: null }),
                            }}
                            handleEdit={() => {
                              handleCollapseChange('4');
                              setEditData(item);
                            }}
                            handleDelete={() => {
                              setShow(true);
                              setItemDelete({
                                id: item.deleteId ?? item.id,
                                text: item?.text,
                                isDifficulty: false,
                              });
                            }}
                            testGroupABM
                            handleSelectTest={handleSelectTest}
                          />
                        ))}
                    </div>
                    <div className="w-100 d-flex justify-content-end mt-3">
                      <button
                        className="outline-none border-0 bg-transparent"
                        type="button"
                        data-testid="button-import-excel"
                        onClick={() => {
                          exportFile();
                        }}
                      >
                        <span className="blue-color text-xs  font-weight-600">
                          {t('TESTS_EXPORT_BUTTON')}
                        </span>
                      </button>
                    </div>
                    <div className="w-100 d-flex justify-content-end mt-3">
                      <button
                        className="outline-none border-0 bg-transparent"
                        type="button"
                        data-testid="button-import-excel"
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        <span className="blue-color text-xs  font-weight-600">
                          {t('TESTS_IMPORT_BUTTON')}
                        </span>
                      </button>
                    </div>
                    {result?.length > 0 && (
                      <div className="w-100 d-flex justify-content-end mt-3">
                        <button
                          onClick={() => setHaveImportTestFile(true)}
                          className="outline-none border-0 bg-transparent"
                          type="button"
                        >
                          <span className="blue-color text-xs  font-weight-600">
                            {t('LOAD_IMAGES_IN_BATCH')}
                          </span>
                        </button>
                      </div>
                    )}
                    <div className="w-100 d-flex justify-content-end mt-3">
                      {submitLoading ? (
                        <div className="mt-4">
                          <HorizontalLoader />
                        </div>
                      ) : (
                        <>
                          {isEdit && (
                            <button
                              onClick={() => handlePatchData(true)}
                              disabled={
                                disabled || !formatDifficultyConfig(testGroupQuestions).length
                              }
                              className={`mt-4 align-self-end px-3 py-1 rounded-pill  text-white text-xs font-weight-bold border-0 outline-none mr-4 ${
                                disabled || !formatDifficultyConfig(testGroupQuestions).length
                                  ? 'disabled-button'
                                  : 'bg-red-danger'
                              } ${submitLoading && 'cursor-wait'}`}
                              type="button"
                            >
                              {t('REPLACE_ACTUAL_TEST_GROUP')}
                            </button>
                          )}
                          <button
                            onClick={isEdit ? () => handlePatchData(false) : handlePostData}
                            disabled={
                              disabled || !formatDifficultyConfig(testGroupQuestions).length
                            }
                            className={`mt-4 align-self-end px-3 py-1 rounded-pill  text-white text-xs font-weight-bold border-0 outline-none ${
                              disabled || !formatDifficultyConfig(testGroupQuestions).length
                                ? 'disabled-button'
                                : 'bg-maya-blue'
                            } ${submitLoading && 'cursor-wait'}`}
                            type="button"
                          >
                            {t('CREATE_TEST_GROUP_SAVE_BUTTON')}
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </BlockRedirection>
  );
};

export default CreateTestGroup;
