import React, { useRef } from 'react';
import './Accordion.scss';

function Accordion({ open, children, fixWidth, maxHeightPercent, maxHeight, isProctoringDetail }) {
  const content = useRef(null);
  const maxHeightStyles = {
    maxHeight: open
      ? maxHeight ||
        (maxHeightPercent && `${maxHeightPercent}%`) ||
        (content?.current?.scrollHeight && `${content?.current?.scrollHeight * 2}px`) ||
        '15.625rem'
      : '0px',
  };
  return (
    <div className="accordion__section position-sticky left-0" style={{ width: fixWidth }}>
      <div
        ref={content}
        style={maxHeightStyles}
        className={`accordion__content ${
          isProctoringDetail || maxHeight ? 'overflow-auto' : 'overflow-hidden'
        }`}
      >
        <div className="accordion__text">{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
