import React from 'react';
import { Button } from 'antd';
import { debounce } from 'lodash';

const DebouncedButton = ({ onClick, debounceTime = 500, children, ...props }) => {
  const debouncedClick = debounce((event) => {
    if (onClick) onClick(event);
  }, debounceTime);

  return (
    <Button {...props} onClick={debouncedClick}>
      {children}
    </Button>
  );
};

export default DebouncedButton;
