import Accordion from '../../../../components/Accordion/Accordion';
import './CollapseItem.scss';

const Collapse = ({ index, options, collapsedSelected, isProctoringDetail, classCustom }) => {
  const containerClass = classCustom || 'border-maya-blue bg-white';

  return (
    <>
      <div className={`collapse-item-container mt-3 p-3 b-radius-8 w-100 ${containerClass}`}>
        <div className="d-flex flex-row justify-content-between" aria-hidden="true">
          <div className="d-flex w-100">{options.header}</div>
        </div>
      </div>
      <Accordion
        open={collapsedSelected.includes(index)}
        maxHeight="100%"
        isProctoringDetail={isProctoringDetail}
      >
        {options?.content}
      </Accordion>
    </>
  );
};

export default Collapse;
