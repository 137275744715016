const Lite = () => (
  <svg
    width="177"
    height="177"
    viewBox="0 0 177 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="home-plan-image"
  >
    <circle cx="88.5" cy="88.5" r="88.5" fill="white" />
    <circle cx="88.6438" cy="88.6438" r="72.1438" stroke="#3771C8" />
    <path
      d="M42.1675 106.104L74.0083 38.123H83.8125L88.4886 48.1038L93.2486 58.2664L115.653 106.104H106.128L88.4886 68.4355L83.7285 58.2728L78.9104 47.9869L51.6932 106.104H42.1675Z"
      fill="#58C1F5"
    />
    <path
      d="M61.2778 106.104L93.1182 38.123H102.922L107.599 48.1038L112.359 58.2664L134.758 106.091L125.248 106.123L107.599 68.4355L102.838 58.2728L98.0203 47.9869L70.8031 106.104H61.2778Z"
      fill="#58C1F5"
    />
    <path
      d="M69.4024 133.123V119.123H70.4224V132.203H78.4824V133.123H69.4024ZM81.1016 133.123V119.123H82.1216V133.123H81.1016ZM89.6801 133.123V120.043H84.6401V119.123H95.7401V120.043H90.7001V133.123H89.6801ZM99.1696 125.563H106.75V126.463H99.1696V125.563ZM99.2896 132.203H107.93V133.123H98.2696V119.123H107.63V120.043H99.2896V132.203Z"
      fill="#3771C8"
    />
  </svg>
);

export default Lite;
