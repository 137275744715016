import { HOME } from '../../constants/routes';
import { t } from '../../i18n/i18n';
import DebouncedButton from '../Button/Button';
import './MyError.scss';
import { useHistory } from 'react-router-dom';
const MyInternalServerError = () => {
  const history = useHistory();
  return (
    <div className="error-page-container d-flex flex-column py-5 mx-auto">
      <h2 className="font-weight-bold error-page-text text-center">
        {t('ERROR_WE_HAD_A_PROBLEM')}
      </h2>
      <div className="text-center">
        <DebouncedButton
          className="mr-2"
          text={t('ERROR_GO_BACK')}
          onClick={() => history.goBack()}
          size="sm"
        />
        <DebouncedButton
          text={t('ERROR_GO_TO_HOME')}
          onClick={() => history.push(HOME)}
          size="sm"
        />
      </div>
      <img src={`/errors/500.svg`} alt="error" className="w-100 mt-2" />
    </div>
  );
};

export default MyInternalServerError;
