import React, { useEffect, useState } from 'react';
import { Button, Divider, notification, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { t } from '../../../i18n/i18n';
import PrefilterQuestionRow from './QuestionComponent';
import ArrowSmall from '../../../assets/screening/ArrowSmall';
import DeleteForever from '../../../assets/screening/DeleteForever';
import EditPen from '../../../assets/screening/EditPen';
import InfoTooltip from '../../InfoTooltip/InfoTooltip';
import { ConfirmModal } from '../../Categories/modal/ConfirmModal';
import EditPencilIcon from '../../../assets/common/EditPencilIcon';
import NewStyleSelect from '../../NewStyleSelect/NewStyleSelect';

export default function PrefilterQuestions({
  prefilterQuestions,
  questionsDraft,
  handleOrder,
  handleAddDelay,
  delayTime,
  handleDelete,
  handleAddPrefilterQuestion,
  handleEdit,
}) {
  const [questionsAdd, setQuestionsAdd] = useState([]);
  const [questionOnEdit, setQuestionOnEdit] = useState();
  const [hideAddQuestion, setHideAddQuesiton] = useState(true);
  const [questionToDelete, setQuestionToDelete] = useState();
  const [open, setOpen] = useState(false);
  const { Option } = Select;

  const handleAddQuestion = (test) => {
    const allQuestions = [...questionsAdd, test];
    setQuestionsAdd(allQuestions);
  };

  const handleSelectQuestion = (questionId) => {
    const test = prefilterQuestions.find((x) => x.testId === questionId);
    if (questionsAdd?.length < 5) {
      if (questionsAdd.some((q) => q.testId === questionId)) {
        notification.open({
          message: t('CREATE_PRE_FILTER_ALREADY_QUESTION_ADD'),
          type: 'warning',
        });
      } else {
        handleAddQuestion(test.test);
      }
    } else {
      notification.open({ message: t('CREATE_PRE_FILTER_MAX_QUESTIONS_ERROR'), type: 'error' });
    }
  };

  const handleSelectToEditQuestion = (question, isSelected = false) => {
    if (questionsAdd.length < 5 || isSelected) {
      setHideAddQuesiton(false);
      setQuestionOnEdit(question?.test ?? question);
    } else {
      notification.open({ message: t('CREATE_PRE_FILTER_MAX_QUESTIONS_ERROR'), type: 'error' });
    }
  };

  const handleEditQuestion = async (question) => {
    try {
      if (question?.testId) {
        const newQuestions = await handleEdit(question);
        setQuestionsAdd(newQuestions);
      } else {
        const filteredQuestions = [
          ...questionsAdd.filter((q) => q.text !== questionOnEdit.text),
          question,
        ];
        setQuestionsAdd(filteredQuestions);
      }
      notification.open({ type: 'success', message: t('EDIT_PREFILTER_QUESTION_MESSAGE') });
      setQuestionOnEdit(null);
      setHideAddQuesiton(true);
    } catch (e) {
      notification.open({ type: 'error', message: t('ERROR_ON_PREFILTER_QUESTIONS') });
    }
  };

  const handleRemove = (questionIndex) => {
    const questionFiltered = questionsDraft.filter((_, i) => i !== questionIndex);
    handleAddPrefilterQuestion(questionFiltered);
    setQuestionsAdd(questionFiltered);
    if (questionFiltered.length === 0) {
      handleAddDelay(undefined);
    }
  };

  const handleDeleteQuestion = async (question) => {
    setOpen(false);
    await handleDelete(question?.testId);
    setQuestionToDelete(undefined);
  };

  const handleCloseEditor = () => {
    setHideAddQuesiton(true);
    setQuestionOnEdit(null);
  };

  useEffect(() => {
    if (questionsDraft?.length >= 5) {
      setHideAddQuesiton(true);
    }
    handleAddPrefilterQuestion(questionsAdd);
    setHideAddQuesiton(true);
  }, [questionsAdd]);

  useEffect(() => {
    if (questionsDraft) {
      setQuestionsAdd(questionsDraft);
    }
  }, []);

  const delayTimes = [
    { id: 0, name: t('PREFILTER_TIME_NO_DELAY') },
    { id: 24, name: '24 hs.' },
    { id: 36, name: '36 hs.' },
    { id: 48, name: '48 hs.' },
    { id: 72, name: '72 hs.' },
  ];

  return (
    <div className="px-2 rounded-lg">
      <ConfirmModal
        showModal={questionToDelete !== undefined}
        setShowModal={() => setQuestionToDelete(undefined)}
        submitAction={() => {
          handleDeleteQuestion(questionToDelete);
        }}
        title={t('QUESTION_DELETE_PREFILTER_TEST')}
        description={t('QUESTION_DELETE_PREFILTER_TEST_DETAIL')}
      />
      <div className="my-1 my-sm-0 ml-0 d-flex justify-content-end align-items-center">
        <span
          className="text-light-black font-weight-400 text-base my-2  px-3"
          data-testid="create-evaluation-ts-custom-test-subtitle-span"
        >
          {t('CREATE_PRE_FILTER_TEST_SUBTITLE')}
        </span>
        <InfoTooltip
          placement="top"
          content={
            <div className="d-flex flex-column p-2 ml-2">
              <span className=" text-xs mb-2">{t('CREATE_PRE_FILTER_ELIMINATORY_WARNING')}</span>
            </div>
          }
        />
      </div>
      <div className="px-3 mr-3 ml-5">
        <Select
          placeholder={t('CREATE_PRE_FILTER_SELECT_QUESTIONS_PLACEHOLDER')}
          value={null}
          style={{
            width: '85%',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
          variant="filled"
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          dropdownRender={(menu) => (
            <div>
              <div className="d-flex align-items-center justify-content-between p-1">
                <span className="ml-2  text-dark font-weight-600 w-75">
                  {t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
                </span>
                <Button
                  type="link"
                  icon={
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="" placement="top">
                          {t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
                        </Tooltip>
                      }
                    >
                      <PlusOutlined />
                    </OverlayTrigger>
                  }
                  disabled={questionsAdd?.length > 4}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                    setHideAddQuesiton(false);
                  }}
                />
              </div>
              <Divider style={{ margin: '2px 0' }} />
              {menu}
            </div>
          )}
        >
          {(prefilterQuestions ?? [])?.map((x) => (
            <Option
              key={x.testId}
              value={x.testId}
              className="d-flex border-0 p-0 w-100 cursor-normal"
              style={{
                index: '-999',
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <span
                  className="ml-2  text-dark font-weight-600 w-75 break-word text-truncate"
                  title={'question | answerType'
                    .replace('question', x?.test?.text)
                    .replace('answerType', t(`CREATE_PREFILTER_QUESTIONS_${x?.test?.answerType}`))}
                >
                  {x?.test?.text}
                </span>
                <div className="d-flex align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="" placement="top">
                        {t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
                      </Tooltip>
                    }
                  >
                    <Button
                      type="link"
                      className="mr-1"
                      icon={<PlusOutlined />}
                      data-id={x.testId}
                      key={x.testId}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        handleSelectQuestion(e.currentTarget.dataset.id);
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="" placement="top">
                        {t('CREATE_PRE_FILTER_EDIT_QUESTION_SELECTED')}
                      </Tooltip>
                    }
                  >
                    <Button
                      type="link"
                      icon={
                        <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        handleSelectToEditQuestion(x);
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="" placement="top">
                        {t('CREATE_PRE_FILTER_DELETE_QUESTION_SELECTED')}
                      </Tooltip>
                    }
                  >
                    <Button
                      type="link"
                      danger
                      className="mt-1 ml-1"
                      icon={<DeleteForever fill="#4987be" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        setQuestionToDelete(x);
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </div>
      {!hideAddQuestion && (
        <div className="d-flex justify-content-center mt-2 mb-4">
          <PrefilterQuestionRow
            handleSubmit={(e) => handleAddQuestion(e)}
            editQuestion={questionOnEdit}
            closeEditor={handleCloseEditor}
            handleEdit={handleEditQuestion}
          />
        </div>
      )}
      <div className="mt-3">
        {Boolean(questionsDraft?.length > 0) && (
          <div className="d-flex flex-column">
            <span className="bg-blue-principal w-100 p-2  font-bold text-muted font-weight-bold text-xs color-white mb-1">
              {t('CREATE_PRE_FILTER_SELECTED_QUESTIONS_TITLE')}
            </span>
            <div className="d-flex gap-1 flex-wrap mt-2">
              {questionsDraft.map((item, index) => (
                <div className="d-flex bg-grey-3 gap-1 align-items-center w-100 justify-content-between">
                  <div className="align-items-center d-flex justify-content-between p-2 my-1 mx-1 gap-1">
                    <span
                      className="text-muted font-montserrat text-xs font-weight-bold text-truncate test-name-max-width mb-2 mb-md-0 question-detail-width"
                      title={item.text}
                    >
                      {index + 1} | {item.text}
                    </span>
                  </div>
                  <div className="d-flex mx-2">
                    <div className="mx-1">
                      <span
                        className=" text-xs mr-3 border-thin-blue-principal text-nowrap font-weight-bold px-3 py-2"
                        data-testid="tg-item-answer-type-span"
                      >
                        {t(`CREATE_PREFILTER_QUESTIONS_${item.answerType}`)}
                      </span>
                    </div>
                    <button
                      className="bg-transparent border-0 outline-none mx-3"
                      onClick={() => handleSelectToEditQuestion(item, true)}
                      type="button"
                      data-testid="tg-item-edit-btn"
                    >
                      <EditPen />
                    </button>
                    <button
                      disabled={index >= questionsAdd.length - 1}
                      className="bg-transparent border-0 outline-none mx- mt-2 d-flex align-items-center"
                      type="button"
                      onClick={() => handleOrder(index, index + 1, 'prefilterQuestions')}
                      data-testid="tg-item-disabled-order-down-btn"
                    >
                      <ArrowSmall
                        color={index >= questionsAdd.length - 1 ? '#828282' : '#4bafe1'}
                        classNames="d-flex align-items-center"
                      />
                    </button>
                    <button
                      disabled={index < 1}
                      className="bg-transparent border-0 outline-none ml-1 mr-2 d-flex align-items-center"
                      type="button"
                      onClick={() => handleOrder(index, index - 1, 'prefilterQuestions')}
                      data-testid="tg-item-disabled-order-up-btn"
                    >
                      <ArrowSmall
                        classNames="rotate-180 d-flex align-items-center"
                        color={index < 1 ? '#828282' : '#4bafe1'}
                      />
                    </button>
                    <button
                      onClick={() => handleRemove(index)}
                      type="button"
                      className="bg-transparent border-0 outline-none mx-1 d-flex align-items-center"
                      data-testid="tg-item-delete-btn"
                    >
                      <FaRegTrashAlt color="#4bafe1" size="1rem" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-100 d-flex justify-content-end align-items-center p-1 mt-2 cursor-pointer font-weight-600">
              <div className="d-flex">
                <span
                  className=" text-light-black font-weight-500 text-base ml-2 mt-1"
                  data-testid="create-evaluation-ts-set-time-span"
                >
                  {t('CREATE_PRE_FILTER_NEGATIVE_DELAY_TITLE')}
                </span>
                <InfoTooltip
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <span className=" text-xs mb-2">
                        {t('CREATE_PRE_FILTER_NEGATIVE_DELAY_SUBTITLE')}
                      </span>
                    </div>
                  }
                />
              </div>
              <NewStyleSelect
                error={false}
                className="text-center justify-content-center"
                value={delayTime}
                options={delayTimes}
                onChange={(e) => {
                  handleAddDelay(e.target.value);
                }}
                name="prefilterDuration"
                borderClasses="bottom-border text-center"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
