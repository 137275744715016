import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'antd';
import { v4 } from 'uuid';
import { processProfilesQuery } from '../../redux/actions/profilesActions';
import search from '../../assets/profile/search.svg';
import { t } from '../../i18n/i18n';
import FiltersSkeleton from './FiltersSkeleton';
import './ProfileSearch.scss';
import setGenre from '../../utils/filters/setGenre';
import setLanguage from '../../utils/filters/setLanguage';
import setStateCandidate from '../../utils/filters/setStateCandidate';
import setProctoringAmountOfEvents from '../../utils/filters/setProctoringAmountOfEvents';
import './ProfilesSearchDashboard.scss';
import ProctoringRangeFilters from '../ProctoringRangeFilters/ProctoringRangeFilters';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import setVisible from '../../utils/filters/setVisible';

function ProfilesSearchDashboard({
  setAmountOfEvents,
  addFilter,
  removeAppliedFilter,
  showFilters,
  isLoading,
  score,
  setScore,
  sliderKey,
  setSliderKey,
  addScoreFilter,
  textInput,
  handleInput,
  handleSubmit,
}) {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);
  const { appliedFilters, availableFilters } = useSelector((state) => state.profiles);
  const [countryIds, setCountryIds] = useState([]);
  useEffect(() => {
    dispatch(processProfilesQuery());
    setCountryIds(
      appliedFilters?.filter((item) => item.type === 'country')?.map((item) => Number(item?.id))
    );
  }, [appliedFilters]);

  useEffect(() => {
    const minScore = appliedFilters.find((f) => f.name === 'minScore');
    const maxScore = appliedFilters.find((f) => f.name === 'maxScore');
    if (!minScore && !maxScore) {
      setSliderKey(v4());
      setScore({
        min: 0,
        max: 100,
      });
    }
  }, [appliedFilters]);

  const getSelectedList = (filterType) =>
    appliedFilters.filter((item) => item.type === filterType).map((item) => item.id) ?? [];

  const parseAvailableFilters = (filters, filterType) =>
    filters ? filters.map((item) => ({ ...item, displayName: item.name, type: filterType })) : [];

  const handleAddFilter = (filter) => {
    const existentFilters = getSelectedList(filter.type);
    if (!existentFilters.includes(filter.id)) {
      addFilter(filter);
    } else {
      removeAppliedFilter(filter);
    }
  };

  return (
    <>
      <div className="profile-search-container w-100">
        {(profilesState.loading && !Object.keys(availableFilters).length) || isLoading ? (
          <FiltersSkeleton dashboard />
        ) : (
          <div className="small" data-tour-id="profile-filters">
            <div className="w-100">
              {showFilters && (
                <div className="d-flex align-items-center w-100 mb-3 filters-container-gap">
                  <div className="w-100">
                    <MultipleSelect
                      title={t('PROFILES_FILTER_LOCATION')}
                      firstSelected
                      showSelectedAmount
                      classNames="filters-dropdown border-maya-blue"
                      options={parseAvailableFilters(availableFilters?.locations, 'country')}
                      onSelect={handleAddFilter}
                      selectedList={getSelectedList('country')}
                      CustomArrow={DropDownArrow}
                      emptyOptionsText={t(
                        'DASHBOARD_FILTERS_PROCTORING_COUNTRIES_EMPTY_STATE_TITLE'
                      )}
                    />
                  </div>
                  {!!availableFilters?.sex?.length && (
                    <div className="w-100">
                      <MultipleSelect
                        title={t('PROFILES_FILTER_GENRE')}
                        firstSelected
                        showSelectedAmount
                        classNames="filters-dropdown border-maya-blue"
                        options={parseAvailableFilters(setGenre(availableFilters.sex), 'sex')}
                        onSelect={handleAddFilter}
                        selectedList={getSelectedList('sex')}
                        CustomArrow={DropDownArrow}
                      />
                    </div>
                  )}
                  {!!availableFilters?.languages?.length && (
                    <div className="w-100">
                      <MultipleSelect
                        title={t('PROFILES_FILTER_LANGUAGE')}
                        firstSelected
                        showSelectedAmount
                        classNames="filters-dropdown border-maya-blue"
                        options={parseAvailableFilters(
                          setLanguage(availableFilters.languages),
                          'languages'
                        )}
                        onSelect={handleAddFilter}
                        selectedList={getSelectedList('languages')}
                        CustomArrow={DropDownArrow}
                      />
                    </div>
                  )}
                  {!!availableFilters?.visible?.length && (
                    <div className="w-100">
                      <MultipleSelect
                        title={t('PROFILES_FILTER_VISIBLE')}
                        firstSelected
                        showSelectedAmount
                        classNames="filters-dropdown border-maya-blue"
                        options={parseAvailableFilters(
                          setVisible(availableFilters.visible),
                          'visible'
                        )}
                        onSelect={handleAddFilter}
                        selectedList={getSelectedList('visible')}
                        CustomArrow={DropDownArrow}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="d-flex align-items-center w-100 filters-container-gap">
                <form
                  onSubmit={handleSubmit}
                  data-testid="dashboard-search-form"
                  className="w-100 Navbar-container-input border border-maya-blue d-none d-lg-flex align-items-center py-1"
                >
                  <img src={search} alt={t('SEARCH_ALT')} className="h-75 ml-2" />
                  <input
                    className="border-0 input px-2 text-xs input-search-dashboard w-100"
                    onChange={handleInput}
                    type="text"
                    value={textInput}
                    placeholder={t('DASHBOARD_INPUT_SEARCH')}
                  />
                </form>
                {!!Object.keys(availableFilters).length && (
                  <div className="text-center w-100">
                    <div className="d-flex justify-content-between">
                      <span className=" text-xs">{t('DASHBOARD_SEARCH_HEADERS_SCORE')}</span>
                      <span className="font-weight-bold ">{`${score.min} - ${score.max}`}</span>
                    </div>
                    <Slider
                      key={sliderKey}
                      range
                      min={0}
                      max={100}
                      defaultValue={[score.min, score.max]}
                      onAfterChange={(e) => {
                        addScoreFilter([
                          { name: 'minScore', id: e[0], type: 'minScore' },
                          { name: 'maxScore', id: e[1], type: 'maxScore' },
                        ]);
                        setScore({
                          min: e[0],
                          max: e[1],
                        });
                      }}
                    />
                  </div>
                )}
                {!!Object.keys(availableFilters).length && (
                  <div className="w-100 d-flex align-items-center">
                    <ProctoringRangeFilters
                      filters={setProctoringAmountOfEvents()}
                      setAmountOfEvents={setAmountOfEvents}
                      type={['minEvents', 'maxEvents']}
                    />
                  </div>
                )}
                {!!Object.keys(availableFilters).length && (
                  <div className="w-100 d-flex align-items-center">
                    <MultipleSelect
                      title={t('DASHBOARD_FILTERS_PROCTORING_PROCESS_STATUS_PLACEHOLDER')}
                      firstSelected
                      showSelectedAmount
                      classNames="filters-dropdown border-maya-blue"
                      options={setStateCandidate()}
                      onSelect={handleAddFilter}
                      selectedList={getSelectedList('processStatus')}
                      CustomArrow={DropDownArrow}
                      zIndex="z-index-9"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfilesSearchDashboard;
