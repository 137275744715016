import { SET_CUSTOM_EMAIL, GET_CUSTOM_EMAIL, CLEAN_CUSTOM_EMAIL } from '../actionTypes';

export const getCustomEmails = () => ({
  type: GET_CUSTOM_EMAIL,
});

export const setCustomEmails = (payload) => ({
  type: SET_CUSTOM_EMAIL,
  payload,
});

export const cleanFiltersCustomEmails = () => ({
  type: CLEAN_CUSTOM_EMAIL,
});
