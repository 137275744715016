import React, { useEffect, useState } from 'react';
import { Button, Select, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import WeightsEmptyState from '../../assets/evaluation/WeightsEmptyState';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { inHtmlTag } from '../../utils/inHtmlTag';
import TestGroupsListItem from '../TestGroupsListItem/TestGroupsListItem';
import {
  AnswerTypeEnum,
  PlanFeaturesIds,
  ReviewTypeEnum,
  TestGroupTypes,
} from '../../constants/enums';
import QuickConfigurationsItem from '../QuickConfigurationsItem/QuickConfigurationsItem';
import GenericToggle from '../GenericToggle/GenericToggle';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';
import { generateHours } from '../../constants/hours';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import './CreateEvaluationFourthStep.scss';
import useRoles from '../../hooks/useRoles';
import ModalCustom from '../ModalCustom/ModalCustom';
import VideoUploader from '../UploadVideoFile/VideoUploader';
import ArrowIcon from '../../assets/common/ArrowIcon';
import { getLangCodesAI } from '../../API/services/screeningService';
import TreeMultiSelect from '../TreeMultiSelect/TreeMultiSelect';
import SliderSelector from '../SliderSelector/SliderSelector';

const CreateEvaluationFourthStep = ({
  disableCustomTestGroupWeight,
  handleChangeStep,
  missingTestgroupWeight,
  evaluation,
  handleSetEvaluationData,
}) => {
  const {
    newScreening,
    allTests,
    availableWeight,
    welcomeVideos,
    handleOrder,
    handleDeleteCustomTestGroup,
    handleTests,
    modifyTestWeight,
    handleEditTestGroupItem,
    handleEditCustomTestGroupItem,
    handleDeletePrefilterTest,
    fetchWelcomeVideos,
    addCustomWelcomeVideo,
    deleteCustomWelcomeVideo,
    handleAddVideoInfo,
    handleSelectLangCodesAIOption,
    handleInitialSetUpLangCodesAIOptions,
    handleLangCodesAIRubrics,
  } = useCreateScreening();
  const hours = generateHours();
  const { roles, loadRoles, isRole } = useRoles();
  const [withWelcomeVideo, setWithWelcomeVideo] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectLangCodes, setSelectLangCodes] = useState(false);
  const [langCodesAI, setLangCodesAI] = useState([]);
  const { Option } = Select;

  const [funcionalidad, setFuncionalidad] = useState(80);
  const [legibilidad, setLegibilidad] = useState(10);
  const [mantenibilidad, setMantenibilidad] = useState(10);
  const [eficiencia, setEficiencia] = useState(0);

  const sliders = [
    {
      label: t('LANG_CODE_RUBRIC_1'),
      tooltipLabel: t('LANG_CODE_RUBRIC_1_TOOLTIP'),
      value: funcionalidad,
      setter: setFuncionalidad,
      min: 50,
      initialValue: 80,
    },
    {
      label: t('LANG_CODE_RUBRIC_2'),
      tooltipLabel: t('LANG_CODE_RUBRIC_2_TOOLTIP'),
      value: legibilidad,
      setter: setLegibilidad,
      min: 0,
      initialValue: 10,
    },
    {
      label: t('LANG_CODE_RUBRIC_3'),
      tooltipLabel: t('LANG_CODE_RUBRIC_3_TOOLTIP'),
      value: mantenibilidad,
      setter: setMantenibilidad,
      min: 0,
      initialValue: 10,
    },
    {
      label: t('LANG_CODE_RUBRIC_4'),
      tooltipLabel: t('LANG_CODE_RUBRIC_4_TOOLTIP'),
      value: eficiencia,
      setter: setEficiencia,
      min: 0,
      initialValue: 0,
    },
  ];

  const calculateWeight = () => {
    if (allTests?.length === 1) {
      modifyTestWeight(allTests[0].id, 100);
      return;
    }
    const customTestAmount = allTests?.reduce((count, testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [
            AnswerTypeEnum.CHECK,
            AnswerTypeEnum.TEXT_AI,
            AnswerTypeEnum.WRITE,
            AnswerTypeEnum.CODE_AI,
          ].includes(testGroup.answerType)
        )
      ) {
        return count + 1;
      }
      return count;
    }, 0);

    const allTestsQuantitatives = allTests?.filter((x) => x?.type === TestGroupTypes.QUANTITATIVE);
    const allRemainingTests = allTests?.filter((x) => x?.type !== TestGroupTypes.QUANTITATIVE);

    const testAmount = allTestsQuantitatives?.length;
    let tgWeight = 100;
    let customTgWeight = 0;

    if (customTestAmount > 0) {
      tgWeight = testAmount > 0 ? Math.floor(99 / testAmount) : 0;
      customTgWeight = 100 - tgWeight * testAmount;
    } else {
      tgWeight = testAmount > 0 ? Math.floor(100 / testAmount) : 0;
    }

    allTestsQuantitatives?.forEach((testGroup, index) => {
      const isLastElement = index === allTestsQuantitatives.length - 1;
      if (isLastElement && customTestAmount === 0 && tgWeight * testAmount < 100) {
        // si la cuenta no da exacta, se suma punto restante
        const lastWeight = 100 - tgWeight * (testAmount - 1);
        modifyTestWeight(testGroup.id, lastWeight);
      } else {
        modifyTestWeight(testGroup.id, tgWeight);
      }
    });

    allRemainingTests.forEach((testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [
            AnswerTypeEnum.CHECK,
            AnswerTypeEnum.TEXT_AI,
            AnswerTypeEnum.VIDEO,
            AnswerTypeEnum.CODE_AI,
          ].includes(testGroup.answerType)
        )
      ) {
        modifyTestWeight(testGroup.id, customTgWeight);
      }
    });
  };

  const handleEnableDates = () => {
    handleSetEvaluationData({
      ...evaluation,
      emptyDates: !evaluation.emptyDates,
      ...(!evaluation.emptyDates
        ? {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date('9999-01-01').toISOString(),
              hours: '00:00',
            },
          }
        : {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date().toISOString(),
              hours: '01:00',
            },
          }),
    });
  };

  const handleSelectLangCodes = async () => {
    setSelectLangCodes(!selectLangCodes);
  };

  const handleToggleDownloadCertificate = () => {
    handleSetEvaluationData({
      ...evaluation,
      allowCertificateDownload: !evaluation.allowCertificateDownload,
    });
  };

  const handleEnableProctoring = () => {
    handleSetEvaluationData({
      ...evaluation,
      proctoringEnabled: !evaluation.proctoringEnabled,
    });
  };

  const handleEnableRequireCompletedProfile = () => {
    handleSetEvaluationData({
      ...evaluation,
      requireCompletedProfile: !evaluation.requireCompletedProfile,
    });
  };

  const handleEnableReminderToCandidates = () => {
    handleSetEvaluationData({
      ...evaluation,
      reminderToCandidates: !evaluation.reminderToCandidates,
    });
  };

  const handleEnablePsychotechnic = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      reviewType:
        evaluation.reviewType === ReviewTypeEnum.GLOBAL
          ? ReviewTypeEnum.PSYCHOTECHNICS
          : ReviewTypeEnum.GLOBAL,
    });
  };

  const handleEnableExclusiveUsers = () => {
    handleSetEvaluationData({
      ...evaluation,
      exclusiveUsers: !evaluation.exclusiveUsers,
    });
  };

  const handleSetStartDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        ...evaluation?.startDate,
        hours: e?.name,
      },
    });
  };

  const handleSetEndDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      endDate: {
        ...evaluation?.endDate,
        hours: e?.name,
      },
    });
  };

  const handleFormatPrefilterPreview = () => {
    if (newScreening?.prefilterQuestions?.length > 0) {
      return {
        name: newScreening?.name,
        type: TestGroupTypes.PRE_FILTER,
        tests: newScreening.prefilterQuestions,
      };
    }
  };

  const handleVideoModal = async () => {
    if (!withWelcomeVideo) {
      setWithWelcomeVideo(true);
      setLoadingVideos(true);
      await fetchWelcomeVideos();
      setLoadingVideos(false);
    } else {
      setWithWelcomeVideo(false);
      handleRestoreVideo();
    }
  };

  const handleSelectVideo = (e) => {
    if (e) {
      if (newScreening.welcomeVideoTitle === e.name && newScreening.welcomeVideoUrl === e.id) {
        handleAddVideoInfo({ welcomeVideoUrl: null, welcomeVideoTitle: null });
      } else {
        handleAddVideoInfo({ welcomeVideoUrl: e.id, welcomeVideoTitle: e.name });
      }
    }
  };

  const handleSelectCustomVideo = (e) => {
    handleAddVideoInfo(e);
    addCustomWelcomeVideo(e);
  };

  const handleRestoreVideo = (title) => {
    handleAddVideoInfo({ welcomeVideoTitle: null, welcomeVideoUrl: null });
    setOpenVideoModal(false);
    if (title && title === newScreening.welcomeVideoTitle) deleteCustomWelcomeVideo(title);
  };

  useEffect(() => {
    loadRoles();
    calculateWeight();
    const getLangCodes = async () => {
      const preLangCodesAvailable = [];
      if (newScreening?.allTests?.length > 0) {
        newScreening?.allTests.forEach((test) => {
          if (test?.preLangCodesAvailable?.length > 0) {
            preLangCodesAvailable.push(...test.preLangCodesAvailable);
          }
        });
      }
      const langCodesResponse = await getLangCodesAI(preLangCodesAvailable);

      if (langCodesResponse) {
        const language = localStorage.getItem('language') || 'es';
        const langCodesFormatted = langCodesResponse.map((item) => ({
          ...item,
          name: item.languages[language],
        }));
        setLangCodesAI(langCodesFormatted);
        handleInitialSetUpLangCodesAIOptions(langCodesResponse, sliders);
      }
    };
    if (hasCustomLangCodesAI) {
      getLangCodes();
    }
    if (newScreening.welcomeVideoUrl) {
      setWithWelcomeVideo(true);
      handleVideoModal();
    }
  }, []);

  useEffect(() => {
    if (
      !welcomeVideos.find((v) => v.id === newScreening.welcomeVideoUrl) &&
      newScreening.welcomeVideoUrl
    ) {
      handleSelectCustomVideo({
        welcomeVideoTitle: newScreening.welcomeVideoTitle,
        welcomeVideoUrl: newScreening.welcomeVideoUrl,
      });
    }
  }, [welcomeVideos]);

  const handleQuestionButton = ({ newItem }) => {
    const itemFound = newScreening?.testGroups?.find((item) => item?.id === newItem?.id);
    if (itemFound) {
      handleEditTestGroupItem({ newTestGroupItem: newItem });
    } else {
      handleEditCustomTestGroupItem({ newCustomTestGroupItem: newItem });
    }
  };

  const calculateWeightAssigned = () => {
    const assignedWeight = availableWeight === 0 ? 100 : 100 - availableWeight;
    return assignedWeight;
  };

  const hasCustomLangCodesAI = () =>
    newScreening?.allTests.some((testgroup) => testgroup.withCustomLangCodesAI) ||
    newScreening?.customTestGroup.some((test) => test.answerType === AnswerTypeEnum.CODE_AI);

  return (
    <div
      data-testid="create-evaluation-fourth-step"
      className="d-flex flex-column w-100 overflow-auto container-additional-configuration"
    >
      <ModalCustom
        show={openVideoModal}
        setShow={setOpenVideoModal}
        onClose={handleRestoreVideo}
        onCancel={(e) => (!uploadingFile ? handleRestoreVideo(e) : () => {})}
        onOk={() => setOpenVideoModal(false)}
        onOkDisabled={
          (!newScreening.welcomeVideoTitle && !newScreening.welcomeVideoUrl) || uploadingFile
        }
        onOkText={t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
        key="custom-upload"
        onCancelText={t('CONFIRM_MODAL_CANCEL')}
        title={t('CREATE_EVALUATION_WELCOME_VIDEO_ADD_MODAL_TITLE')}
        subtitle={t('CREATE_EVALUATION_WELCOME_VIDEO_ADD_MODAL_SUBTITLE')}
        input={
          <VideoUploader
            t={t}
            setWelcomeVideos={handleSelectCustomVideo}
            newScreening={newScreening}
            deleteCustomWelcomeVideo={handleRestoreVideo}
            uploadingFile={uploadingFile}
            setUploadingFile={setUploadingFile}
          />
        }
      />
      <span
        className=" font-weight-600 text-base text-light-black"
        data-testid="create-evaluation-fs-test-title-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TESTS_TITLE')}
      </span>
      {allTests.length ? (
        <div className="mt-2 d-flex flex-column">
          <div className="row mx-3">
            {newScreening?.prefilterQuestions?.length > 0 && (
              <div className="col-md-4 d-flex" key="prefilter-card-fourth-step">
                <TestGroupsListItem
                  key={newScreening?.prefilterQuestions?.name}
                  testGroup={handleFormatPrefilterPreview()}
                  disableCustomWeight
                  disableOrderUp
                  disableOrderDown
                  handleDelete={() => handleDeletePrefilterTest()}
                />
              </div>
            )}
            {allTests
              ?.sort((a, b) => a.order - b.order)
              ?.map((item, index) => (
                <div className="col-md-4 d-flex" key={item.id}>
                  <TestGroupsListItem
                    key={item.id}
                    testGroup={item}
                    disableCustomWeight={item?.type === 'custom' && disableCustomTestGroupWeight}
                    disableOrderUp={index === 0}
                    disableOrderDown={index === newScreening.allTests.length - 1}
                    handleOrder={handleOrder}
                    handleQuestionButton={handleQuestionButton}
                    handleDelete={() =>
                      item.type === 'custom' ? handleDeleteCustomTestGroup() : handleTests(item)
                    }
                    index={index}
                    fromCreateEvaluation
                  />
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-between font-weight-500 text-xs border-blue-dark pb-1">
            <div className="d-flex gap-2">
              <span>{`${t(
                'FOURTH_STEP_CREATE_EVALUATION_ASSIGN_VALUE'
              )} ${calculateWeightAssigned()}%`}</span>
              <span>{`${t('FOURTH_STEP_CREATE_EVALUATION_TO_ASSIGN_VALUE')} ${
                availableWeight ?? 0
              }%`}</span>
            </div>
            <div className="d-flex gap-2">
              {missingTestgroupWeight && (
                <span className="orange-error">
                  {t('FOURTH_STEP_CREATE_EVALUATION_WARNING_MISSING_VALUE')}
                </span>
              )}
              {availableWeight !== 0 ? (
                <span className="orange-error">
                  {t('FOURTH_STEP_CREATE_EVALUATION_WARNING_WRONG_VALUE')}
                </span>
              ) : (
                <span className="weight-success">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M4.75 9L7.96429 11.6786L12.25 6.32143M8.5 16.5C7.51509 16.5 6.53982 16.306 5.62987 15.9291C4.71993 15.5522 3.89314 14.9997 3.1967 14.3033C2.50026 13.6069 1.94781 12.7801 1.5709 11.8701C1.19399 10.9602 1 9.98491 1 9C1 8.01509 1.19399 7.03982 1.5709 6.12987C1.94781 5.21993 2.50026 4.39314 3.1967 3.6967C3.89314 3.00026 4.71993 2.44781 5.62987 2.0709C6.53982 1.69399 7.51509 1.5 8.5 1.5C10.4891 1.5 12.3968 2.29018 13.8033 3.6967C15.2098 5.10322 16 7.01088 16 9C16 10.9891 15.2098 12.8968 13.8033 14.3033C12.3968 15.7098 10.4891 16.5 8.5 16.5Z"
                      stroke="#4987be"
                      strokeWidth="2"
                    />
                  </svg>
                  {t('ALL_WEIGHT_ASSIGNED_DISCLAIMER')}
                </span>
              )}
            </div>
          </div>
          <div className="border-blue-dark" />
        </div>
      ) : (
        <div className="mt-4 w-66 mx-auto d-flex">
          <WeightsEmptyState testid="fourth-step-empty-state-icon" />
          <div className="d-flex flex-column ml-5">
            <span
              className=" text-base font-weight-bold text-title mb-2"
              data-testid="create-evaluation-fs-empty-state-title-span"
            >
              {t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_TITLE')}
            </span>
            <span
              className=" text-base font-weight-500"
              dangerouslySetInnerHTML={{
                __html: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE').replace(
                  '{bold}',
                  inHtmlTag({
                    tag: 'b',
                    className: '',
                    content: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE_BOLD'),
                  })
                ),
              }}
              data-testid="create-evaluation-fs-empty-state-subtitle-span"
            />
            <div className="ml-auto mt-3">
              {[
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_TEST_BUTTON'),
                  onClick: () => handleChangeStep(1),
                  id: '50f11e3f-2c0f-4585-bc50-6c0d3ef96708',
                },
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_QUESTION_BUTTON'),
                  onClick: () => handleChangeStep(2),
                  classNames: 'ml-2',
                  id: '88ff2089-1bd4-4c7e-a293-a7988b52258d',
                },
              ].map((item, index) => (
                <button
                  key={item.id}
                  onClick={item.onClick}
                  className={`rounded-0 create-evaluation-secondary-button  font-weight-700 bg-transparent py-2 px-4 text-xs ${item.classNames}`}
                  type="button"
                  data-testid={`create-evaluation-fs-button-option-${index}-btn`}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-column gap-2 mx-3">
        <span className=" font-weight-600 text-base text-light-black my-2">
          {t('CREATE_EVALUATION_OPTIONAL_CONFIGURATION')}
        </span>
        {isRole(roles.TESTGROUPCREATOR) && (
          <QuickConfigurationsItem
            title={t('PSYCHOTECHNIC')}
            subtitle={t('ENABLE_PSYCHOTECHNIC_DESCRIPTION')}
            toggleData={
              <GenericToggle
                data-testid="toggle"
                defaultActive={evaluation.reviewType === ReviewTypeEnum.PSYCHOTECHNICS}
                actionFunction={handleEnablePsychotechnic}
              />
            }
          />
        )}
        {hasCustomLangCodesAI() && (
          <DisabledFeature
            className="d-flex w-100 align-items-center position-relative"
            childrenClassname="w-100"
            iconClassName="fourth-step-premium-functions"
            featureId={PlanFeaturesIds.TEXT_AI}
          >
            <QuickConfigurationsItem
              title={t('CREATE_EVALUATION_LANG_CODES_SELECTION')}
              subtitle={
                <span
                  className="text-xs font-weight-500"
                  dangerouslySetInnerHTML={{
                    __html: t('CREATE_EVALUATION_LANG_CODES_SELECTION_SUBTITLE'),
                  }}
                  data-testid="create-evaluation-lang-codes-ai-subtitle-span"
                />
              }
              toggleData={
                <div className="d-flex mt-2 align-items-center">
                  <GenericToggle
                    data-testid="toggle"
                    actionFunction={handleSelectLangCodes}
                    defaultActive={false}
                  />
                </div>
              }
              contentBody={
                <>
                  {langCodesAI?.length !== 0 && selectLangCodes && (
                    <div className="d-flex flex-row columnsLangCodes">
                      <div className="mt-2">
                        <TreeMultiSelect
                          options={langCodesAI}
                          value={newScreening?.langCodesAIOptions}
                          onChange={(value) => {
                            handleSelectLangCodesAIOption(value);
                          }}
                          buttonClassNames={`${
                            newScreening?.langCodesAIOptions?.length && 'border-maya-blue'
                          }`}
                          className="select-color drops-filter flex-row mr-2"
                          label={t('MULTIPLE_SELECT_LANG_CODE_PLACEHOLDER')}
                          labelPosition={{ top: 8 }}
                          openDrowdown={openDropdown}
                          textSize="12px"
                          showSelectUnselectAll="true"
                        />
                      </div>
                      <div>
                        <SliderSelector
                          handleLangCodesAIRubrics={handleLangCodesAIRubrics}
                          sliders={sliders}
                        />
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </DisabledFeature>
        )}
        <DisabledFeature
          className="d-flex w-100 align-items-center position-relative"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.CUSTOM_DATES}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_PLAN_DATES')}
            subtitle={
              <span
                className="text-xs font-weight-500"
                dangerouslySetInnerHTML={{
                  __html: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE').replace(
                    '{bold}',
                    inHtmlTag({
                      tag: 'b',
                      className: '',
                      content: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE_BOLD'),
                    })
                  ),
                }}
                data-testid="create-evaluation-fs-date-subtitle-span"
              />
            }
            toggleData={
              <div className="d-flex mt-2 align-items-center">
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={!evaluation.emptyDates}
                  actionFunction={handleEnableDates}
                />
              </div>
            }
            contentBody={
              <>
                {!evaluation.emptyDates && (
                  <div className="ml-4 mt-2">
                    <div className="d-flex justify-content-start">
                      <div className="w-10">
                        <CalendarDatePicker
                          stageData={evaluation}
                          dateType="startDate"
                          buttonClassNames="text-nowrap create-evaluation-datepickers"
                          placeholder={t('CREATE_EVALUATION_START_DATE_DATEPICKER_PLACEHOLDER')}
                          setStageData={handleSetEvaluationData}
                        />
                      </div>
                      <DropdownSelect
                        searchable
                        options={hours}
                        selected={evaluation?.startDate?.hours}
                        selectedPlaceholder
                        setState={handleSetStartDate}
                        className="time-picker-button"
                        placeholderClassname="ml-4"
                      />
                    </div>
                    <div className="d-flex justify-content-start">
                      <div className="w-10">
                        <CalendarDatePicker
                          stageData={evaluation}
                          dateType="endDate"
                          iconClassNames="ml-4 pl-2"
                          buttonClassNames="text-nowrap create-evaluation-datepickers"
                          placeholder={t('CREATE_EVALUATION_END_DATE_DATEPICKER_PLACEHOLDER')}
                          setStageData={handleSetEvaluationData}
                        />
                      </div>
                      <DropdownSelect
                        options={hours}
                        selected={evaluation?.endDate?.hours || '00:00'}
                        selectedPlaceholder
                        setState={handleSetEndDate}
                        className="time-picker-button"
                        placeholderClassname="ml-4"
                      />
                    </div>
                  </div>
                )}
              </>
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center position-relative"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.PROCTORING}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_PROCTORING')}
            subtitle={t('CREATE_EVALUATION_ENABLE_PROCTORING_DESCRIPTION')}
            toggleData={
              <GenericToggle
                data-testid="toggle"
                defaultActive={evaluation.proctoringEnabled}
                actionFunction={handleEnableProctoring}
              />
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center position-relative"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.EXCLUSIVE_TEMP_USERS}
          hideOnDisabled
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_EXCLUSIVE_TEMP_USERS')}
            subtitle={
              <span data-testid="create-evaluation-fs-exclusive-users-description">
                {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS_DESCRIPTION')}
              </span>
            }
            toggleData={
              <GenericToggle
                data-testid="toggle"
                defaultActive={evaluation.exclusiveUsers}
                actionFunction={handleEnableExclusiveUsers}
              />
            }
          />
        </DisabledFeature>
        <QuickConfigurationsItem
          title={t('CREATE_EVALUATION_QUICK_CONFIG_ALLOW_DOWNLOADS')}
          subtitle={
            <span data-testid="create-evaluation-fs-download-certificate-description">
              {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_DESCRIPTION')}
            </span>
          }
          toggleData={
            <GenericToggle
              data-testid="toggle"
              defaultActive={evaluation.allowCertificateDownload}
              actionFunction={handleToggleDownloadCertificate}
            />
          }
        />
        <QuickConfigurationsItem
          title={t('ENABLE_COMPLETE_PROFILE_DROPDOWN_TITLE')}
          subtitle={
            <span data-testid="create-evaluation-fs-download-certificate-description">
              {t('ENABLE_COMPLETE_PROFILE_CERTIFICATES_DESCRIPTION')}
            </span>
          }
          toggleData={
            <GenericToggle
              data-testid="toggle"
              defaultActive={evaluation.requireCompletedProfile}
              actionFunction={handleEnableRequireCompletedProfile}
            />
          }
        />
        <DisabledFeature
          className="d-flex w-100 align-items-center"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.TEXT_AI}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_WELCOME_VIDEO')}
            subtitle={t('CREATE_EVALUATION_WELCOME_VIDEO_SUBTITLE')}
            toggleData={
              <GenericToggle
                data-testid="toggle-welcome-video"
                className="my-1"
                defaultActive={withWelcomeVideo}
                actionFunction={() => handleVideoModal()}
              />
            }
            contentBody={
              <div className="">
                {withWelcomeVideo &&
                  (loadingVideos ? (
                    <div className="mt-2">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 30,
                              color: 'maya-blue',
                              marginLeft: '4em',
                            }}
                            spin
                          />
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className="ml-4 align-items-center mt-2">
                        <div className="d-flex justify-content-start">
                          <div className="d-flex justify-content-start">
                            <Select
                              placeholder={t('CREATE_EVALUATION_WELCOME_VIDEO_SELECT_PLACEHOLDER')}
                              onSelect={(e) => {
                                const video = welcomeVideos.find((v) => v.id === e);
                                handleSelectVideo(video);
                              }}
                              value={newScreening.welcomeVideoUrl}
                              key="welcome-video-selector"
                              showArrow
                              suffixIcon={<ArrowIcon />}
                              className="w-100"
                            >
                              {welcomeVideos?.map((video) => (
                                <Option value={video.id} key={video.id}>
                                  {video?.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>{t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}</Tooltip>
                            }
                          >
                            <Button
                              type="link"
                              className="mx-3"
                              icon={<PlusOutlined />}
                              data-id="add-welcome-video-options"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenVideoModal(true);
                              }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            }
          />
        </DisabledFeature>
        <QuickConfigurationsItem
          title={t('REMEMBER_TEST')}
          subtitle={t('REMEMBER_TEST_DETAILS')}
          toggleData={
            <GenericToggle
              data-testid="toggle"
              defaultActive={evaluation.reminderToCandidates}
              actionFunction={handleEnableReminderToCandidates}
            />
          }
        />
      </div>
    </div>
  );
};

export default CreateEvaluationFourthStep;
