import { CLEAN_CUSTOM_EMAIL, SET_CUSTOM_EMAIL } from '../actionTypes';

const initialState = {
  customEmailsPaginated: {
    list: [],
    filter: {},
    tabsFilter: [ { name: 'WITHOUT_ACCEPT', total: 5, }, { name: 'WITHOUT_START', total: 133, }, { name: 'PROCESSED', total: 470, }, ],
    pagination: { limit: 10, offset: 0 },
    isLoading: false,
    reload: false,
  },
};

function customEmailReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_EMAIL: {
      return {
        ...state,
        customEmailsPaginated: { ...state.customEmailsPaginated, ...action.payload },
      };
    }
    case CLEAN_CUSTOM_EMAIL: {
      return {
        ...state,
        customEmailsPaginated: {
          ...state.customEmailsPaginated,
          filter: {
            limit: state.customEmailPaginated?.filter?.lsimit,
            offset: state.customEmailPaginated?.filter?.osffset,
          },
        },
      };
    }
    default:
      return state;
  }
}

export default customEmailReducer;
