import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { BiTrash } from 'react-icons/bi';
import Modal from '../../Modal/Modal';
import { t } from '../../../i18n/i18n';
import Input from '../../ScreeningForm/Input';
import { languagesList } from '../../DropdownLanguage/DropdownLanguage';
import CountryFlag from '../../CountryFlag/CountryFlag';
import { SelectLanguage, buildOptionLanguage } from '../../SelectLanguages/SelectLanguage';
import { debounce } from 'lodash';

const UpdateRolesTestGroupModal = ({
  showModal,
  setShowModal = () => {},
  onClickDeleteRol = () => {},
  onSubmit = () => {},
  rolToUpdate,
}) => {
  const [languagesSelected, setLanguagesSelected] = useState([]);
  const [name, setName] = useState({});

  const onChangeSelect = (languages = []) => {
    setLanguagesSelected(languages);
    const nameExistent = Object.keys(name);
    const langValue = languages?.map((item) => item?.value);
    const attr = Object.values(langValue).map((value) => ({
      [value]: nameExistent.includes(value) ? name[value] : '',
    }));
    const newAttr = attr?.reduce((a, b) => ({ ...a, ...b }), {});

    setName(newAttr);
  };

  useEffect(() => {
    let newLanguages = [languagesList[0]];
    if (rolToUpdate?.languages) {
      const langToUpdate = Object.keys(rolToUpdate?.languages);
      newLanguages = languagesList?.filter((item) => langToUpdate?.includes(item?.value));
    }
    setLanguagesSelected(newLanguages);

    setName(rolToUpdate?.languages ?? { es: rolToUpdate?.displayName });
  }, [rolToUpdate]);

  const onSubmitModal = () => {
    if (languagesSelected?.length === 0) {
      notification.error({ message: 'Debe tener al menos 1 idioma seleccionado' });
      return null;
    }
    const names = Object.values(languagesSelected)?.filter((item) => name[item?.value] === '');
    if (names?.length > 0) {
      notification.error({ message: 'Debe tener todos los input completados' });
      return null;
    }
    const firstName = Object.values(name)[0];
    const updateRol = { displayName: firstName, languages: name };
    onSubmit(updateRol);
    return null;
  };

  const onUpdateModal = debounce(() => {
    onSubmitModal();
  }, 500);

  return (
    <Modal
      data-testid="card-screening-search-modal"
      className="modal-buttons-border d-flex justify-content-end"
      showCloseButton={false}
      show={showModal}
      minWidth={false}
      setShow={(e) => {
        setShowModal(e);
      }}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <div className="px-5 mx-5" style={{ overflowY: 'scroll' }}>
        <div className="py-5 d-flex flex-column align-items-center w-100 position-relative">
          <span className="font-weight-600  text-base mb-2">{t('UPDATE_ROL')}</span>
          <div className="position-absolute" style={{ top: 20, right: -40 }}>
            <button
              type="button"
              className="button-trash-modal "
              title={t('DELETE_MODAL_TITLE')}
              onClick={() => {
                onClickDeleteRol(rolToUpdate);
                setShowModal(false);
              }}
            >
              <BiTrash />
            </button>
          </div>
          <div className="my-2 w-100">
            <span className=" font-weight-bold text-base my-2">
              {t('CREATE_TEST_GROUP_LANGUAGES_TITLE')}
            </span>
            <SelectLanguage
              onChange={onChangeSelect}
              defaultSelect={
                rolToUpdate?.languages
                  ? languagesList
                      ?.filter((item) => Object.keys(rolToUpdate?.languages).includes(item?.value))
                      ?.map((item) => buildOptionLanguage(item))
                  : [buildOptionLanguage(languagesList[0])]
              }
            />
          </div>
          <div className="w-100">
            {languagesSelected?.map((item) => (
              <div className="my-2">
                {' '}
                <span className="font-weight-500  text-light-black mb-3">
                  {t('NAME')} <CountryFlag countryIso={item.iso} size="sm" className="mr-2" />
                </span>
                <Input
                  placeholder={t('Ej: Desarrollador Web')}
                  className="flex-grow-1"
                  name="name"
                  value={name[item.value]}
                  onChange={(value) => setName({ ...name, [item.value]: value })}
                />
              </div>
            ))}
          </div>

          <div>
            <button
              type="button"
              className="px-5 py-3 hover-danger modal-buttons-border bg-transparent mt-5 dont-delete-draft-button-border border-red-danger text-red-danger  outline-none text-xs font-weight-700 mr-2"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('CANCEL')}
            </button>
            <button
              type="button"
              className="px-5 py-3 modal-buttons-border mt-5 border-0 text-white  outline-none text-xs font-weight-700 ml-2"
              style={{ backgroundColor: '#449cc8' }}
              onClick={onUpdateModal}
            >
              {t('UPDATE')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateRolesTestGroupModal;
