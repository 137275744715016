import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';
import Input from '../ScreeningForm/Input';
import RemoveInputIcon from '../../assets/workspace/RemoveInputIcon';
import { validateCompanyEmail } from '../../validations/screeningValidation';
import {
  createWorkspaceInvitationsBatch,
  getWorkspaceDuplicatedInvitations,
} from '../../API/services/companyService';
import useImportCsv from '../../hooks/useImportCsv';
import './InviteMembersForm.scss';
import { PlanFeaturesIds, PartnerWorkspaceRole } from '../../constants/enums';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import DebouncedButton from '../Button/Button';

const InviteMembersForm = ({ className = '', onSubmit, pagination }) => {
  const { currentWorkspace, planFeatures, currentRole } = useSelector((state) => state.auth);
  const [inputs, setInputs] = useState([{ email: '', option: PartnerWorkspaceRole.ADMINISTRATOR }]);
  const [validateErrors, setValidateErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionsRole, setOptionsRole] = useState([]);
  const { importRef, readFile, result } = useImportCsv(['email']);

  useEffect(() => {
    setOptionsRole([PartnerWorkspaceRole.ADMINISTRATOR, PartnerWorkspaceRole.VISUALIZATOR]);
  }, []);

  const addInput = () => {
    const maxInvitations = planFeatures?.features?.find(
      (item) => item.id === PlanFeaturesIds.WORKSPACE_MEMBERS
    )?.amount;
    const remainingInvitations = maxInvitations - pagination?.total - 1;

    if (remainingInvitations > 0 && Object.keys(inputs).length - 1 < remainingInvitations) {
      setInputs([...inputs, { email: '', option: PartnerWorkspaceRole.ADMINISTRATOR }]);
    } else {
      notification.open({
        message:
          'No puedes agregar mas emails a este workspace debido al plan activo, para mas informacion contacte con ventas',
        type: 'error',
      });
    }
  };

  const validateEmail = (key, value) => {
    const validEmail = validateCompanyEmail(value);
    setErrors((state) => ({
      ...state,
      [key]:
        value === ''
          ? t('EMAIL_REQUIRED_ERROR')
          : validEmail
          ? null
          : t('INVITE_MEMBERS_INVALID_EMAIL'),
    }));
    return validEmail;
  };

  const updateInput = (index, value, name) => {
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    if (validateErrors) validateEmail(index, value);
    setInputs(newInputs);
  };

  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    setErrors({ ...errors, [index]: null });
  };

  const submit = async () => {
    try {
      setLoading(true);

      const emailsErrors = inputs
        .map((row, index) => validateEmail(index, row.email))
        .some((correctEmail) => !correctEmail);

      const roleErrors = inputs
        .map((row) => row.option)
        .some((role) => !optionsRole.includes(role));
      if (emailsErrors || roleErrors) {
        setValidateErrors(true);
        return;
      }

      const emailsToInvite = [];
      const rolesToApply = [];

      inputs.forEach((row) => {
        emailsToInvite.push(row.email.toLowerCase());
        rolesToApply.push(row.option);
      });
      const duplicatedEmails = await getWorkspaceDuplicatedInvitations(
        currentWorkspace,
        emailsToInvite
      );
      inputs.map(
        (row, index) =>
          duplicatedEmails?.includes(row.email) &&
          setErrors((state) => ({
            ...state,
            [index]: t('INVITE_MEMBERS_DUPLICATED_EMAIL'),
          }))
      );
      if (duplicatedEmails.length) {
        notification.open({
          message: t('INVITE_MEMBERS_REQUEST_DUPLICATED_ERROR'),
          type: 'error',
        });
        setValidateErrors(true);
        return;
      }

      try {
        await createWorkspaceInvitationsBatch(currentWorkspace, emailsToInvite, rolesToApply);
        onSubmit?.();
        setInputs([{ email: '', option: PartnerWorkspaceRole.ADMINISTRATOR }]);
      } catch (error) {
        notification.open({
          message: t('INVITE_MEMBERS_REQUEST_ERROR'),
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (result?.length) {
      const prevEmails = [];
      inputs.map((row, index) => {
        if (row.email?.length) {
          prevEmails[index] = { email: row.email, option: row.option };
        }
        return null;
      });

      const values = Object.values(result);
      const emailsToAdd = values.map((item) => ({
        email: item.email,
        option: '',
      }));
      prevEmails.push(...emailsToAdd);
      setInputs(prevEmails);
    }
  }, [result]);

  return (
    <div className={`mh-100 invite-members-form-container d-flex flex-column ${className}`}>
      <DisabledFeature
        className="d-flex flex-column align-items-center"
        iconClassName="mb-2"
        featureId={PlanFeaturesIds.WORKSPACE_MEMBERS}
        validateAmount
        usedAmount={pagination?.total}
      >
        <div className="mb-2 d-flex flex-column">
          <span className=" font-weight-600 mb-3">{t('MEMBERS_INVITE_TITLE')}</span>
          <span className=" font-weight-500 text-xs">{t('MEMBERS_INVITE_SUBTITLE')}</span>
        </div>
        <div className="d-flex flex-column overflow-auto pr-2">
          {inputs.map((row, index) => (
            <div className="d-flex py-2 column-gap" key={index}>
              <Input
                placeholder={t('INVITE_MEMBER_FORM_PLACEHOLDER')}
                className="flex-grow-1"
                name="email"
                value={row.email}
                onChange={(text) => updateInput(index, text, 'email')}
                error={errors[index]}
                id={index}
              />
              <select
                className="px-3 rounded dropdown-input  flex-grow-1"
                name="option"
                value={row.option}
                onChange={(e) => updateInput(index, e.target.value, 'option')}
              >
                {optionsRole.map((option) => (
                  <option value={option}>{t(option)}</option>
                ))}
              </select>
              <button
                type="button"
                className="invite-members-input-delete ml-3 d-flex pt-1"
                onClick={() => removeInput(index)}
                data-testid="invite-members-form-remove-input-button"
              >
                <RemoveInputIcon />
              </button>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column py-3">
          <button
            type="button"
            className="members-invite-add-members-button  text-xs font-weight-600 mb-2"
            onClick={addInput}
            data-testid="invite-members-form-add-members-button"
          >
            {t('INVITE_MEMBER_ADD_BUTTON')}
          </button>
          <div>
            <input
              type="file"
              name="file"
              id="fileEmails"
              hidden
              ref={importRef}
              onChange={readFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              data-testid="invite-members-form-file-input"
            />
            <label htmlFor="fileEmails">
              <div className="members-invite-add-members-button  text-xs font-weight-600 cursor-pointer">
                {t('INVITE_MEMBER_ADD_FROM_FILE_BUTTON')}
              </div>
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-end py-3">
          <DebouncedButton
            type="button"
            className={`members-invite-submit-button  text-xs font-weight-bold py-2 px-4 rounded-0  text-white ${
              loading ||
              disableForWorkspaceRole(currentRole, [
                PartnerWorkspaceRole.OWNER,
                PartnerWorkspaceRole.ADMINISTRATOR,
              ])
                ? 'disabled'
                : ''
            }`}
            onClick={submit}
            disabled={
              loading ||
              disableForWorkspaceRole(currentRole, [
                PartnerWorkspaceRole.OWNER,
                PartnerWorkspaceRole.ADMINISTRATOR,
              ])
            }
            data-testid="invite-members-form-submit-button"
          >
            {t('INVITE_MEMBER_SUBMIT_BUTTON')}
          </DebouncedButton>
        </div>
      </DisabledFeature>
    </div>
  );
};

export default InviteMembersForm;
