import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import {
  getAlkymerAdvance,
  getProfile,
  setAlkymerAdvance,
  setAppliedFilters,
  setInvitedProfile,
  setProfile,
  setProfileInvitationsAndChallenges,
  setUniqueProfileData,
} from '../../redux/actions/profilesActions';
import Spinner from '../../components/spinner/Spinner';
import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import InvitedProfileData from '../../components/InvitedProfileData/InvitedProfileData';
import InvitedProfileStages from '../../components/InvitedProfileStages/InvitedProfileStages';
import {
  getAllTechnologies,
  getInvitationsAndChallenges,
  getInvitedProfile,
  getProgress,
} from '../../API/services/alkymersService';
import ModalSubmission from '../../components/ModalSubmission/ModalSubmission';
import './InvitedProfile.scss';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import {
  postSendEmailCustom,
  updateScreeningCandidatesBatch,
} from '../../API/services/screeningService';
import { setStageCandidates, setSubjectEmail } from '../../redux/actions/screeningActions';
import {
  breadcrumbAlkemyCommunity,
  breadcrumbAlkymetricsEvaluation,
} from '../../constants/breadcrumb';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole, ProfileDataTypes } from '../../constants/enums';
import useTranslations from '../../hooks/useTranslations';

const InvitedProfile = ({ isInvitedProfile }) => {
  const [disabledWorkspaceFunctions, setDisabledWorkspaceFunctions] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { template, stageCandidates, subjectEmail } = useSelector((state) => state.screenings);
  const { key } = useTranslations();
  const alkymerAdvance = useSelector((state) => state.profiles.alkymerAdvance);
  const { uniqueProfileData, profile } = useSelector((state) => state.profiles);
  const {
    editMailVisible,
    setSelected,
    setEditMailVisible,
    confirmModifyModalStage,
    startModifyStageConfirmation,
  } = useTableBatchAction();
  const [loading, setLoading] = useState(true);
  const [groupAcceleration, setGroupAcceleration] = useState({});
  const { recruitSessionId, profileId, id } = useParams();
  const containerRef = useRef(null);
  const history = useHistory();
  const { currentRole, user } = useSelector((state) => state.auth);

  useEffect(async () => {
    setDisabledWorkspaceFunctions(
      disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ])
    );
    if (recruitSessionId && profileId) {
      // invitation case
      try {
        const invitedProfileData = await getInvitedProfile(recruitSessionId, profileId);
        dispatch(setProfile(invitedProfileData?.profile));
        dispatch(
          setUniqueProfileData({
            recruitSession: invitedProfileData.recruitSession,
            type: ProfileDataTypes.EVALUATION,
            data: invitedProfileData.stages,
          })
        );
      } catch (error) {
        console.log('error:', error);
        history.goBack();
      } finally {
        setLoading(false);
      }
    } else if (id) {
      // challenge or talent case
      dispatch(getProfile(id));
      try {
        const technologyProgress = await getProgress(id).then((res) => res.technologyProgress);
        const technologies = await getAllTechnologies();
        const dictionary = {};
        technologies?.forEach((technology) => {
          dictionary[technology.id] = technology;
        });
        const progressTechsMapped = technologyProgress?.map((technology) => ({
          ...technology,
          technology: dictionary[technology.alkymetricsId],
        }));

        dispatch(
          setUniqueProfileData({ type: ProfileDataTypes.CHALLENGE, data: progressTechsMapped })
        );
      } catch (error) {
        console.log('error:', error);
      } finally {
        setLoading(false);
      }
    }

    try {
      const setProfileData = async () => {
        const profileData = await getInvitationsAndChallenges(profileId ?? id, user?.id);
        dispatch(setProfileInvitationsAndChallenges(profileData));
      };
      await setProfileData();
    } catch (error) {
      console.log('error setting unique profile:', error);
    }

    return () => {
      dispatch(setInvitedProfile(null));
      dispatch(setProfile(null));
      dispatch(setAlkymerAdvance(null));
      dispatch(setUniqueProfileData(null));
      dispatch(setAppliedFilters([]));
    };
  }, []);

  useEffect(() => {
    if (profile) {
      const groups = profile?.groups;
      const jobreadyId = groups && groups[groups.length - 1]?.group?.jobReadyProfileId;
      dispatch(getAlkymerAdvance(id, jobreadyId));
    }
  }, [profile]);

  const updateStageSingleCandidate = async (newStatus, id) => {
    const body = {
      ids: [id],
      processStatus: newStatus,
    };
    if (newStatus && uniqueProfileData?.data?.[0].invitation.processStatus !== newStatus) {
      const updated = await updateScreeningCandidatesBatch(body);
      if (updated) {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS_SINGLE'),
          type: 'success',
        });
        dispatch(
          setStageCandidates({
            ...stageCandidates,
            collection: stageCandidates?.collection?.map((screening) => ({
              ...screening,
              ...(screening?.invitationId === id ? { processStatus: body.processStatus } : {}),
            })),
          })
        );
        const tempStages = uniqueProfileData.data;
        tempStages[0].invitation.processStatus = newStatus;
        dispatch(setUniqueProfileData({ ...uniqueProfileData, data: tempStages }));
      } else {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
          type: 'error',
        });
      }
    }
  };

  const handleSendEmail = async () => {
    const id = stageCandidates?.collection?.find(
      (candidate) => candidate.userId === profile.id
    )?.invitationId;
    const requestData = {
      invitationsIds: [id],
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectEmail.subjectEmail,
        html: template.template,
      },
      customEmailId: template.id,
      language: key,
    };
    try {
      await postSendEmailCustom(requestData, {});
      notification.open({
        message: t('INVITED_SINGLE_PROFILE_CORRECTLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
      setEditMailVisible(false);
      dispatch(setSubjectEmail({ subjectEmail: '' }));
    } catch (error) {
      notification.open({
        message: t('INVITATION_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };
  return loading || (!uniqueProfileData && !profile) ? (
    <Spinner show={loading} />
  ) : editMailVisible ? (
    <EditEmailTemplate
      handleSendRequest={handleSendEmail}
      goBack={() => setEditMailVisible(false)}
      viewInfo={false}
      emailDashboard
    />
  ) : (
    <div
      data-testid="invited-profile-container"
      className="w-100 h-100 bg-profile"
      ref={containerRef}
    >
      <div className="d-flex">
        <BackgroundTop
          title={isInvitedProfile ? t('INVITED_PROFILE_CANDIDATE_BANNER_TITLE') : t('TALENT')}
          className="position-relative"
          routes={
            isInvitedProfile
              ? [
                  ...breadcrumbAlkymetricsEvaluation,
                  {
                    text: 'SCREENING_DASHBOARD_CAPSULE_TITLE',
                    clickable: true,
                    route: `/search/${recruitSessionId}`,
                  },
                ]
              : [
                  ...breadcrumbAlkemyCommunity,
                  {
                    text: 'TALENTS',
                    clickable: true,
                    route: '/profiles',
                  },
                ]
          }
          titleClassname="text-title"
        />
      </div>
      <div className="d-flex flex-column description-container mx-auto mb-5 pt-0 px-2">
        <ModalSubmission
          show={show}
          setShow={setShow}
          showResult
          isInvitedProfile={isInvitedProfile}
          {...(groupAcceleration?.skills?.length ? { groupAcceleration } : {})}
        />
        {confirmModifyModalStage}
        <div className="container-invited-profile-ca">
          <InvitedProfileData
            profile={profile}
            isInvitedProfile={isInvitedProfile}
            search={uniqueProfileData?.recruitSession}
            setSelected={setSelected}
            setEditMailVisible={setEditMailVisible}
            currentStage={
              uniqueProfileData?.data?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.recruitSessionStage
            }
            nextStage={
              uniqueProfileData?.data?.filter((stage) => !stage.invitation)[0]?.recruitSessionStage
            }
            currentInvitation={
              uniqueProfileData?.data?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.invitation
            }
            deleteCandidate={startModifyStageConfirmation}
            disabledWorkspaceFunctions={disabledWorkspaceFunctions}
          />
          <InvitedProfileStages
            search={uniqueProfileData?.recruitSession}
            isInvitedProfile={isInvitedProfile}
            score={
              uniqueProfileData?.data?.filter((stage) => Boolean(stage.invitation))[0]?.invitation
                ?.score || alkymerAdvance
            }
            setShow={setShow}
            profile={profile}
            uniqueProfileData={uniqueProfileData}
            setGroupAcceleration={setGroupAcceleration}
            updateStageSingleCandidate={updateStageSingleCandidate}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitedProfile;
