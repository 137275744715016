/**
 * Determines the percentile for a given score based on a set of percentiles.
 * @param {number} score - The score to determine the percentile for.
 * @param {Object} percentiles - An object where keys are percentiles and values are the score limits.
 * @returns {number} - The percentile the score belongs to.
 */
export function getPercentile(score, percentiles) {
  const sortedPercentiles = Object.keys(percentiles)
    .map((key) => parseInt(key, 10))
    .sort((a, b) => a - b);

  let lastMatchingPercentile = sortedPercentiles[0];

  for (let i = 0; i < sortedPercentiles.length; i += 1) {
    const percentile = sortedPercentiles[i];

    if (score >= percentiles[percentile]) {
      lastMatchingPercentile = percentile;
    } else {
      break;
    }
  }

  return lastMatchingPercentile;
}

/**
 * Calculates the percentage of scores that fall within a given percentile range.
 * @param {Array<number>} scores - The array of scores ordered.
 * @param {Object} percentiles - An object where keys are percentiles and values are the score limits.
 * @param {number} targetPercentile - The target percentile to calculate the percentage for.
 * @returns {number} - The percentage of scores within the given percentile range.
 */
export function getPercentageOfScoresInPercentile(scores, percentiles, targetPercentile) {
  const targetScoreLimit = percentiles[targetPercentile];
  const totalScores = scores.length;
  const scoresInPercentile = scores.filter((score) => score <= targetScoreLimit).length;

  return (scoresInPercentile / totalScores) * 100;
}

/**
 * Calculates the total of scores that fall within a given percentile range.
 * @param {Array<number>} scores - The array of scores ordered.
 * @param {Object} percentiles - An object where keys are percentiles and values are the score limits.
 * @param {number} targetPercentile - The target percentile to calculate the percentage for.
 * @returns {number} - The total of scores within the given percentile range.
 */
export function getAmountOfScoresInPercentile(scores, percentiles, targetPercentile) {
  const targetScoreLimit = percentiles[targetPercentile];
  const scoresInPercentile = scores.filter((score) => score <= targetScoreLimit).length;

  return scoresInPercentile;
}
