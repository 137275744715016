import { useState } from 'react';
import { useSelector } from 'react-redux';

import FilterCustomEmails from './FilterCustomEmails/FilterCustomEmails';
import { Button } from 'antd';
import { t } from '../../i18n/i18n';

export const HeaderCustomEmails = ({
  handleSubmit,
  onClickNewCustomEmail,
  headers,
  setHeaders,
  idCustomTable,
}) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  return (
    <div className="container-info container-search-dashboard pb-0 mb-0 bg-white rounded px-4 pt-4 mx-auto">
      <div className="d-flex mt-2">
        <div className="w-100 d-flex justify-content-start">
          <div>
            {' '}
            <FilterCustomEmails handleSubmit={handleSubmit} showMoreFilters={showMoreFilters} />
          </div>
          <Button
            className=" font-weight-700 bg-maya-blue text-white abm-redirect-button rounded-0"
            onClick={onClickNewCustomEmail}
            type="button"
          >
            {t('ABM_CREATE_EMAIL')}
          </Button>
        </div>
      </div>
    </div>
  );
};
