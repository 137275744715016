import { useHistory } from 'react-router-dom';
import ArrowWithCircle from '../../assets/createEvaluation/ArrowWithCircle';
import './createEvaluationSummaryNav.scss';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { t } from '../../i18n/i18n';
import { SCREENING_SEARCHES } from '../../constants/routes';
import useCreateScreening from '../../hooks/useCreateScreening';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DebouncedButton from '../Button/Button';
import { debounce } from 'lodash';

const CreateEvaluationSummaryNav = ({
  evaluation,
  existingChanges,
  setShowExitModal,
  incompleteQuestionValidation,
  handleChangeStep,
  step,
  handleOpenEvaluation,
  disableOpenEvaluation,
  handleEvaluationName,
}) => {
  const { testsDuration, allTests, customScreeningTotalTime, setPrevenReload } =
    useCreateScreening();
  const { handleCreateDraft, draftId, enableCreateButton, loadingCreateDraft } =
    useCreateEvaluation();
  const navigate = useHistory();
  const nextStepClick = debounce(() => {
    if (step < 3) handleChangeStep(step + 1);
  }, 500);

  return (
    <div className="create-evaluation-summary-nav text-sm font-weight-500">
      {step > 0 && (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <p>{t('CREATE_EVALUATION_PREVIOUS_STEP')}</p>
            </Tooltip>
          }
          placement="top"
        >
          <div
            className="cursor-pointer"
            onClick={() => {
              if (step > 0) handleChangeStep(step - 1);
            }}
          >
            <ArrowWithCircle fill={step > 0 ? '#4987be' : undefined} />
          </div>
        </OverlayTrigger>
      )}
      <div className="summary-content">
        <input
          minLength={1}
          maxLength={50}
          placeholder={t('SCREENING_NAME_INPUT_PLACEHOLDER')}
          value={evaluation?.name}
          type="text"
          onChange={handleEvaluationName}
          className={`ml-2 p-2 text-black font-weight-700 space-title-evaluation bg-transparent rounded text-sm screening-name-input evaluation-name-input mr-2 ${
            evaluation?.name?.trim()?.length >= 1 && 'completed'
          }`}
          data-testid="create-evaluation-fs-name-input"
        />
        <div className="summary-items">
          <div className="summary-item">
            <CheckOutlined />
            <span className="text-truncate">{`${allTests?.length} ${t(
              'CREATE_EVALUATION_SUMMARY_TESTS_TITLE'
            )}`}</span>
          </div>
          <div className="summary-item">
            <ClockCircleOutlined />
            <span className="text-truncate">{`${customScreeningTotalTime + testsDuration} ${t(
              'CREATE_EVALUATION_SUMMARY_MINUTES_TITLE'
            )}`}</span>
          </div>
          {/* //TODO: Hay que armar la logica para previsualizar una evaluacion */}
          {/* <div className="summary-item">
            <FaEye />
            <span>{t('CREATE_EVALUATION_SUMMARY_PREVIEW_TITLE')}</span>
          </div> */}
          {step === 3 ? (
            <DebouncedButton
              onClick={handleOpenEvaluation}
              className="px-3 py-2 rounded-0  text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue"
              type="button"
              data-testid="create-evaluation-open-search-btn"
              disabled={disableOpenEvaluation}
            >
              {t('CREATE_EVALUATION_OPEN_SEARCH_BUTTON')}
            </DebouncedButton>
          ) : (
            <DebouncedButton
              className={`summary-item button-save-and-exit mr-3 px-2 ${
                (!enableCreateButton || loadingCreateDraft) && 'disabled'
              }`}
              disabled={!enableCreateButton || loadingCreateDraft}
              onClick={() => {
                if (draftId) {
                  if (existingChanges || incompleteQuestionValidation) {
                    setShowExitModal(true);
                  } else {
                    setPrevenReload(false);
                    navigate.push(SCREENING_SEARCHES);
                  }
                } else {
                  handleCreateDraft();
                }
              }}
            >
              {t('CREATE_EVALUATION_SUMMARY_SAVE_AND_EXIT')}
            </DebouncedButton>
          )}
        </div>
      </div>
      {step < 3 && (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <p>{t('CREATE_EVALUATION_NEXT_STEP')}</p>
            </Tooltip>
          }
          placement="top"
        >
          <div onClick={() => nextStepClick()} className="cursor-pointer">
            <ArrowWithCircle rotate fill={step < 3 ? '#4987be' : undefined} />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default CreateEvaluationSummaryNav;
