import React, { useState } from 'react';
import { enGB, es, pt } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import { notification, Select } from 'antd';
import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import '../ScreeningInvitationsList.scss';
import '../../components/CreateEvaluationSecondStep/CreateScreeningStep2.scss';
import './CustomEmails.scss';
import { postSendCustomEmailByWorkspace } from '../../API/services/screeningService';

import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../../components/Modal/Modal';
import { breadcrumbAlkymetricsDashboardCandidates } from '../../constants/breadcrumb';
import { useCustomEmails } from '../../hooks/useCustomEmails';
import { HeaderCustomEmails } from '../../components/CustomEmails/HeaderCustomEmails';
import { TableCustomEmails } from '../../components/CustomEmails/TableCustomEmails';
import ModalCustom from '../../components/ModalCustom/ModalCustom';
import { restartCustomEmail } from '../../API/services/alkymersService';
import EditCustomEmailTemplate from '../../components/EditEmailTemplate/EditCustomEmailTemplate';
import useTranslations from '../../hooks/useTranslations';

registerLocale('en', enGB);
registerLocale('pt', pt);
registerLocale('es', es);

const CustomEmails = () => {
  const customEmails = useSelector((state) => state.customEmails.customEmailsPaginated);
  const endPagination = useSelector(
    (state) => state?.customEmails?.customEmailsPaginated?.pagination?.endPagination
  );

  const [disableButton, setDisableButton] = useState(false);

  const {
    getCustomEmailsForTable,
    customEmailDefault,
    sendMail,
    setSendMail,
    editMailVisible,
    setEditMailVisible,
    defaultTemplate,
    setRestartTemplate,
    restartTemplate,
    setDeleteTemplate,
    deleteTemplate,
  } = useCustomEmails();
  const { currentWorkspace, user } = useSelector((state) => state?.auth);
  const [newCustomEmail, setNewCustomEmail] = useState(false);
  const { key } = useTranslations();
  const fetchDataTable = async (page = 0, filters = {}, forceFetch = false) => {
    if (
      (page > 0 && Object.keys(filters).length && endPagination) ||
      customEmails?.isLoading ||
      customEmails?.reload ||
      !forceFetch
    ) {
      return;
    }

    await getCustomEmailsForTable({
      ...filters,
      workspaceId: `${currentWorkspace}`,
      limit: 20,
      offset: page,
    });
  };

  const { screeningModalContent, closeModal, loadingModal, showModal } = useModalDetails();

  const { template, subjectEmail } = useSelector((state) => state.screenings);

  const handleGoBack = async () => {
    setEditMailVisible(null);
    setSendMail('');
    setNewCustomEmail(false);
    await fetchDataTable(0, {}, true);
  };

  const onClickNewCustomEmail = () => {
    setEditMailVisible(customEmailDefault);
    setNewCustomEmail(true);
  };

  const onClickRestartTemplate = async () => {
    setDisableButton(true);
    try {
      const response = await restartCustomEmail(restartTemplate?.id);
      if (response?.deletedAt) {
        notification.open({
          message: t('TEMPLATE_RESTARTED_SUCCESSFULLY'),
          icon: <i className="fa fa-check" style={{ color: 'green' }} />,
        });
        setRestartTemplate(null);
        await fetchDataTable(0, {}, true);
      }
    } finally {
      setDisableButton(false);
    }
  };
  const onClickDeleteTemplate = async () => {
    setDisableButton(true);
    try {
      const response = await restartCustomEmail(deleteTemplate?.id);
      if (response?.deletedAt) {
        notification.open({
          message: t('TEMPLATE_DELETED_SUCCESSFULLY'),
          icon: <i className="fa fa-check" style={{ color: 'green' }} />,
        });
        setDeleteTemplate(null);
        await fetchDataTable(0, {}, true);
      }
    } finally {
      setDisableButton(false);
    }
  };

  const handleSendEmail = async (
    error,
    setLoading,
    emailsToSend = [],
    templateDefault,
    subjectDefault,
    customEmailId
  ) => {
    if (!templateDefault) {
      templateDefault = template?.template;
    }
    if (!subjectDefault) {
      subjectDefault = subjectEmail.subjectEmail;
    }
    const requestData = {
      emails: emailsToSend,
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectDefault,
        html: templateDefault,
      },
      customEmailId,
      language: key,
    };
    const response = await postSendCustomEmailByWorkspace(requestData);
    if (response === '') {
      notification.open({
        message: t('EMAIL_SENT'),
        icon: <i className="fa fa-check" style={{ color: 'green' }} />,
      });
    } else {
      notification.open({
        message: t('EMAIL_DID_NOT_SEND'),
        icon: <i className="fa fa-check" style={{ color: 'red' }} />,
      });
    }
  };

  const optionHeaders = [
    {
      columnOrder: 1,
      key: 'title',
      name: t('TITLE'),
      width: '250px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 1,
      key: 'subjectTranslated',
      name: t('SUBJECT'),
      width: '250px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 1,
      key: 'typeColumn',
      name: t('TYPE'),
      width: '250px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 1,
      key: 'sendTypeColumn',
      name: t('SEND_TYPE'),
      width: '250px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 10,
      key: 'actions',
      name: t('ACTIONS'),
      width: '150px',
      pinned: false,
      checked: true,
    },
  ];
  const idCustomTable = 'tableCustomMailPersonalization';
  const savedPersonalization = JSON.parse(localStorage.getItem(idCustomTable));

  const [headers, setHeaders] = useState(
    savedPersonalization?.length
      ? optionHeaders?.map((item, index) => ({
          ...item,
          checked: savedPersonalization[index]?.checked,
          pinned: savedPersonalization[index]?.pinned,
          columnOrder: savedPersonalization[index]?.columnOrder,
        }))
      : optionHeaders
  );
  return (
    <>
      {(editMailVisible || sendMail) && (
        <EditCustomEmailTemplate
          handleSendRequest={handleSendEmail}
          goBack={handleGoBack}
          viewInfo={false}
          emails={[user.email]}
          customTemplate={editMailVisible}
          newCustomEmail={newCustomEmail}
        />
      )}
      {restartTemplate && (
        <ModalCustom
          onCancel={() => setRestartTemplate(null)}
          onCancelText={t('CANCEL_OPERATION_LABEL')}
          onOk={disableButton ? () => {} : onClickRestartTemplate}
          classModal="allow-certificate-download-modal-width"
          subtitleClassName="px-0 px-lg-0"
          onOkText={t('RESTART_LABEL')}
          setShow={() => setRestartTemplate(null)}
          show={restartTemplate}
          title={t('RESTART_CUSTOM_EMAIL')}
          subtitle={t('RESTART_CUSTOM_EMAIL_DESCRIPTION')}
        />
      )}
      {deleteTemplate && (
        <ModalCustom
          onCancel={() => setDeleteTemplate(null)}
          onCancelText={t('CANCEL_OPERATION_LABEL')}
          onOk={disableButton ? () => {} : onClickDeleteTemplate}
          classModal="allow-certificate-download-modal-width"
          subtitleClassName="px-0 px-lg-0"
          onOkText={t('DELETE_LABEL')}
          setShow={() => setDeleteTemplate(null)}
          show={deleteTemplate}
          title={t('DELETE_CUSTOM_EMAIL')}
          subtitle={t('DELETE_CUSTOM_EMAIL_DESCRIPTION')}
          okBtnClassname="btn-danger bg-danger border-danger"
        />
      )}
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>

      <div className={`w-100 mx-auto h-100 ${editMailVisible || sendMail ? 'd-none' : ''}`}>
        <BackgroundTop
          title={t(`CUSTOM_EMAILS`)}
          routes={breadcrumbAlkymetricsDashboardCandidates}
          className="position-relative"
          titleClassname="text-xl ml-5"
          key={t(`CUSTOM_EMAILS`)}
        />
        <div className="mw-100">
          <div className="position-absolute" />
          <div>
            <div className="px-2">
              <div className="position-relative d-flex flex-column">
                <HeaderCustomEmails
                  handleSubmit={fetchDataTable}
                  headers={headers}
                  setHeaders={setHeaders}
                  idCustomTable={idCustomTable}
                  onClickNewCustomEmail={onClickNewCustomEmail}
                />
                <TableCustomEmails
                  fetchDataTable={fetchDataTable}
                  onClickSendMail={(data) => setEditMailVisible(data)}
                  headers={headers}
                  setHeaders={setHeaders}
                  defaultTemplate={defaultTemplate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomEmails;
