import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';

import EnvelopeIcon from '../../assets/dashboard/EnvelopeIcon';
import { setCustomEmails } from '../../redux/actions/customEmailsActions';
import TableFull from '../TableFull/TableFull';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';

export const TableCustomEmails = ({
  fetchDataTable,
  onClickSendMail,
  setHeaders,
  headers,
  defaultTemplate,
}) => {
  const dispatch = useDispatch();
  const customEmails = useSelector((state) => state.customEmails.customEmailsPaginated) || {};
  const quantity = useMemo(
    () => customEmails?.list?.filter((item) => item?.checked)?.length,
    [customEmails?.list]
  );
  const [stopLoad, setStopLoad] = useState(false);

  const hasFilter = useMemo(() => {
    const { workspaceIds, ...rest } = customEmails?.filter || {};
    return Object.keys(rest).length > 0;
  }, [customEmails?.filter]);

  const onCheckRow = (elements) => {
    dispatch(setCustomEmails({ ...customEmails, list: elements }));
  };

  const init = async () => {
    await fetchDataTable(0, {}, true);
  };

  useEffect(() => {
    init();
  }, []);

  const loadMoreData = async () => {
    if (stopLoad || customEmails?.isLoading || customEmails?.reload) return;
    await fetchDataTable(customEmails?.pagination?.offset + 1, customEmails?.filter);
  };

  const handleSort = async (headerKey, order) => {
    let sortName = headerKey === 'candidate' ? 'firstName' : headerKey;
    if (headerKey === 'contacted') {
      sortName = 'comunications.sendDate';
    }
    await fetchDataTable(0, { ...customEmails?.filter, orderBy: order, sort: sortName });
  };

  const onClickHeader = async (header) => {
    setStopLoad(true);
    await handleSort(header?.key, header?.order);
    setHeaders(headers?.map((item) => (item?.key === header?.key ? header : item)));
    setStopLoad(false);
  };

  const cleanFilters = async () => {
    setStopLoad(true);
    await fetchDataTable(0, {});
    setStopLoad(false);
  };
  return (
    <>
      <div className="container-search-dashboard container-info new-search-container bg-white rounded px-4 pb-4 pt-0 mx-auto mb-5 w-full pt-4">
        {!customEmails?.reload ? (
          customEmails?.list?.length ? (
            <>
              <TableFull
                elements={customEmails?.list}
                headers={headers}
                onCheckAllRow={onCheckRow}
                onClickHeader={onClickHeader}
                checkbox={false}
              />
              <Spinner show={customEmails?.isLoading} />
            </>
          ) : (
            <EmptyComponent
              className="mt-3 py-4"
              title={t('EMAILS_EMPTY_ROW')}
              subtitle={t('EMAILS_EMPTY_DESCRIPTIONS')}
            />
          )
        ) : (
          <Spinner show={customEmails?.reload} />
        )}
        <ScrollToTop />
        <InfiniteScroll
          onScrollEnd={() => {
            if (!stopLoad) {
              loadMoreData();
            }
          }}
          isLoading={customEmails?.isLoading}
        />
      </div>
    </>
  );
};
