import { useState } from 'react';
import { t } from '../i18n/i18n';
import { TestGroupMetricsRol } from '../constants/enums';
import { isNullOrUndefined } from '../utils/typesUtils';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';

const useTestGroupMetrics = ({ testGroup }) => {
  const { companyName, companyId } = getLocalStorageWorkspace();
  const [companyMetricSelected, setCompanyMetricSelected] = useState(0);
  const [roleMetricSelected, setRoleMetricSelected] = useState(
    TestGroupMetricsRol.GENERAL_USER_ROLES
  );

  const companyMetricsOptions = [
    {
      id: 0,
      displayName: t(TestGroupMetricsRol.GENERAL_USER_ROLES),
      visible: !isNullOrUndefined(
        testGroup?.metrics?.dataGrouped?.[0]?.roleMetrics[roleMetricSelected]
      ),
    },
    {
      id: companyId,
      displayName: companyName,
      visible: !isNullOrUndefined(
        testGroup?.metrics?.dataGrouped?.[companyId]?.roleMetrics[roleMetricSelected]
      ),
    },
  ];

  const roleMetricsOptions = Object.keys(
    testGroup?.metrics?.dataGrouped?.[companyMetricSelected]?.roleMetrics ?? []
  )?.map((r) => ({
    id: r,
    displayName: t(r),
    visible: !isNullOrUndefined(
      testGroup?.metrics?.dataGrouped?.[companyMetricSelected]?.roleMetrics[r]
    ),
  }));

  const cleanData = () => {
    setCompanyMetricSelected(0);
    setRoleMetricSelected(TestGroupMetricsRol.GENERAL_USER_ROLES);
  };

  const setMetricForProfile = (profile) => {
    const profileRole = profile?.jobRole;
    if (testGroup?.metrics?.dataGrouped?.length > 0) {
      if (profileRole && profileRole?.role) {
        const companyToUse = testGroup?.metrics?.dataGrouped[companyId] ? companyId : 0;
        setCompanyMetricSelected(companyToUse);
        const metric = testGroup?.metrics?.dataGrouped[companyToUse];
        const roleMetric = metric?.roleMetrics[profileRole.role];
        if (roleMetric) setRoleMetricSelected(profileRole.role);
      }
    }
  };

  const selectedCompanyData = testGroup?.metrics?.dataGrouped?.find(
    (item) => item.companyId === companyMetricSelected
  );
  const metricDataSelected = selectedCompanyData?.roleMetrics[roleMetricSelected];

  return {
    companyId,
    companyName,
    companyMetricSelected,
    companyMetricsOptions,
    roleMetricSelected,
    roleMetricsOptions,
    selectedCompanyData,
    metricDataSelected,
    cleanData,
    setCompanyMetricSelected,
    setRoleMetricSelected,
    setMetricForProfile,
  };
};

export default useTestGroupMetrics;
