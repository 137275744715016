import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
import Star from '../../assets/tests/Star';
import Clock from '../../assets/tests/Clock';
import Checkbox from '../Checkbox/Checkbox';
import { t } from '../../i18n/i18n';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import {
  addTestGroupFavorite,
  getTestGroup,
  removeTestGroupFavorite,
} from '../../API/services/screeningService';
import { setSubmissionSelected, setTestGroupsData } from '../../redux/actions/screeningActions';
import './TestItem.scss';
import { questionsAmount } from '../../utils/tests';
import DisabledFeatureMinTier from '../DisabledFeatureMinTier/DisabledFeatureMinTier';
import { TestGroupTypes } from '../../constants/enums';
import { determineDisabledMinimumTier } from '../../utils/determineDisabled';
import useTranslations from '../../hooks/useTranslations';
import OtherCheck from '../../assets/tests/OtheCheck';
import Clip from '../../assets/tests/Clip';
import Candidates from '../../assets/tests/Candidates';
import ScoreChart from '../../assets/tests/ScoreChart';
import { getGroupSliderTags } from '../../utils/scoreTags';
import stars from '../../utils/scoreTagStars';
import QualitativeIcon from '../../assets/tests/QualitativeIcon';
import { formatNumberOfCandidates } from '../../utils/numbersUtils';
import { doesNotHaveNewTag, isANewTestgroup } from '../../utils/testgroups';
import { generateTGDemoUrl } from '../../utils/validations/formatUrl';
import ScoreDistributionChart from '../SearchChart/AreaChart/ScoreDistributionChart';

const TestItem = ({
  test,
  setShow,
  isTestsPage,
  isStar,
  isFavorite,
  testArray,
  isCheck = true,
  isSelectTests = false,
  handleTests,
  newScreening,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { key } = useTranslations();
  const dispatch = useDispatch();
  const getTestGroupId = async (testGroupId, detailFlag) => {
    await getTestGroup(testGroupId, true).then(async (res) => {
      dispatch(
        setSubmissionSelected({ testGroup: res, submissions: res?.tests?.map((r) => r.test) })
      );
      if (detailFlag) {
        setShow(true);
      } else {
        const url = await generateTGDemoUrl(res, user?.id);
        window.open(url.toString(), '_blank');
      }
    });
  };

  const [scoreTagsFormatted, setScoreTagsFormatted] = useState([]);
  const defaultScoreTagsValues = ['<31%', '>31%', '>46%', '>64%', '>90%'];

  useEffect(() => {
    if (!test?.scoreTags) return;
    const scoreTags = getGroupSliderTags(test?.scoreTags?.tags);
    setScoreTagsFormatted(scoreTags);
  }, [test.scoreTags]);

  const [isFav, setIsFav] = useState(isFavorite);
  const addFavorite = (testGroupId) => {
    addTestGroupFavorite({ testGroupId, userId: user.id }).then(() => {
      setIsFav(true);
      dispatch(
        setTestGroupsData({
          pagination: testArray?.pagination,
          collection: testArray?.collection?.map((test) => {
            if (test.id === testGroupId) {
              return { ...test, favorite: true };
            }
            return test;
          }),
        })
      );
    });
  };

  const removeFavorite = (testGroupId) => {
    removeTestGroupFavorite(testGroupId).then(() => {
      setIsFav(false);
    });
  };

  const normalizedDescription = (description = '', maxValue = 185) => {
    if (description?.length <= maxValue) {
      return description;
    }
    return `${description?.substring(0, maxValue)}...`;
  };

  const [checked, setChecked] = useState(false);
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (newScreening.testGroups.find((item) => item.id === test.id)?.isSelected) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [newScreening, test]);

  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => setFlipped(true), 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setFlipped(false);
  };
  return (
    <DisabledFeatureMinTier
      className="position-relative disabled-feature-container"
      iconClassName="disabled-feature-icon"
      isDisabledFeature={() => determineDisabledMinimumTier(test?.minimumTier)}
      tooltipLabel={t('AVAILABLE_IN_HIGHER_PLANS_CONTACT_SALES_TEAM')}
    >
      <article
        className={`test-item-container ${isCheck && checked && 'selected'}`}
        onMouseLeave={handleMouseLeave}
      >
        <div className="testgroup-clip">
          <Clip checked={isCheck && checked} />
        </div>
        <div className="d-flex justify-content-between align-content-center py-3">
          <button
            type="button"
            className="bg-transparent border-0"
            onClick={() => (!isFav ? addFavorite(test?.id) : removeFavorite(test?.id))}
            data-testid={`test-item-${!isFav ? 'add' : 'remove'}-favorite-btn`}
          >
            <div>
              <Star selected={isStar && isFav} />
            </div>
          </button>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">{test?.name}</Tooltip>}
          >
            <p data-testid="test-item-name-span align-self-center" className="m-0 font-weight-700">
              {`${test.name.slice(0, 30)} ${test.name.length > 30 ? '...' : ''}`}
            </p>
          </OverlayTrigger>
          <div>
            {isCheck && (
              <Checkbox
                checked={checked}
                onClick={() => handleTests(test, isSelectTests)}
                className={`test-checkbox align-self-start mt-1 ${
                  isTestsPage ? 'd-none' : 'd-flex align-items-center justify-content-between'
                } ${isStar ? 'ml-2' : ''}`}
              />
            )}
          </div>
        </div>
        <div className="test-labels-container d-flex justify-content-around">
          {doesNotHaveNewTag(test?.tags) && isANewTestgroup(test?.createdAt) && (
            <div key={`${test?.id}-new-tag-`}>
              <OtherCheck />
              <span className="test-label-item pl-2">{t('TEST_NEW_TAG')}</span>
            </div>
          )}
          {test?.tags?.some((tag) => tag.type === 'MARKETING')
            ? test.tags.map((tag, index) => {
                if (tag.type === 'MARKETING') {
                  return (
                    <div key={tag?.id}>
                      <OtherCheck />
                      <span className="test-label-item pl-2">{tag.displayName}</span>
                    </div>
                  );
                }
              })
            : t('MODAL_SUBMISSION_DEFAULT_MARKETING_TAGS')
                ?.split(',')
                ?.map((tag, index) => (
                  <div key={`${test?.id}-default-tag-${index}`}>
                    <OtherCheck />
                    <span className="test-label-item pl-2">{tag}</span>
                  </div>
                ))}
        </div>
        <div className={`card ${flipped && 'flipped'}`} onClick={() => setFlipped(!flipped)}>
          <div className="card-front" onMouseEnter={handleMouseEnter}>
            <div className="objetivos d-flex flex-column">
              <h3>{t('TESTGROUP_OBJECTIVES_OF_THE_EVALUATION')}</h3>
              <div className="d-flex flex-wrap test-objectives-content">
                {test.objetives?.[key]?.length
                  ? test.objetives?.[key].slice(0, 6).map((item, index) => (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{item}</Tooltip>}
                      >
                        <p key={`${test?.id}-obj-${index}`} className="objective-pill texts">
                          {normalizedDescription(item, 22)}
                        </p>
                      </OverlayTrigger>
                    ))
                  : t('MODAL_SUBMISSION_DEFAULT_OBJECTIVES')
                      ?.split(',')
                      ?.map((item, index) => (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">{item}</Tooltip>}
                        >
                          <p key={`${test?.id}-obj-${index}`} className="objective-pill texts">
                            {normalizedDescription(item, 22)}
                          </p>
                        </OverlayTrigger>
                      ))}
              </div>
            </div>
            <div className="dotted-border" />
            <div className="roles d-flex flex-column">
              <h3>{t('TESTGROUP_TARGET_ROLES')}</h3>
              <div className="test-roles-content">
                {test.roles?.length
                  ? test.roles?.slice(0, 6).map((role, index) => (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{role?.displayName}</Tooltip>}
                      >
                        <p key={`${test?.id}-role-${index}`} className="role-pill texts">
                          {normalizedDescription(role?.displayName, 22)}
                        </p>
                      </OverlayTrigger>
                    ))
                  : t('MODAL_SUBMISSION_DEFAULT_TARGET_ROLES')
                      ?.split(',')
                      ?.map((item, index) => (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">{item}</Tooltip>}
                        >
                          <p key={`${test?.id}-role-${index}`} className="role-pill texts">
                            {normalizedDescription(item, 22)}
                          </p>
                        </OverlayTrigger>
                      ))}
              </div>
            </div>
            <div className="dotted-border" />
          </div>
          <div className="card-back">
            <div className="statistics-container">
              <h3>{t('TESTGROUP_STATISTICS_TITLE')}</h3>
              <div className="statistics-content">
                <div className="bold-titles">
                  <Candidates />
                  <p>
                    {test?.metrics?.candidatesCount
                      ? formatNumberOfCandidates(test.metrics.candidatesCount)
                      : '+100'}
                  </p>
                  <p>{`${t('TESTGROUP_CANDIDATES')}`}</p>
                </div>
                {test.type === TestGroupTypes.QUALITATIVE ? (
                  <div className="d-flex align-items-center justify-content-center flex-column gap">
                    <QualitativeIcon width="2.6rem" />
                    <span>{t('CREATE_SCREENING_QUALITATIVE_LABEL')}</span>
                  </div>
                ) : (
                  <>
                    {test?.metrics?.scores?.length > 1 &&
                    test?.metrics?.average &&
                    test?.metrics?.dataGrouped?.length > 0 ? (
                      <div className="align-content-top align-content-center w-40">
                        <ScoreDistributionChart
                          mean={test?.metrics?.average}
                          scores={test?.metrics?.scores}
                          width={200}
                          height={120}
                          padding={8}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="bold-titles">
                          <ScoreChart />
                          <span>
                            {`${
                              test?.metrics?.average
                                ? Number(test?.metrics?.average.toFixed(0)) || 50
                                : 50
                            }% ${t('TESTGROUP_AVERAGE_SCORE')}`}
                          </span>
                        </div>
                        <div className="stars-averages">
                          {test?.scoreTags?.tags ? (
                            <div className="mt-2">
                              {scoreTagsFormatted?.ranges?.map((range, index) => {
                                const [min, max] = range;
                                return (
                                  <div className="" key={`${test?.id}-scoreTag-${index}`}>
                                    {stars([index >= 1, index >= 2, index >= 3], '12')}
                                    <span className="average">
                                      {index == 0 ? `< ${max}%` : `> ${min}%`}
                                    </span>
                                    <span className="text">
                                      {scoreTagsFormatted?.descriptions[index]}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              {t('TESTGROUP_DEFAULT_SCORETAGS')
                                ?.split(',')
                                ?.map((label, index) => (
                                  <div className="" key={`${test?.id}-scoreTag-${index}`}>
                                    {stars([index >= 1, index >= 2, index >= 3], '12', index >= 4)}
                                    <span className="average">{defaultScoreTagsValues[index]}</span>
                                    <span className="text">{normalizedDescription(label, 16)}</span>
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="dotted-border" />

            <div className="endorsement-container gap">
              <div className="created-by">
                <h3>{t('TESTGROUP_CREATED_BY')}</h3>
                <div className="item-content">
                  <img
                    src={test?.endorsement?.imgUrl}
                    alt="author-avatar"
                    className="img-endorsement"
                  />
                  <div className="item-texts">
                    <h3 className="font-weight-600 truncate">{test?.endorsement?.name}</h3>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">{test?.endorsement?.summary?.[key]}</Tooltip>
                      }
                    >
                      <p className="font-weight-400">
                        {normalizedDescription(test?.endorsement?.summary?.[key], 100)}
                      </p>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              <div className="used-by">
                <h3>{t('TESTGROUP_USED_BY')}</h3>
                <div className="item-content">
                  {test?.companyUsage
                    ?.slice(0, 2)
                    .filter((company) => company.companyId !== user?.companyId)
                    ?.map((company, index) => (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            <>
                              <p>{company?.name}</p>
                              <p>{company?.summary?.[key]}</p>
                            </>
                          </Tooltip>
                        }
                      >
                        <div
                          className="image-wrapper"
                          style={{ backgroundImage: `url(${company?.imgUrl})` }}
                        >
                          <img src={company?.imgUrl} alt="company-logo" />
                        </div>
                      </OverlayTrigger>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testgroup-footer">
          <div className="footer-item">
            <OtherCheck />
            <span className="texts">
              {questionsAmount(test)}
              {` ${t('TEST_ITEM_QUESTION')}${questionsAmount(test) > 1 ? 's' : ''}`}
            </span>
          </div>
          <div className="footer-item">
            <Clock />
            <span className="texts">{`${formatMinutesToHours(test.duration)}`}</span>
          </div>
          <div className="footer-item">
            {test.testFormats?.map((format, i) => (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{format?.name}</Tooltip>}
              >
                <img
                  src={format?.iconUrl}
                  alt="Format test logo"
                  className="text-blue-principal"
                  style={{ width: '1rem', height: '1rem' }}
                />
              </OverlayTrigger>
            ))}
          </div>
        </div>
        <button
          className="details-button"
          onClick={() => getTestGroupId(test?.id, true)}
          data-testid="test-item-see-more-tg-btn"
        >
          {t('TESTGROUP_SEE_DETAILS')}
        </button>
        <button className="see-experience-button" onClick={() => getTestGroupId(test?.id, false)}>
          <FaPlay />
          <span className="text-sm">{t('MODAL_SUBMISSION_SEE_DEMO_TESTGROUP')}</span>
        </button>
      </article>
    </DisabledFeatureMinTier>
  );
};

export default TestItem;

// displayName ??
