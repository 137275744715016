import React from 'react';

const DeleteForever = ({ fill, height, width }) => (
  <svg
    width={width ?? '24'}
    height={height ?? '24'}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8125 16C2.29688 16 1.85562 15.8261 1.48875 15.4782C1.12187 15.1304 0.938125 14.7117 0.9375 14.2222V2.66667H0V0.888889H4.6875V0H10.3125V0.888889H15V2.66667H14.0625V14.2222C14.0625 14.7111 13.8791 15.1298 13.5122 15.4782C13.1453 15.8267 12.7037 16.0006 12.1875 16H2.8125ZM4.6875 12.4444H6.5625V4.44444H4.6875V12.4444ZM8.4375 12.4444H10.3125V4.44444H8.4375V12.4444Z"
      fill={fill ?? '#4987be'}
    />
  </svg>
);

export default DeleteForever;
