const Default = () => (
  <svg
    width="177"
    height="177"
    viewBox="0 0 177 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="home-plan-image"
  >
    <circle cx="88.5" cy="88.5" r="88.5" fill="white" />
    <circle cx="88.6438" cy="88.6438" r="72.1438" stroke="#41479B" />
    <path
      d="M42.1676 122.104L74.0084 54.123H83.8127L88.4887 64.1038L93.2487 74.2664L115.654 122.104H106.128L88.4887 84.4355L83.7287 74.2728L78.9106 63.9869L51.6933 122.104H42.1676Z"
      fill="#58C1F5"
    />
    <path
      d="M61.2778 122.104L93.1181 54.123H102.922L107.599 64.1038L112.359 74.2664L134.758 122.091L125.247 122.123L107.599 84.4355L102.838 74.2728L98.0203 63.9869L70.803 122.104H61.2778Z"
      fill="#58C1F5"
    />
  </svg>
);

export default Default;
