import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';

import EnvelopeIcon from '../../assets/dashboard/EnvelopeIcon';
import { setUserSummary } from '../../redux/actions/userSummaryActions';
import TableFull from '../TableFull/TableFull';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';

export const TableUsersSummary = ({ fetchDataTable, onClickSendMail, setHeaders, headers }) => {
  const dispatch = useDispatch();
  const userSummary = useSelector((state) => state.userSummary.userSummaryPaginated) || {};
  const quantity = useMemo(
    () => userSummary?.list?.filter((item) => item?.checked)?.length,
    [userSummary?.list]
  );
  const [stopLoad, setStopLoad] = useState(false);

  const hasFilter = useMemo(() => {
    const { workspaceIds, ...rest } = userSummary?.filter || {};
    return Object.keys(rest).length > 0;
  }, [userSummary?.filter]);

  const onCheckRow = (elements) => {
    dispatch(setUserSummary({ ...userSummary, list: elements }));
  };

  const init = async () => {
    await fetchDataTable(0, {}, true);
  };

  useEffect(() => {
    init();
  }, []);

  const loadMoreData = async () => {
    if (stopLoad || userSummary?.isLoading || userSummary?.reload) return;
    await fetchDataTable(userSummary?.pagination?.offset + 1, userSummary?.filter);
  };

  const handleSort = async (headerKey, order) => {
    let sortName = headerKey === 'candidate' ? 'firstName' : headerKey;
    if (headerKey === 'contacted') {
      sortName = 'comunications.sendDate';
    }
    await fetchDataTable(0, { ...userSummary?.filter, orderBy: order, sort: sortName });
  };

  const onClickHeader = async (header) => {
    setStopLoad(true);
    await handleSort(header?.key, header?.order);
    setHeaders(headers?.map((item) => (item?.key === header?.key ? header : item)));
    setStopLoad(false);
  };

  const cleanFilters = async () => {
    setStopLoad(true);
    await fetchDataTable(0, {});
    setStopLoad(false);
  };

  return (
    <>
      <div className="container-search-dashboard container-info new-search-container bg-white rounded px-4 pb-4 pt-0 mx-auto mb-5 w-full pt-4">
        {(Boolean(hasFilter) || Boolean(quantity)) && (
          <div className="d-flex justify-content-between mb-0 ml-1 align-items-center mt-1 min-height w-100">
            <div className="d-flex flex-row align-items-center">
              {Boolean(quantity) && (
                <div>
                  <span className="mr-1  text-xs total-number-text font-weight-bold">
                    {quantity}
                  </span>
                  <span className="mr-2  text-xs gray-subtitle font-weight-600">
                    {t('CANDIDATES_SELECTED')}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              {Boolean(quantity) && (
                <button
                  type="button"
                  className="border-none rounded-0 p-2 bg-maya-blue text-xs text-white font-weight-700 mr-2"
                  onClick={onClickSendMail}
                >
                  <span className="mr-2 text-nowrap">{t('DASHBOARD_SEND_MAIL')}</span>
                  <EnvelopeIcon fill="#FFF" />
                </button>
              )}
              {Boolean(hasFilter) && (
                <button
                  className="border-none ml-2 font-weight-700  text-xs rounded-0 p-2 text-blue-principal border-maya-blue bg-white"
                  type="button"
                  onClick={cleanFilters}
                >
                  {t('PROFILES_CLEAR_FILTERS')}
                </button>
              )}
            </div>
          </div>
        )}
        {!userSummary?.reload ? (
          userSummary?.list?.length ? (
            <>
              <TableFull
                elements={userSummary?.list}
                headers={headers}
                onCheckRow={onCheckRow}
                onCheckAllRow={onCheckRow}
                onClickHeader={onClickHeader}
              />
              <Spinner show={userSummary?.isLoading} />
            </>
          ) : (
            <EmptyComponent
              className="mt-3 py-4"
              title={t('SEARCH_STAGE_EMPTY_FILTERS_TITLE')}
              subtitle={t('SEARCH_STAGE_PENDING_CREDITS_SUBTITLE')}
            />
          )
        ) : (
          <Spinner show={userSummary?.reload} />
        )}
        <ScrollToTop />
        <InfiniteScroll
          onScrollEnd={() => {
            if (!stopLoad) {
              loadMoreData();
            }
          }}
          isLoading={userSummary?.isLoading}
        />
      </div>
    </>
  );
};
