import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Radio } from 'antd';
import { FaPlay, FaVideoSlash } from 'react-icons/fa';
import moment from 'moment';
import MathJax from 'react-mathjax';
import DOMPurify from 'dompurify';
import { t } from '../../i18n/i18n';
import Ticket from '../Ticket/Ticket';
import FailedIcon from '../../assets/modal/Failed';
import Arrow from '../../assets/modal/Arrow';
import CheckIcon from '../../assets/modal/Check';
import { formatMinutesSecondsToHours } from '../../utils/formatMinutesSecondToHours';
import timerIcon from '../../assets/profile/timer.svg';
import CloseIcon from '../../assets/modal/Close';
import CloudIcon from '../../assets/modal/Cloud';
import QuestionIcon from '../../assets/modal/QuestionIcon';
import MinusWarning from '../../assets/modal/MinusWarning';
import Accordion from '../Accordion/Accordion';
import OptionMultipleComponent from './OptionMultipleComponent/OptionMultipleComponent';
import { optionsMatch } from '../../utils/modal/optionsToMatch';
import OptionText from './OptionTextComponent/OptionText';
import TrainingSkills from '../TrainingSkills/TrainingSkills';
import { setSubmissionSelected } from '../../redux/actions/screeningActions';
import {
  AnswerTypeEnum,
  PrefilterNumberConditions,
  TestGroupMetricsRol,
  TestGroupTypes,
} from '../../constants/enums';
import ModalTestFile from '../ModalTestFile/ModalTestFile';
import './modalSubmission.scss';
import OptionCode from './OptionCodeComponent/OptionCodeComponent';
import { convertSecondsToTimeFormat } from '../../utils/time';
import useTranslations from '../../hooks/useTranslations';
import { isNullOrUndefined } from '../../utils/typesUtils';
import CountryFlag from '../CountryFlag/CountryFlag';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { getGroupSliderTags } from '../../utils/scoreTags';
import { parseLineHTML } from '../../utils/parseLineHTML';
import Chart from '../../assets/modal/Chart';
import Eye from '../../assets/modal/Eye';
import OtherCheck from '../../assets/tests/OtheCheck';
import { questionsAmount } from '../../utils/tests';
import CheckWithCircle from '../../assets/tests/CheckWithCircle';
import Candidates from '../../assets/tests/Candidates';
import ScoreChart from '../../assets/tests/ScoreChart';
import ClockIcon from '../../assets/tests/ClockIcon';
import stars from '../../utils/scoreTagStars';
import useClickOutside from '../../hooks/useClickOutside';
import QualitativeIcon from '../../assets/tests/QualitativeIcon';
import { formatNumberOfCandidates, oneDecimal } from '../../utils/numbersUtils';
import { doesNotHaveNewTag, isANewTestgroup } from '../../utils/testgroups';
import { generateTGDemoUrl } from '../../utils/validations/formatUrl';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';
import ChartLayout from '../ChartLayout/ChartLayout';
import ScoreDistributionChart from '../SearchChart/AreaChart/ScoreDistributionChart';
import { getPercentile } from '../../utils/stats/statistics';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useTestGroupMetrics from '../../hooks/useTestGroupMetrics';
import useCreateTestGroup from '../../hooks/useCreateTestGroup';
import { capitalizeWord } from '../../utils/stringUtils';

const ModalSubmission = ({
  show,
  setShow,
  showResult = false,
  isInvitedProfile,
  groupAcceleration,
  isTestgroupListDetail,
}) => {
  const [itemsToBeOpened, setItemsToBeOpened] = useState([]);
  const [submissionsPrint, setSubmissionsPrint] = useState([]);
  const [scoreTagsFormatted, setScoreTagsFormatted] = useState(null);
  const videoRefs = useRef([]);
  const { key } = useTranslations();
  const { profile, alkymerAdvance } = useSelector((state) => state.profiles);
  const { currentWorkspace } = useSelector((state) => state.auth);
  const submission = useSelector((state) => state.screenings.submissionSelected);
  const { testGroup, submissions } = submission;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const modalRef = useRef();
  useClickOutside(modalRef, () => {
    setShow(false);
    setExampleQuestions(false);
  });
  const [exampleQuestions, setExampleQuestions] = useState(false);
  const { companyName } = getLocalStorageWorkspace();
  const {
    companyMetricSelected,
    companyMetricsOptions,
    roleMetricsOptions,
    roleMetricSelected,
    selectedCompanyData,
    metricDataSelected,
    setCompanyMetricSelected,
    setRoleMetricSelected,
    setMetricForProfile,
    cleanData,
  } = useTestGroupMetrics({ testGroup });

  const { testDifficulty } = useCreateTestGroup();
  const possibleResults = [
    {
      text: t('SUBMISSION_MODAL_CORRECT_ANSWER'),
      icon: <CheckIcon />,
    },
    {
      text: t('SUBMISSION_MODAL_PARTIAL_CORRECT_ANSWER'),
      icon: <MinusWarning />,
    },
    {
      text: t('SUBMISSION_MODAL_INCORRECT_ANSWER'),
      icon: <FailedIcon />,
    },
    {
      text: t('SUBMISSION_MODAL_WITHOUT_ANSWER'),
      icon: <QuestionIcon />,
    },
  ];

  useEffect(() => {
    if (show) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
    if (!show) {
      videoRefs?.current?.forEach((video) => {
        if (video) {
          video?.pause();
        }
      });
    }
  }, [show]);
  useEffect(() => {}, [show]);
  useEffect(() => {
    if (testGroup?.scoreTags) {
      const scoreTags = getGroupSliderTags(testGroup?.scoreTags?.tags);
      setScoreTagsFormatted(scoreTags);
    }
  }, [testGroup]);

  const submittedZeroPoints = (acceptedOptions, submittedOptions) => {
    const weightZero = acceptedOptions
      ?.filter((acceptedOption) => acceptedOption.weight === 0)
      ?.map((acceptedOption) => acceptedOption.options)
      ?.flat();

    return weightZero.includes(submittedOptions[0]);
  };

  const generateMetricPercentilChartDisclaimer = ({
    company,
    role,
    name,
    lastName,
    percentil,
    testGroupName,
    score,
  }) => {
    const hasCompany = company !== TestGroupMetricsRol.GENERAL_USER_ROLES;

    const companyText = hasCompany
      ? ` ${t('METRICS_COMPANY_ARTICLE')} <strong>${company}</strong>`
      : '';
    const roleText = ![
      TestGroupMetricsRol.GENERAL_USER_ROLES,
      TestGroupMetricsRol.USER_WHITOUT_FORMATION_ROLE,
    ].includes(role)
      ? ` ${t('METRICS_ROLE_ARTICLE')} <strong>${t(role)?.toLowerCase()}</strong>`
      : role !== TestGroupMetricsRol.USER_WHITOUT_FORMATION_ROLE
      ? ` ${t('METRICS_COMPANY_ARTICLE')} ${t('ALL_ROLES_METRIC')?.toLowerCase()}`
      : ` ${t('METRICS_COMPANY_ARTICLE')} <strong>${t(role)?.toLowerCase()}</strong>`;

    return t('PROFILE_DISCLAIMER_PERCENTIL_METRICS')
      .replace('{{company}}', `<strong>${companyText}</strong>`)
      .replace('{{role}}', `<strong>${roleText.trim()}</strong>`)
      .replace('{{name}}', `<strong>${capitalizeWord(name)}</strong>`)
      .replace('{{lastName}}', `<strong>${capitalizeWord(lastName)}</strong>`)
      .replace('{{percentil}}', `<strong>${percentil}</strong>`)
      .replace('{{percentilP}}', `<strong>${percentil}%</strong>`)
      .replace('{{testGroupName}}', `<strong>${testGroupName}</strong>`)
      .replace('{{score}}', `<strong>${oneDecimal(score)}%</strong>`);
  };

  const getDifficultyTag = (testId) => {
    let difficultyTag = '';
    const testDifficultyId = testGroup?.tests?.find((test) => test.testId === testId)?.difficultyId;
    if (testDifficultyId) {
      difficultyTag = testDifficulty?.find(
        (difficulty) => difficulty.id === testDifficultyId
      )?.name;
    }
    return difficultyTag;
  };

  useEffect(() => {
    if (show && submissions?.length) {
      const submissionsModify = [];

      submissions?.sort((a, b) => {
        const dateA = new Date(a?.submittedAt);
        const dateB = new Date(b?.submittedAt);

        const isSubmissionA = a.isSubmission !== undefined ? a.isSubmission : false;
        const isSubmissionB = b.isSubmission !== undefined ? b.isSubmission : false;

        if (!isSubmissionA && isSubmissionB) {
          return 1;
        }
        if (isSubmissionA && !isSubmissionB) {
          return -1;
        }

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });

      submissions?.forEach((submission, index) => {
        let difficultyTag = '';
        if (!testGroup?.isComplementary) {
          difficultyTag = getDifficultyTag(submission.testId);
        }
        switch (submission?.answerType) {
          case AnswerTypeEnum.CHECK:
          case AnswerTypeEnum.RADIO:
            submissionsModify.push({
              ...submission,
              titleCollapsed:
                submission.type === 'TECHNICAL'
                  ? t('MODAL_QUESTION_OPTION_CODE')
                  : t('MODAL_QUESTION_OPTION_MULTIPLE'),
              componentResult: (
                <OptionMultipleComponent
                  submission={submission}
                  showResult={showResult}
                  isTestgroupListDetail={isTestgroupListDetail}
                />
              ),
              iconResult:
                !isTestgroupListDetail &&
                (!submission?.isSubmission ||
                !submission.submittedOptions.length ||
                (submission?.answerType === AnswerTypeEnum.RADIO &&
                  !submission?.hasGlobalScore &&
                  submittedZeroPoints(
                    submission?.acceptedOptions ?? [],
                    submission?.submittedOptions ?? []
                  )) ? (
                  <QuestionIcon />
                ) : (
                  submission?.acceptedOptions.length >= 1 &&
                  (optionsMatch(
                    submission?.acceptedOptions.filter((x) => x.weight > 0) ?? [],
                    submission?.submittedOptions
                  ) ? (
                    optionsMatch(
                      [submission?.acceptedOptions.sort((a, b) => b.weight - a.weight)[0]],
                      submission?.submittedOptions
                    ) ? (
                      <CheckIcon />
                    ) : (
                      <MinusWarning />
                    )
                  ) : (
                    <FailedIcon />
                  ))
                )),
              difficultTag: difficultyTag,
            });
            break;
          case AnswerTypeEnum.FILE:
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_QUESTION_FILE_ATTACH'),
              ...(showResult &&
                submission?.submittedFile && {
                  componentResult: (
                    <>
                      <span className=" font-weight-600 text-xs text-color-item-test-group mt-2">
                        {t('MODAL_ANSWER')}
                      </span>
                      <a
                        href={submission?.submittedFile}
                        target="_blank"
                        rel="noreferrer"
                        className=" text-xs download-cv-text mx-3 mt-3"
                      >
                        {t('MODAL_FILE_ATTACH')}
                      </a>
                    </>
                  ),
                }),
              iconResult: submission?.submittedFile ? (
                <a href={submission?.submittedFile} target="_blank" rel="noreferrer">
                  <CloudIcon />
                </a>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('MODAL_FILE_NOT_FOUND')}</Tooltip>}
                >
                  <Button variant="bg-transparent p-0 m-0">
                    <CloudIcon />
                  </Button>
                </OverlayTrigger>
              ),
            });
            break;
          case AnswerTypeEnum.VIDEO:
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO'),
              ...(showResult && {
                componentResult: (
                  <>
                    {submission?.submittedVideo ? (
                      <>
                        <div className="d-flex flex-column mt-2 ">
                          <div className="d-flex flex-row">
                            <span className=" text-xs font-weight-500 color-count-questions">
                              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_TOTAL_RECORD_TIME')}
                              :&nbsp;
                            </span>
                            <span className=" text-xs font-weight-600 text-blue-modal">
                              {convertSecondsToTimeFormat(submission?.totalTime)}
                            </span>
                          </div>
                          <div className="d-flex flex-row">
                            <span className=" text-xs font-weight-500 color-count-questions">
                              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_PREPARATION_TIME')}:&nbsp;
                            </span>
                            <span className=" text-xs font-weight-600 text-blue-modal">
                              {convertSecondsToTimeFormat(submission?.preparationTime)}
                            </span>
                          </div>
                          <div className="d-flex flex-row">
                            <span className=" text-xs font-weight-500 color-count-questions">
                              {t('MODAL_YOUR_QUESTIONS_VIDEO_MAX_ATTEMPTS')}:&nbsp;
                            </span>
                            <span className=" text-xs font-weight-600 text-blue-modal">
                              {submission?.maxAttempts}
                            </span>
                          </div>
                        </div>
                        <span className=" font-weight-600 text-xs text-color-item-test-group mt-2">
                          {t('MODAL_ANSWER')}
                        </span>
                        <video
                          ref={(ref) => (videoRefs.current[index] = ref)}
                          id="submittedVideo"
                          width="640"
                          height="360"
                          controls
                          src={submission?.submittedVideo}
                          preload="auto"
                        />
                      </>
                    ) : (
                      <>
                        <span className=" font-weight-600 text-xs text-color-item-test-group mt-2">
                          {t('EMPTY_CODE_RESPONSE')}
                        </span>
                      </>
                    )}
                  </>
                ),
              }),
              iconResult: submission?.submittedVideo ? (
                <a href={submission?.submittedVideo} target="_blank" rel="noreferrer">
                  <CloudIcon />
                </a>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('MODAL_FILE_NOT_FOUND')}</Tooltip>}
                >
                  <Button variant="bg-transparent p-0 m-0">
                    <FaVideoSlash color="rgb(220, 37, 28)" size="24" />
                  </Button>
                </OverlayTrigger>
              ),
            });
            break;
          case AnswerTypeEnum.TEXT_AI:
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_QUESTION_OPEN_IA'),
              componentResult: (
                <div className="d-flex flex-column mx-2">
                  <div className="d-flex flex-column my-3">
                    <span className="text-xs text-muted font-weight-bold  mb-1">
                      {t('MODAL_QUESTION_OPEN_IA_CONCEPTS_TITLE')}
                    </span>
                    <span
                      className="text-xs "
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(submission?.concepts)
                          ?.replace(/;/g, '<br>')
                          .replace(new RegExp('&lt;', 'g'), '<')
                          .replace(new RegExp('&gt;', 'g'), '>'),
                      }}
                    />
                  </div>
                  {!isTestgroupListDetail && (
                    <>
                      <OptionText submission={submission} showResult={showResult} />
                      <div className="d-flex mt-4">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-sm text-muted font-weight-bold  mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_SCORE')}
                          </span>
                          <span className="text-sm text-muted font-weight-600 ">
                            {submission?.ratio * 100}%
                          </span>
                        </div>
                        <div className="d-flex pl-3 ml-5 flex-column align-items-start">
                          <span className="text-sm text-muted font-weight-bold  mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_DETAILS_TITLE')}
                          </span>
                          <span className="text-xs ">{submission?.explanation}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ),
            });
            break;
          case AnswerTypeEnum.CODE_AI:
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_QUESTION_CODE_IA'),
              componentResult: (
                <div className="d-flex flex-column mx-2">
                  <div className="d-flex flex-column my-3">
                    <span className="text-xs text-muted font-weight-bold  mb-1">
                      {t('MODAL_QUESTION_CODE_IA_LANGUAGE_TITLE')}
                    </span>
                    <div className="border-language-selected text-center">
                      <span
                        className="text-xs font-montserrat font-weight-600"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            submission?.submittedLangCode?.trim()
                              ? submission.submittedLangCode
                              : t('WITHOUT_SELECTED_CODE')
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column my-3">
                    <span className="text-xs text-muted font-weight-bold  mb-1">
                      {t('MODAL_QUESTION_CODE_IA_CONCEPTS_TITLE')}
                    </span>
                    <span
                      className="text-xs "
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(submission?.concepts)
                          ?.replace(/;/g, '<br>')
                          .replace(new RegExp('&lt;', 'g'), '<')
                          .replace(new RegExp('&gt;', 'g'), '>'),
                      }}
                    />
                  </div>
                  {!isTestgroupListDetail && (
                    <>
                      <OptionCode submission={submission} showResult={showResult} />
                      <div className="d-flex mt-4">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-sm text-muted font-weight-bold  mb-1">
                            {t('MODAL_QUESTION_CODE_IA_SCORE')}
                          </span>
                          <span className="text-sm text-muted font-weight-600 ">
                            {submission?.ratio * 100}%
                          </span>
                        </div>
                        <div className="d-flex pl-3 ml-5 flex-column align-items-start">
                          <span className="text-sm text-muted font-weight-bold  mb-1">
                            {t('MODAL_QUESTION_CODE_IA_DETAILS_TITLE')}
                          </span>
                          <span className="text-xs ">{submission?.explanation}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ),
            });
            break;
          case AnswerTypeEnum.WRITE:
            submissionsModify.push({
              ...submission,
              titleCollapsed: submission?.isTyping
                ? t('MODAL_QUESTION_TYPING')
                : t('MODAL_QUESTION_WRITE'),
              iconResult:
                !isTestgroupListDetail &&
                (!submission?.isSubmission ||
                  (submission?.answerType === AnswerTypeEnum.WRITE &&
                    !submission?.submittedText)) ? (
                  <QuestionIcon />
                ) : Number(submission?.ratio) > 0 ? (
                  Number(submission?.ratio) === 1 ? (
                    <CheckIcon />
                  ) : (
                    <MinusWarning />
                  )
                ) : (
                  <FailedIcon />
                ),
              difficultTag: difficultyTag,
              componentResult: (
                <div className="d-flex flex-column mx-2">
                  {!isTestgroupListDetail && (
                    <>
                      <OptionText submission={submission} showResult={showResult} />
                      <div className="d-flex mt-4">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-medium text-muted font-weight-bold  mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_SCORE')}
                          </span>
                          <span className="text-medium text-muted font-weight-600 ">
                            {Math.round(submission?.ratio * 100)}%
                          </span>
                        </div>
                        <div className="d-flex pl-3 ml-5 flex-column align-items-start">
                          <span className="text-medium text-muted font-weight-bold  mb-1">
                            {submission?.isTyping
                              ? t('MODAL_QUESTION_DETAIL_WRITE_TYPING')
                              : t('MODAL_QUESTION_DETAIL_WRITE')}
                          </span>
                          <span className="text-xs ">{submission?.writeResponse}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center text-xs italic  mt-3">
                        {t('ADD_WRITE_QUESTION_NOTES')}
                        <InfoTooltip
                          placement="top"
                          content={
                            <div className="d-flex flex-column py-2">
                              <p className="text-xs   mb-2">
                                {submission?.isTyping
                                  ? t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION_TYPING')
                                  : t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION')}
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              ),
            });
            break;
          case AnswerTypeEnum.CODE:
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_TECHNICAL_EXERCISE'),
              iconResult: showResult ? (
                Number(submission.ratio) > 0 ? (
                  Number(submission.ratio) === 1 ? (
                    <CheckIcon />
                  ) : (
                    <MinusWarning />
                  )
                ) : (
                  <FailedIcon />
                )
              ) : null,
              componentResult: showResult ? (
                <OptionCode submission={submission} showResult={showResult} />
              ) : null,
            });
            break;
          default:
            submissionsModify.push({
              ...submission,
              titleCollapsed:
                submission?.type !== 'TECHNICAL'
                  ? t('MODAL_QUESTION_OPEN')
                  : t('MODAL_TECHNICAL_EXERCISE'),
              componentResult: showResult ? (
                <OptionText submission={submission} showResult={showResult} />
              ) : null,
              iconResult:
                submission?.type === TestGroupTypes.PRE_FILTER ? (
                  (Number(submission.submittedText) >
                    Number(submission.acceptedOptions[0]?.options[0]) &&
                    submission.writeResponse === PrefilterNumberConditions.GREATER) ||
                  (Number(submission.submittedText) <
                    Number(submission.acceptedOptions[0]?.options[0]) &&
                    submission.writeResponse === PrefilterNumberConditions.LESS) ||
                  (Number(submission.submittedText) ===
                    Number(submission.acceptedOptions[0]?.options[0]) &&
                    submission.writeResponse === PrefilterNumberConditions.EQUAL) ? (
                    <CheckIcon />
                  ) : (
                    <FailedIcon />
                  )
                ) : null,
            });
            break;
        }
      });
      submissionsModify.sort((a, b) => {
        const dateA = new Date(a?.submittedAt);
        const dateB = new Date(b?.submittedAt);

        const isSubmissionA = a.isSubmission !== undefined ? a.isSubmission : false;
        const isSubmissionB = b.isSubmission !== undefined ? b.isSubmission : false;

        if (!isSubmissionA && isSubmissionB) {
          return 1;
        }
        if (isSubmissionA && !isSubmissionB) {
          return -1;
        }

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      setSubmissionsPrint(submissionsModify);
      setMetricForProfile(profile);
    }
  }, [show, submissions]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        cleanData();
        dispatch(setSubmissionSelected({}));
      }, 750);
    }
  }, [show]);

  useEffect(() => {
    if (submissionsPrint?.length) {
      const indexToOpen = [];
      submissionsPrint?.forEach((submission, index) => {
        switch (submission?.answerType) {
          case AnswerTypeEnum.CHECK:
          case AnswerTypeEnum.RADIO:
            break;
          case AnswerTypeEnum.FILE:
            indexToOpen.push(index);
            break;
          case AnswerTypeEnum.VIDEO:
            indexToOpen.push(index);
            break;
          case AnswerTypeEnum.TEXT_AI:
            break;
          case AnswerTypeEnum.CODE:
            break;
          case AnswerTypeEnum.CODE_AI:
            break;
          case AnswerTypeEnum.WRITE:
            break;
          default:
            indexToOpen.push(index);
            break;
        }
      });
      setItemsToBeOpened(indexToOpen);
    }
  }, [submissionsPrint]);

  const calcDuration = (startedAt, finishAt) => {
    if (!startedAt || !finishAt || startedAt?.trim() === '' || finishAt?.trim() === '') {
      return t('WITHOUT_TIME');
    }
    const start = moment(startedAt);
    const end = moment(finishAt);
    const duration = moment.duration(end.diff(start));

    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');
    if (duration.hours() > 0) {
      return `${hours} h ${minutes} min`;
    }
    const roundedMinutes = Math.floor(duration.asMinutes());
    if (roundedMinutes >= 1) {
      let value = `${roundedMinutes} min`;
      if (duration.seconds()) value += `  ${seconds} s`;
      return value;
    }
    return `${seconds} s`;
  };
  const defaultScoreTagsValues = ['<31%', '>31%', '>46%', '>64%'];
  const handleRedirectScreening = async () => {
    const url = await generateTGDemoUrl(testGroup, user?.id);
    window.open(url.toString(), '_blank');
  };
  useEffect(() => {
    if (
      isNullOrUndefined(metricDataSelected) ||
      !metricDataSelected?.scores?.length ||
      !metricDataSelected?.averageScore ||
      !metricDataSelected?.median
    ) {
      setMetricForProfile();
    }
  }, [metricDataSelected]);

  return (
    <div
      className={`modal modal_submission d-flex justify-content-center position-fixed my-0 ${
        show ? 'visible_submission' : ''
      }`}
    >
      {!isTestgroupListDetail ? (
        <div
          className="modal__wrap_submission d-flex flex-column container-modal-screening w-100 bg-white"
          ref={modalRef}
        >
          <div
            aria-hidden="true"
            onClick={() => {
              setShow(false);
              setItemsToBeOpened([]);
            }}
          >
            <CloseIcon className="position-absolute right-0 top-0 mt-3 mr-4 cursor-pointer button-close-modal font-weight-bold" />
          </div>
          <div className="d-flex align-items-center">
            <span className=" text-base font-weight-600">
              {testGroup?.name || groupAcceleration?.technology?.displayName}
            </span>
            <div className="d-flex flex-column flex-md-row">
              {testGroup?.category?.name && isInvitedProfile && (
                <Ticket
                  text={testGroup?.category?.name}
                  className="ticket-technology-fullstack ticket-padding font-weight-bold  px-3 text-category-responsive mr-auto mr-md-2 ml-3"
                />
              )}
              {isInvitedProfile &&
                !!testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')?.length && (
                  <Ticket
                    text={testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')[0]?.displayName}
                    className="ticket-technology-frontend ticket-padding font-weight-bold  text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                  />
                )}
            </div>
          </div>
          {testGroup?.languages?.length ? (
            <>
              <div className="mt-4">
                <span className=" text-base font-weight-600 color-title-test my-2 mr-2">
                  {t('MODAL_SUBMISSION_TESTGROUP_LANGUAGES_LABEL')}
                </span>
                <Radio.Group value={null}>
                  {testGroup?.languages?.map((lang) => (
                    <Radio.Button value={lang} disabled style={{ cursor: 'default' }}>
                      <CountryFlag
                        countryIso={languagesList?.find((y) => y.value === lang)?.iso}
                        size="sm"
                      />
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
              {testGroup?.objetives && (
                <div className="mt-4">
                  <span className=" text-base font-weight-600 color-title-test my-2">
                    {t('MODAL_SUBMISSION_TESTGROUP_OBJETIVES_LABEL')}
                  </span>
                  <div className="d-flex flex-column flex-md-row my-2">
                    {testGroup?.objetives[key]?.length
                      ? testGroup?.objetives[key]?.map((obj) => (
                          <Ticket
                            text={obj}
                            className="ticket-technology-frontend ticket-padding font-weight-bold  text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                          />
                        ))
                      : testGroup.objetives[testGroup?.languages[0]].map((obj) => (
                          <Ticket
                            text={obj}
                            className="ticket-technology-frontend ticket-padding font-weight-bold  text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                          />
                        ))}
                  </div>
                </div>
              )}
              {testGroup?.summary && (
                <div className="d-flex flex-column mt-4">
                  <span className=" text-base font-weight-600 color-title-test">
                    {t('MODAL_RESUME_OF_TEST')}
                  </span>
                  <span className=" text-xs font-weight-500 color-title-test mt-2">
                    <pre className="tg-description  text-xs font-weight-500 color-title-test mt-2 pr-2">
                      {testGroup?.summary[key] ?? testGroup?.summary[testGroup?.languages[0]]}
                    </pre>
                  </span>
                </div>
              )}
              {testGroup?.detail && (
                <div className="d-flex flex-column mt-4">
                  <span className=" text-base font-weight-600 color-title-test">
                    {t('MODAL_TESTGROUP_DETAIL')}
                  </span>
                  <span className=" text-xs font-weight-500 color-title-test mt-2">
                    <pre className="tg-description  text-xs font-weight-500 color-title-test mt-2 pr-2">
                      {testGroup?.detail[key] ?? testGroup?.detail[testGroup?.languages[0]]}
                    </pre>
                  </span>
                </div>
              )}
            </>
          ) : (
            (testGroup?.description ||
              testGroup?.categoryId === process.env.RAZZLE_RUNTIME_CATEGORY_ID) && (
              <div className="d-flex flex-column mt-4">
                <span className=" text-base font-weight-600 color-title-test">
                  {t('MODAL_RESUME_OF_TEST')}
                </span>
                <span className=" text-xs font-weight-500 color-title-test mt-2">
                  {testGroup?.description ? (
                    <pre className="tg-description  text-xs font-weight-500 color-title-test mt-2 pr-2">
                      {testGroup?.description}
                    </pre>
                  ) : testGroup?.categoryId === process.env.RAZZLE_RUNTIME_CATEGORY_ID ? (
                    t('MODAL_EMPTY_DESCRIPTION_WITH_CATEGORY')
                  ) : null}
                </span>
              </div>
            )
          )}

          {metricDataSelected && (
            <div className="py-2">
              <div className="d-flex justify-content-start">
                <h3 className="font-size-20 ">{t('TESTGROUP_STATISTICS_TITLE')}</h3>
                <InfoTooltip
                  placement="bottom"
                  className="px-2"
                  content={
                    <div className="d-flex flex-column p-2 ml-2">
                      <span className=" text-xs mb-1">{t('STADISTICS_INFO_DETAILS')}</span>
                      <span className=" text-xs mb-2">
                        {t('STADISTICS_INFO_DETAILS_NUMBERS_CANDIDATE')}
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="d-flex flex-xl-row flex-lg-column flex-xs-column py-2 mb-2">
                <div className="d-flex gap-3 p-1">
                  <ScoreDistributionChart
                    mean={metricDataSelected?.averageScore}
                    specificScore={testGroup?.score}
                    scores={metricDataSelected?.scores}
                  />
                </div>
                <div className="d-flex flex-column gap-2 p-1">
                  <div className="d-flex flex-column flex-xl-row">
                    <div className="col-md-7 col-xl-5 px-md-3 py-md-1">
                      <span className="font-weight-500  text-light-black ">
                        {t('TESTGROUP_STATISTICS_COMPARE_BY_COMPANY')}
                      </span>
                      <DropdownSelect
                        options={companyMetricsOptions
                          .filter((item) => item.visible)
                          .map((item) => ({
                            id: item.id,
                            name: item.displayName,
                          }))}
                        className="w-100 position-relative"
                        selected={companyMetricSelected}
                        selectedPlaceholder
                        placeholderClassname="mr-2 text-xs font-weight-500"
                        axHeight={false}
                        bigArrowLightBlue
                        arrow={false}
                        placeholder={t('TESTGROUP_STATISTICS_COMPARE_BY_COMPANY')}
                        setState={(e) => {
                          setCompanyMetricSelected(e?.id);
                        }}
                      />
                    </div>
                    <div className="col-md-7 col-xl-5 px-md-3 py-md-1">
                      <span className="font-weight-500  text-light-black ">
                        {t('TESTGROUP_STATISTICS_COMPARE_BY_ROLE')}
                      </span>
                      <DropdownSelect
                        key={selectedCompanyData?.companyId}
                        options={[...roleMetricsOptions]
                          .filter((item) => item.visible)
                          .map((item) => ({
                            id: item.id,
                            name: item.displayName,
                          }))}
                        className="w-100 position-relative"
                        selected={roleMetricSelected}
                        selectedPlaceholder
                        placeholderClassname="mr-2 text-xs font-weight-500"
                        axHeight={false}
                        bigArrowLightBlue
                        arrow={false}
                        placeholder={t('TESTGROUP_STATISTICS_COMPARE_BY_ROLE')}
                        setState={(e) => {
                          setRoleMetricSelected(e?.id);
                        }}
                      />
                    </div>
                  </div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: generateMetricPercentilChartDisclaimer({
                        company:
                          selectedCompanyData?.companyId > 0
                            ? companyName
                            : TestGroupMetricsRol.GENERAL_USER_ROLES,
                        role: roleMetricSelected,
                        name: profile?.firstName,
                        lastName: profile.lastName,
                        testGroupName: testGroup?.name,
                        percentil: getPercentile(testGroup?.score, metricDataSelected?.percentiles),
                        score: testGroup?.score,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {testGroup?.scoreTags?.tags && (
            <div className="d-flex flex-column mt-4">
              <span className=" text-base font-weight-600 color-title-test">
                {t('MODAL_SCORE_TAGS')}
              </span>
              <div className="d-flex flex-column mt-2">
                {scoreTagsFormatted?.ranges?.map((range, index) => {
                  const [min, max] = range;
                  return (
                    <div className="mt-2">
                      <span className=" text-xs font-weight-600 color-title-test ">
                        {min} - {max}:
                      </span>
                      <span className=" text-xs font-weight-500 color-title-test mx-2">
                        {scoreTagsFormatted?.descriptions[index]}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {testGroup?.testFormats?.length && (
            <div className="d-flex flex-column my-4">
              <span className=" text-base font-weight-600 color-title-test my-2">
                {t('MODAL_TEST_FORMATS')}
              </span>
              <div className="d-flex flex-wrap  my-2">
                {testGroup?.testFormats?.map((format) => (
                  <span className="ticket-technology-frontend ticket-padding font-weight-bold  text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2 my-4 mx-2">
                    <img
                      src={format?.iconUrl}
                      alt="Format test logo"
                      className="text-blue-principal mr-2"
                      style={{ width: '1rem', height: '1rem' }}
                    />
                    {t(format?.label)}
                  </span>
                ))}
              </div>
            </div>
          )}
          <div className="d-flex mt-2">
            {(isInvitedProfile || submissions?.length) && (
              <div className="d-flex flex-row">
                <span className=" text-xs font-weight-500 color-count-questions">
                  {t('MODAL_DURATION_TEXT')}:&nbsp;
                </span>
                <span className=" text-xs font-weight-600 text-blue-modal">
                  {testGroup?.type !== TestGroupTypes.PRE_FILTER
                    ? formatMinutesSecondsToHours(testGroup?.duration ?? 0)
                    : t('NO_TIME_RESTRICTION')}
                </span>
              </div>
            )}
            {(isInvitedProfile || submissions?.length) && (
              <div
                className={`d-flex flex-row ${
                  isInvitedProfile || submissions?.length ? 'ml-4' : ''
                }`}
              >
                <span className=" text-xs font-weight-500 color-count-questions">
                  {t('MODAL_COUNT_ANSWERS')}&nbsp;
                </span>
                <span className=" text-xs font-weight-600 text-blue-modal">
                  {submissions?.length || ''}
                </span>
              </div>
            )}

            {!isNullOrUndefined(testGroup?.minimumTier) && (
              <div className="d-flex flex-row ml-4">
                <span className=" text-xs font-weight-500 color-count-questions">
                  {t('MODAL_MINIMUM_TIER_LABEL')}&nbsp;
                </span>
                <span className=" text-xs font-weight-600 text-blue-modal">
                  {testGroup?.minimumTier}
                </span>
              </div>
            )}
          </div>
          {!isTestgroupListDetail && (isInvitedProfile || submissions?.length) && (
            <div className="d-flex my-3">
              {possibleResults.map((result) => (
                <div key={result.text} className="d-flex flex-row pr-4">
                  {result.icon}
                  <span className="pl-2 font-weight-500  color-title-test">{result.text}</span>
                </div>
              ))}
            </div>
          )}
          <div className={`d-flex flex-column w-full  mt-1 ${isInvitedProfile ? 'mb-1' : 'my-2'}`}>
            <div className={`d-flex flex-column w-full  ${isInvitedProfile ? 'my-1' : 'bg-white'}`}>
              {isInvitedProfile || submissions?.length ? (
                submissionsPrint?.map((item, index) => (
                  <div key={`${item?.id}-submission-${index}`} className="mb-2" aria-hidden="true">
                    <div
                      className="d-flex flex-row justify-content-between align-items-center border-item-test-group p-3 position-relative"
                      onClick={() =>
                        itemsToBeOpened.includes(index)
                          ? setItemsToBeOpened(itemsToBeOpened.filter((i) => i !== index))
                          : setItemsToBeOpened([...itemsToBeOpened, index])
                      }
                      aria-hidden="true"
                    >
                      <span
                        className={`position-absolute ticket-position ticket-color-${item?.difficultTag} ticket-difficult font-weight-bold  px-1 mr-auto mr-md-2 ml-3`}
                      >
                        {t(item?.difficultTag)}
                      </span>
                      <div className="d-block  font-weight-600 text-xs text-color-item-test-group text-truncate media-body">
                        {itemsToBeOpened.includes(index) ? (
                          item?.titleCollapsed
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(parseLineHTML(item?.text))
                                ?.replace(/<[^>]+>/g, '')
                                .replace(new RegExp('&lt;', 'g'), '<')
                                .replace(new RegExp('&gt;', 'g'), '>'),
                            }}
                          />
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        {item.answerType === AnswerTypeEnum.TEXT_AI && !isTestgroupListDetail && (
                          <span
                            data-testid="text-ia-percentage"
                            className=" font-weight-bold text-muted mr-2"
                          >
                            {item.ratio * 100}%
                          </span>
                        )}
                        {showResult && item?.type !== TestGroupTypes.PRE_FILTER && (
                          <span className="mx-3  font-weight-bold text-muted text-xs ">
                            {calcDuration(item?.createdAt, item?.submittedAt)}
                          </span>
                        )}
                        <Arrow
                          className={`cursor-pointer ${
                            item?.iconResult && showResult ? 'mr-3' : ''
                          } ${
                            itemsToBeOpened.includes(index)
                              ? 'arrow-dropdown-modal-collapsed'
                              : 'arrow-dropdown-modal-uncollapsed'
                          }`}
                        />
                        {showResult && !itemsToBeOpened.includes(index) && item?.iconResult}
                      </div>
                    </div>
                    <Accordion open={itemsToBeOpened.includes(index)}>
                      <div className="d-flex flex-row justify-content-between border-item-test-group-collapsed p-4 mb-2">
                        <div className="d-flex flex-column w-100">
                          <span
                            className=" font-weight-500 text-xs mb-2 text-color-collapsed-modal"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(parseLineHTML(item?.text)),
                            }}
                          />
                          {item?.formula ? (
                            <MathJax.Provider>
                              <MathJax.Node
                                className="option-formula-background"
                                formula={item.formula.replace(/(?<!\\)\$/g, '')}
                              />
                            </MathJax.Provider>
                          ) : null}
                          {Boolean(item?.files?.length) &&
                            item?.files.map((file) => (
                              <ModalTestFile
                                file={file}
                                open={itemsToBeOpened.includes(index)}
                                key={file?.id}
                              />
                            ))}
                          {item?.componentResult}
                        </div>
                      </div>
                    </Accordion>
                  </div>
                ))
              ) : (
                <div className="bg-white description-container rounded mx-auto position-relative p-4">
                  <div className="border-bottom mb-4">
                    <h3 className=" font-weight-bold text-title text-capitalize">
                      {profile?.firstName} {profile?.lastName}
                    </h3>
                  </div>
                  {alkymerAdvance?.error ? (
                    <div className="bg-gray d-flex align-items-center w-100 py-3 px-2 rounded  font-weight-bold text-xs my-3">
                      <img src={timerIcon} alt={t('ICON_TIMER')} className="mr-2" />
                      {t('SKILLS_WAITING_ACCELERATION')}
                    </div>
                  ) : (
                    <div className="w-100 d-flex flex-column flex-md-row">
                      <div className="results-left-column">
                        <TrainingSkills
                          className="px-4 pb-4"
                          skills={groupAcceleration?.skills ?? []}
                          waiting={false}
                        />
                      </div>
                      <div className="results-right-column d-flex flex-column pl-3">
                        <div className="d-flex flex-column my-2">
                          <span className="text-xs text-muted font-weight-bold ">
                            {t('RESULTS_PAGE_RESULT')}
                          </span>
                          <span className="text-muted ">
                            <span className="font-weight-bold text-dark percent-result-text  name-text mr-2">
                              {Math.round(groupAcceleration?.bestScore)}
                            </span>
                            {t('RESULTS_PAGE_POINTS_OF').replace('{points}', '100')}
                          </span>
                        </div>
                        <div className="d-flex flex-column my-2">
                          <span className="text-xs text-muted font-weight-bold ">
                            {t('RESULTS_PAGE_TECHNOLOGY')}
                          </span>
                          <span className="font-weight-bold text-dark  name-text">
                            {groupAcceleration?.technology?.displayName}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              data-testid="MODAL_SUBMISSION_CLOSE_BUTTON"
              className="button-close-modal-submission mt-3 px-4 py-2 mr-auto border-0 text-decoration-none d-flex align-items-center"
              onClick={() => {
                setShow(false);
                setItemsToBeOpened([]);
              }}
              type="button"
            >
              <span className=" text-xs font-weight-700 text-white">{t('MODAL_CLOSE')}</span>
            </button>
          </div>
        </div>
      ) : (
        <div
          className="modal__wrap_submission d-flex flex-column container-modal-screening bg-white testgroup-details"
          ref={modalRef}
        >
          <div
            className={`d-flex align-items-center details-header justify-content-between w-100 gap-2 ${
              exampleQuestions && 'example-questions'
            }`}
          >
            <div className="d-flex flex-column flex-lg-row gap-1 w-100 justify-content-between">
              <div className="d-flex flex-column flex-lg-row">
                <span className=" font-weight-700 title justify-self-start mr-lg-2">
                  {testGroup?.name || groupAcceleration?.technology?.displayName}
                </span>
                <span
                  className={`d-flex align-items-center font-weight-500 ${
                    exampleQuestions && 'hidden'
                  }`}
                >
                  <Chart />
                  <div className="pt-2">
                    {isInvitedProfile &&
                      !!testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')?.length &&
                      testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')[0]?.displayName}
                  </div>
                </span>
              </div>
              <div className="d-flex">
                <div
                  className={`${
                    exampleQuestions ? 'd-none' : 'pointer'
                  } see-questions-button justify-self-end`}
                  onClick={(e) => {
                    e.preventDefault();
                    setExampleQuestions(true);
                  }}
                >
                  <Eye />
                  <span className="text-sm">{t('MODAL_SUBMISSION_SHOW_SAMPLE_QUESTIONS')}</span>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="" placement="top">
                      {t('TOOLTIP_DEMO_TESTGROUP')}
                    </Tooltip>
                  }
                >
                  <div
                    className="see-questions-button ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRedirectScreening();
                    }}
                  >
                    <FaPlay />
                    <span className="text-sm">{t('MODAL_SUBMISSION_SEE_DEMO_QUESTIONS')}</span>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div
              onClick={() => {
                if (!exampleQuestions) {
                  setShow(false);
                  setItemsToBeOpened([]);
                } else {
                  setExampleQuestions(false);
                }
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          {!exampleQuestions ? (
            <div className="d-flex flex-column pl-4 pt-4 pr-4 overflow-y-scroll pointer testgroup-main-container">
              <div className="tags-container d-flex justify-items-around py-1 px-5 align-self-start mb-3 gap-m">
                {doesNotHaveNewTag(testGroup?.tags) && isANewTestgroup(testGroup?.createdAt) && (
                  <div key={`${testGroup?.id}-new-tag-`}>
                    <OtherCheck />
                    <span className="test-label-item pl-2">{t('TEST_NEW_TAG')}</span>
                  </div>
                )}
                {testGroup?.tags?.some((tag) => tag.type === 'MARKETING')
                  ? testGroup?.tags?.map((tag, index) => {
                      if (tag.type === 'MARKETING') {
                        return (
                          <div key={`tag-${tag?.id}-${index}`} className="">
                            <OtherCheck />
                            <span className="test-label-item pl-2">{tag.displayName}</span>
                          </div>
                        );
                      }
                    })
                  : t('MODAL_SUBMISSION_DEFAULT_MARKETING_TAGS')
                      ?.split(',')
                      ?.map((tag, index) => (
                        <div key={`tag-${testGroup?.id}${index}`} className="">
                          <OtherCheck />
                          <span className="test-label-item pl-2">{tag}</span>
                        </div>
                      ))}
              </div>
              <div className="testgroup-content-columns">
                <div className=" first-container w-1-3">
                  <div>
                    <div className="font-size-16 font-weight-500 detail-boxes-container">
                      <div className="d-flex flex-column">
                        <div className="bg-blue-light box-item mb-4">
                          <div>
                            <ClockIcon />
                          </div>
                          <p>{`${testGroup?.duration} ${t('MODAL_SUBMISSION_MINUTES')}`}</p>
                        </div>
                        <div className="bg-blue-light box-item">
                          <CheckWithCircle />
                          <p>
                            {questionsAmount(testGroup)}
                            {` ${t('TEST_ITEM_QUESTION')}${
                              questionsAmount(testGroup) > 1 ? 's' : ''
                            }`}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="bg-blue-light box-item mb-4">
                          <p className=" ">{t('MODAL_SUBMISSION_AVAILABLE_LANGUAGES')}</p>
                          <div className="d-flex font-size-14 font-weight-400 w-75 justify-content-around">
                            {testGroup?.languages ? (
                              testGroup.languages.map((lang) => (
                                <span>
                                  <CountryFlag
                                    className="border-radius-5"
                                    countryIso={languagesList?.find((y) => y.value == lang)?.iso}
                                    size="sm"
                                  />
                                  {lang?.toUpperCase()}
                                </span>
                              ))
                            ) : (
                              <span>
                                <CountryFlag
                                  className="border-radius-5"
                                  countryIso="ESP"
                                  size="sm"
                                />
                                ES
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="bg-blue-light box-item no-gap">
                          <span>{t('TESTGROUP_TITLE_TYPE_OF_QUESTIONS')}</span>
                          <div className="d-flex flex-wrap px-3 pb-1 justify-content-center align-items-center gap-formats overflow-y">
                            {testGroup?.testFormats?.map((format, index) => (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{format?.name}</Tooltip>}
                              >
                                <img
                                  src={format?.iconUrl}
                                  alt="Format test logo"
                                  className="rounded-0"
                                  style={{ width: '1.2rem', height: '1.2rem' }}
                                />
                              </OverlayTrigger>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h3 className="font-size-20 mb-3">{t('MODAL_SUBMISSION_THE_TEST_COVERS')}</h3>
                      <div className="font-size-12 font-weight-400 d-flex gap-m flex-wrap">
                        {testGroup?.objetives?.[key]?.length
                          ? testGroup?.objetives?.[key]?.map((obj, i) => (
                              <div key={`${testGroup?.id}-objective-${i}`} className="gray-pill">
                                <span>{obj}</span>
                              </div>
                            ))
                          : t('MODAL_SUBMISSION_DEFAULT_OBJECTIVES')
                              ?.split(',')
                              ?.map((obj, i) => (
                                <div key={`${testGroup?.id}-objective-${i}`} className="gray-pill">
                                  <span>{obj}</span>
                                </div>
                              ))}
                      </div>
                    </div>
                  </div>
                  {testGroup?.endorsement && (
                    <div className="created-by self-end pt-4">
                      <h3 className="font-size-20 font-weight-500 mb-4">
                        {t('TESTGROUP_CREATED_BY')}
                      </h3>
                      <div className="d-flex gap-m">
                        <img
                          src={testGroup?.endorsement?.imgUrl}
                          alt="author-avatar"
                          className="img-endorsement"
                        />
                        <div className="pl-2">
                          <h3 className="font-size-16 font-weight-700 truncate">
                            {testGroup?.endorsement?.name}
                          </h3>
                          <p className="font-size-14 font-weight-400">
                            {testGroup?.endorsement?.summary?.[key]}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className=" second-container w-1-3">
                  <div className="statistics-container font-size-16 pt-4 pt-xl-0">
                    <div className="d-flex justify-content-start">
                      <h3 className="font-size-20 ">{t('TESTGROUP_STATISTICS_TITLE')}</h3>
                      <InfoTooltip
                        placement="bottom"
                        className="px-2"
                        content={
                          <div className="d-flex flex-column p-2 ml-2">
                            <span className=" text-xs mb-1">{t('STADISTICS_INFO_DETAILS')}</span>
                            <span className=" text-xs mb-2">
                              {t('STADISTICS_INFO_DETAILS_NUMBERS')}
                            </span>
                          </div>
                        }
                      />
                    </div>
                    {testGroup?.metrics?.dataGrouped?.length > 0 &&
                    testGroup.type === TestGroupTypes.QUANTITATIVE ? (
                      <div className="statistics-container-selector row">
                        <div className="col-5 col-lg-6">
                          <span className="font-weight-500  text-light-black ">
                            {t('TESTGROUP_STATISTICS_COMPARE_BY_COMPANY')}
                          </span>
                          <DropdownSelect
                            options={companyMetricsOptions
                              .filter((item) => item.visible)
                              .map((item) => ({
                                id: item.id,
                                name: item.displayName,
                              }))}
                            className="w-100 position-relative"
                            selected={companyMetricSelected}
                            selectedPlaceholder
                            placeholderClassname="mr-2 text-xs font-weight-500"
                            axHeight={false}
                            bigArrowLightBlue
                            arrow={false}
                            placeholder={t('TESTGROUP_STATISTICS_COMPARE_BY_COMPANY')}
                            setState={(e) => {
                              setCompanyMetricSelected(e?.id);
                            }}
                          />
                        </div>
                        <div className="col-5 col-lg-6">
                          <span className="font-weight-500  text-light-black ">
                            {t('TESTGROUP_STATISTICS_COMPARE_BY_ROLE')}
                          </span>
                          <DropdownSelect
                            key={selectedCompanyData?.companyId}
                            options={[...roleMetricsOptions]
                              .filter((item) => item.visible)
                              .map((item) => ({
                                id: item.id,
                                name: item.displayName,
                              }))}
                            className="w-100 position-relative"
                            selected={roleMetricSelected}
                            selectedPlaceholder
                            placeholderClassname="mr-2 text-xs font-weight-500"
                            axHeight={false}
                            bigArrowLightBlue
                            arrow={false}
                            placeholder={t('TESTGROUP_STATISTICS_COMPARE_BY_ROLE')}
                            setState={(e) => {
                              setRoleMetricSelected(e?.id);
                            }}
                          />
                        </div>
                        <div
                          className={`${
                            metricDataSelected?.scores?.length > 0
                              ? 'metric-chartlayout-wrapper mt-3 ml-3'
                              : 'd-none'
                          } `}
                        >
                          <ChartLayout
                            averageScore={metricDataSelected?.averageScore}
                            totalCount={metricDataSelected?.totalCount}
                            scores={metricDataSelected?.scores}
                            percentiles={metricDataSelected?.percentiles}
                            title={`${t('TESTGROUP_STATISTICS_TITLE_CHART').replace(
                              '{{company}}',
                              companyMetricSelected !== 0
                                ? companyName
                                : t('ALL_CANDIDATES_STATISTICS_TITLE')
                            )}
                              ${
                                roleMetricSelected !==
                                TestGroupMetricsRol.USER_WHITOUT_FORMATION_ROLE
                                  ? t('FOR_ROLE_TEXT_DESCRIPTION').replace(
                                      '{{role}}',
                                      t(roleMetricSelected)
                                    )
                                  : t(TestGroupMetricsRol.USER_WHITOUT_FORMATION_ROLE)
                              }`}
                            fixedHeight={false}
                          >
                            <div className="my-3 align-content-center">
                              <div className="d-flex d-flex">
                                <div className="mr-3 statistics-total-text">
                                  <p data-testid="score-chart-average" className=" font-weight-550">
                                    {oneDecimal(Math.round(metricDataSelected?.averageScore))}
                                    <span className=" font-weight-550 ml-1">
                                      {t('OPTION_WEIGHT_PTS_TEXT')}
                                    </span>
                                  </p>
                                  <span className="text-sm  total-invitations-text">
                                    {t('SCORES_CHART_AVERAGE')}
                                  </span>
                                </div>
                                <div className="vertical-line pl-3 statistics-total-text">
                                  <p data-testid="score-chart-median" className=" font-weight-600">
                                    {oneDecimal(Math.round(metricDataSelected?.median))}
                                    <span className=" font-weight-600 ml-1">
                                      {t('OPTION_WEIGHT_PTS_TEXT')}
                                    </span>
                                  </p>
                                  <span className="text-sm  total-invitations-text">
                                    {t('SCORES_CHART_MEDIAN')}
                                  </span>
                                </div>

                                <div className="d-flex mt-3">
                                  <div className="d-flex justify-content-center align-items-end ml-3 mb-1">
                                    <span className=" text-sm font-weight-500">
                                      {t('SCORES_CHART_TALENTS')}:
                                      <span
                                        data-testid="total-cantidates-found"
                                        className={` text-sm font-weight-600 mx-1 ${
                                          Boolean(metricDataSelected?.totalCount) && 'text-purple'
                                        }`}
                                      >
                                        {metricDataSelected?.totalCount}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {metricDataSelected?.percentiles && (
                                <ScoreDistributionChart
                                  mean={metricDataSelected?.averageScore}
                                  scores={metricDataSelected?.scores}
                                />
                              )}
                            </div>
                          </ChartLayout>
                        </div>
                      </div>
                    ) : (
                      <div className="statistics-content">
                        <div className="d-flex gap-m pb-3">
                          <Candidates width="31" />
                          <span className="font-weight-500">{`${t(
                            'MODAL_SUBMISSION_CANDIDATES_COUNT'
                          )} ${
                            testGroup?.metrics?.candidatesCount
                              ? formatNumberOfCandidates(testGroup.metrics.candidatesCount)
                              : '+100'
                          } ${t('TESTGROUP_CANDIDATES').toLowerCase()}`}</span>
                        </div>
                        {testGroup?.type === 'QUALITATIVE' ? (
                          <div className="d-flex align-items-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {t('CREATE_SCREENING_QUALITATIVE_LABEL')}
                                </Tooltip>
                              }
                            >
                              <div className="d-flex align-items-center gap">
                                <QualitativeIcon width="2.5rem" />
                                <span>{t('CREATE_SCREENING_QUALITATIVE_LABEL')}</span>
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex gap-m pb-3">
                              <ScoreChart width="31" />
                              <span>
                                {`${
                                  testGroup?.metrics?.average
                                    ? Number(testGroup?.metrics?.average.toFixed(0)) || 50
                                    : 50
                                }% ${t('TESTGROUP_AVERAGE_SCORE')}`}
                              </span>
                            </div>
                            {testGroup?.scoreTags?.tags ? (
                              <div className="d-flex flex-column mt-2">
                                {scoreTagsFormatted?.ranges?.map((range, index) => {
                                  const [min, max] = range;
                                  return (
                                    <div
                                      className="d-flex gap-m"
                                      key={`${testGroup?.id}-scoretag-${index}`}
                                    >
                                      {stars(
                                        [index >= 1, index >= 2, index >= 3],
                                        undefined,
                                        index >= 4
                                      )}
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-${index}`}>
                                            {scoreTagsFormatted?.descriptions[index]}
                                          </Tooltip>
                                        }
                                      >
                                        <div>
                                          <span className="">
                                            {index == 0 ? `< ${max}%` : `> ${min}% `}
                                          </span>
                                          <span className="">
                                            {`${scoreTagsFormatted?.descriptions[index]?.slice(
                                              0,
                                              22
                                            )}${
                                              scoreTagsFormatted?.descriptions[index]?.length >= 22
                                                ? '...'
                                                : ''
                                            }`}
                                          </span>
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="d-flex flex-column mt-2">
                                {t('TESTGROUP_DEFAULT_SCORETAGS')
                                  ?.split(',')
                                  ?.map((label, index) => (
                                    <div
                                      className="d-flex gap-m"
                                      key={`${testGroup?.id}-scoretag-${index}`}
                                    >
                                      {stars([index >= 1, index >= 2, index >= 3])}
                                      <div>
                                        <span className="mr-2">
                                          {defaultScoreTagsValues[index]}
                                        </span>
                                        <span>{label}</span>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="used-by pt-4">
                    <h3 className="font-size-20 font-weight-500 mb-4">{t('TESTGROUP_USED_BY')}</h3>
                    <div className="d-flex gap-m">
                      {testGroup?.companyUsage
                        ?.slice(0, 2)
                        .filter((company) => company.companyId !== user?.companyId)
                        .map((company, index) => (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                <>
                                  <p>{company?.name}</p>
                                  <p>{company?.summary?.[key]}</p>
                                </>
                              </Tooltip>
                            }
                          >
                            <div
                              className="image-wrapper"
                              style={{ backgroundImage: `url(${company?.imgUrl})` }}
                            >
                              <img src={company?.imgUrl} alt="company-logo" />
                            </div>
                          </OverlayTrigger>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="third-container w-1-3 pb-3 px-4">
                  {testGroup?.summary?.[key] && (
                    <div className="mb-5">
                      <p className="font-size-20">{t('MODAL_SUBMISSION_TEST_SUMMARY')}</p>
                      <p className="font-size-16">{testGroup?.summary?.[key]}</p>
                    </div>
                  )}

                  <div>
                    <p className="font-size-20">{t('MODAL_SUBMISSION_TEST_DESCRIPTION')}</p>
                    <p className="font-size-16">
                      {key && testGroup?.detail?.[key]
                        ? testGroup?.detail?.[key]
                        : testGroup?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="tests-container">
              <p>{t('MODAL_SUBMISSION_SAMPLE_QUESTIONS_DISCLAIMER')}</p>
              <div
                className={`d-flex flex-column w-full  mt-1 ${isInvitedProfile ? 'mb-1' : 'my-2'}`}
              >
                <div
                  className={`d-flex flex-column w-full gap-m  ${
                    isInvitedProfile ? 'my-1' : 'bg-white'
                  }`}
                >
                  {isInvitedProfile || submissions?.length ? (
                    submissionsPrint?.map((item, index) => (
                      <div
                        key={`${item?.id}-submission-print-${index}`}
                        className="test-item"
                        aria-hidden="true"
                      >
                        <div
                          className="d-flex justify-content-between align-items-center position-relative"
                          onClick={() =>
                            itemsToBeOpened.includes(index)
                              ? setItemsToBeOpened(itemsToBeOpened.filter((i) => i !== index))
                              : setItemsToBeOpened([...itemsToBeOpened, index])
                          }
                          aria-hidden="true"
                        >
                          <div>
                            <p className="font-size-14 font-weight-600 text-dark">{`${'Pregunta'} ${
                              index + 1
                            } - 
                        ${item?.titleCollapsed
                          ?.replace(/<[^>]+>/g, '')
                          .replace(new RegExp('&lt;', 'g'), '<')
                          .replace(new RegExp('&gt;', 'g'), '>')} `}</p>
                            <p className="mt-2">
                              {t('MODAL_SUBMISSION_SAMPLE_QUESTIONS_QUESTION_BODY')}
                            </p>
                          </div>
                          <div className="d-flex">
                            {item.answerType === AnswerTypeEnum.TEXT_AI &&
                              !isTestgroupListDetail && (
                                <span
                                  data-testid="text-ia-percentage"
                                  className=" font-weight-bold text-muted mr-2"
                                >
                                  {item.ratio * 100}%
                                </span>
                              )}
                            <Arrow
                              modal
                              className={`cursor-pointer ${
                                item?.iconResult && showResult ? 'mr-3' : ''
                              } ${
                                itemsToBeOpened.includes(index)
                                  ? 'arrow-dropdown-modal-collapsed'
                                  : 'arrow-dropdown-modal-uncollapsed'
                              }`}
                            />
                            {showResult && !itemsToBeOpened.includes(index) && item?.iconResult}
                          </div>
                        </div>
                        <Accordion open={itemsToBeOpened.includes(index)}>
                          <div className="mt-2 d-flex font-size-14 font-weight-400">
                            <p className={`pr-4 ${item?.componentResult ? 'w-50' : 'w-100'}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(parseLineHTML(item?.text)),
                                }}
                              />
                              {item?.formula && (
                                <MathJax.Provider>
                                  <MathJax.Node
                                    className="option-formula-background"
                                    formula={item.formula.replace(/(?<!\\)\$/g, '')}
                                  />
                                </MathJax.Provider>
                              )}
                              {Boolean(item?.files?.length) && (
                                <div className="m-4">
                                  {item?.files.map((file) => (
                                    <ModalTestFile
                                      file={file}
                                      open={itemsToBeOpened.includes(index)}
                                      key={file?.id}
                                    />
                                  ))}
                                </div>
                              )}
                            </p>
                            {(item.answerType === AnswerTypeEnum.RADIO ||
                              item.answerType === AnswerTypeEnum.CHECK) &&
                              Boolean(item?.componentResult) && (
                                <div className="d-flex flex-column w-50 bg-grey-light rounded">
                                  {item?.componentResult}
                                </div>
                              )}
                          </div>
                        </Accordion>
                      </div>
                    ))
                  ) : (
                    <div className="bg-white description-container rounded mx-auto position-relative p-4">
                      <div className="border-bottom mb-4">
                        <h3 className=" font-weight-bold text-title text-capitalize">
                          {profile?.firstName} {profile?.lastName}
                        </h3>
                      </div>
                      {alkymerAdvance?.error ? (
                        <div className="bg-gray d-flex align-items-center w-100 py-3 px-2 rounded  font-weight-bold text-xs my-3">
                          <img src={timerIcon} alt={t('ICON_TIMER')} className="mr-2" />
                          {t('SKILLS_WAITING_ACCELERATION')}
                        </div>
                      ) : (
                        <div className="w-100 d-flex flex-column flex-md-row">
                          <div className="results-left-column">
                            <TrainingSkills
                              className="px-4 pb-4"
                              skills={groupAcceleration?.skills ?? []}
                              waiting={false}
                            />
                          </div>
                          <div className="results-right-column d-flex flex-column pl-3">
                            <div className="d-flex flex-column my-2">
                              <span className="text-xs text-muted font-weight-bold ">
                                {t('RESULTS_PAGE_RESULT')}
                              </span>
                              <span className="text-muted ">
                                <span className="font-weight-bold text-dark percent-result-text  name-text mr-2">
                                  {Math.round(groupAcceleration?.bestScore)}
                                </span>
                                {t('RESULTS_PAGE_POINTS_OF').replace('{points}', '100')}
                              </span>
                            </div>
                            <div className="d-flex flex-column my-2">
                              <span className="text-xs text-muted font-weight-bold ">
                                {t('RESULTS_PAGE_TECHNOLOGY')}
                              </span>
                              <span className="font-weight-bold text-dark  name-text">
                                {groupAcceleration?.technology?.displayName}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ModalSubmission;
