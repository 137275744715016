import { useEffect, useState } from 'react';
import { Steps } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import Spinner from '../../components/spinner/Spinner';
import './CreateEvaluation.scss';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import CreateEvaluationFirstStep from '../../components/CreateEvaluationFirstStep/CreateEvaluationFirstStep';
import CreateEvaluationSecondStep from '../../components/CreateEvaluationSecondStep/CreateEvaluationSecondStep';
import CreateEvaluationThirdStep from '../../components/CreateEvaluationThirdStep/CreateEvaluationThirdStep';
import { CREATE_EVALUATION } from '../../constants/routes';
import BlockRedirection from '../../components/BlockRedirection/BlockRedirection';
import CreateEvaluationFourthStep from '../../components/CreateEvaluationFourthStep/CreateEvaluationFourthStep';
import CreateEvaluationSummaryNav from '../../components/CreateEvaluationSummaryNav/createEvaluationSummaryNav';
import { debounce } from 'lodash';

const CreateEvaluation = () => {
  const {
    step,
    draftId,
    enableCreateButton,
    existingChanges,
    evaluation,
    loadingCreate,
    loadingTests,
    disableCustomTestGroupWeight,
    missingTestgroupWeight,
    preventReload,
    incompleteQuestionValidation,
    fetchTests,
    saveChangesModal,
    setShowExitModal,
    handleEvaluationName,
    handleClearData,
    handleSetEvaluationData,
    handleCreateDraft,
    handleOpenEvaluation,
    handleEvaluationQuickRole,
    handleChangeStep,
    loadingCreateDraft,
    newScreeningFilters,
    handleEditDraft,
    disableOpenEvaluation,
    fetchSearchTypes,
  } = useCreateEvaluation();
  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => () => {
      if (location?.pathname === CREATE_EVALUATION) handleClearData();
    },
    [location?.pathname]
  );
  const [searchTypesLoading, setSearchTypesLoading] = useState(false);
  useEffect(async () => {
    const setSearchTypes = async () => await fetchSearchTypes('', 0);
    setSearchTypesLoading(true);
    await setSearchTypes();
    setSearchTypesLoading(false);
  }, []);

  const onChangestep = debounce(handleChangeStep, 500);

  return (
    <BlockRedirection
      conditionToBlock={preventReload}
      route={CREATE_EVALUATION}
      updateConditionToBlock
      message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
    >
      <div className="w-100 mx-auto pl-3 pr-3 create-evaluation-container h-100">
        {saveChangesModal()}
        <div>
          <BackgroundTop
            title={t('CREATE_EVALUATION_SECTION_TITLE')}
            routes={[
              { text: 'MENU_ITEM_SCREENING', clickable: false },
              ...(history.location.state?.from
                ? [
                    {
                      text: history.location.state?.fromName,
                      clickable: true,
                      route: history.location.state?.from,
                    },
                  ]
                : []),
            ]}
            className="position-relative"
            titleClassname="text-title"
          />
        </div>
        <div
          className={`position-relative new-search-container px-3 py-2 bg-white d-flex w-100 flex-column rounded mx-auto h-100 ${
            step === 2 ? 'window-height-step3' : ''
          }`}
        >
          {loadingCreate ? (
            <Spinner show />
          ) : (
            <div className="position-relative w-100">
              <div className="fixed-container py-2">
                <CreateEvaluationSummaryNav
                  evaluation={evaluation}
                  setShowExitModal={setShowExitModal}
                  existingChanges={existingChanges}
                  incompleteQuestionValidation={incompleteQuestionValidation}
                  step={step}
                  handleChangeStep={handleChangeStep}
                  handleOpenEvaluation={handleOpenEvaluation}
                  disableOpenEvaluation={disableOpenEvaluation}
                  handleEvaluationName={handleEvaluationName}
                />
                <Steps
                  data-testid="create-evaluation-steps-container"
                  current={step}
                  onChange={onChangestep}
                  items={[
                    {
                      title: t('CREATE_EVALUATION_FIRST_STEP_TITLE'),
                      'data-testid': 'CREATE_EVALUATION_FIRST_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_SECOND_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_SECOND_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_THIRD_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_THIRD_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_FOURTH_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_FOURTH_STEP_TITLE',
                    },
                  ]}
                />
              </div>
              <div className="mt-2 flex-grow-1 d-flex flex-column align-items-center h-100">
                {step === 0 && (
                  <CreateEvaluationFirstStep
                    evaluation={evaluation}
                    handleEvaluationQuickRole={handleEvaluationQuickRole}
                    handleSetEvaluationData={handleSetEvaluationData}
                    newScreeningFilters={newScreeningFilters}
                    draftId={draftId}
                    handleCreateDraft={handleCreateDraft}
                    handleEditDraft={handleEditDraft}
                    searchTypesLoading={searchTypesLoading}
                    handleChangeStep={handleChangeStep}
                  />
                )}
                {step === 1 && (
                  <CreateEvaluationSecondStep
                    loadingTests={loadingTests}
                    fetchTests={fetchTests}
                    evaluation={evaluation}
                  />
                )}
                {step === 2 && <CreateEvaluationThirdStep />}
                {step === 3 && (
                  <CreateEvaluationFourthStep
                    handleChangeStep={handleChangeStep}
                    evaluation={evaluation}
                    handleSetEvaluationData={handleSetEvaluationData}
                    missingTestgroupWeight={missingTestgroupWeight}
                    disableCustomTestGroupWeight={disableCustomTestGroupWeight}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </BlockRedirection>
  );
};

export default CreateEvaluation;
