import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../../i18n/i18n';
import './ScoreDistributionChart.scss';
import { oneDecimal } from '../../../utils/numbersUtils';

export default function ScoreDistributionChart({
  mean,
  scores,
  width = 500,
  height = 200,
  padding = 40,
  specificScore,
}) {
  const stdDev = Math.sqrt(
    scores.reduce((sum, score) => sum + Math.pow(score - mean, 2), 0) / scores.length
  );

  const gaussian = (x, mean, stdDev) =>
    (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-((x - mean) ** 2) / (2 * stdDev ** 2));

  const normalizedscoreDistributions = Array.from({ length: 101 }, (_, i) => {
    const x = i;
    return {
      x,
      y: gaussian(x, mean, stdDev),
    };
  });

  const maxGaussian = Math.max(...normalizedscoreDistributions.map((p) => p.y));

  const xScale = (x) => (x / 100) * (width - 2 * padding) + padding;
  const yScale = (y) => height - padding - (y / maxGaussian) * (height - 2 * padding);

  const smoothPathData = normalizedscoreDistributions
    .map((point, index, array) => {
      const x = xScale(point.x);
      const y = yScale(point.y);
      if (index === 0) {
        return `M${x},${y}`;
      }
      const prevX = xScale(array[index - 1].x);
      const prevY = yScale(array[index - 1].y);
      const controlX1 = (prevX + x) / 2;
      const controlY1 = prevY;
      return `C${controlX1},${controlY1} ${controlX1},${y} ${x},${y}`;
    })
    .join(' ');

  const areaPathData =
    smoothPathData + ` L${xScale(100)},${yScale(0)} L${xScale(0)},${yScale(0)} Z`;

  const averageX = xScale(mean);
  const specificScoreX = specificScore >= 0 ? xScale(specificScore) : 80;
  const specificScoreY = specificScore >= 0 ? yScale(gaussian(specificScore, mean, stdDev)) : 40;

  return (
    <div
      className="scoreDistribution-chart-container"
      data-testid="scoreDistribution-chart-container"
    >
      <svg
        data-testid="scoreDistribution-svg"
        width={width}
        height={specificScore >= 0 ? height * 1.5 : height}
        role="img"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4987be" stopOpacity="0.5" />
            <stop offset="100%" stopColor="#4987be" stopOpacity="0" />
          </linearGradient>
        </defs>
        <g>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{`${t('STANDARD_DESV')}: ${oneDecimal(stdDev)}`}</Tooltip>}
          >
            <path
              d={smoothPathData}
              fill="none"
              stroke="#4987be"
              strokeWidth="2"
              data-testid="smooth-path"
            />
          </OverlayTrigger>

          <path d={areaPathData} fill="url(#gradient)" stroke="none" />
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className="">{`${t('MEAN')}: ${oneDecimal(mean)}`}</Tooltip>}
          >
            <line
              x1={averageX}
              y1={padding}
              x2={averageX}
              y2={height - padding}
              stroke="red"
              strokeWidth="3"
              strokeDasharray="4"
              data-testid="mean-line"
            />
          </OverlayTrigger>
        </g>
        <g className="axis">
          <line
            x1={padding}
            y1={height - padding}
            x2={width - padding}
            y2={height - padding}
            stroke="#D9D9D9"
          />
          <text
            x={width / 2}
            y={height - padding + 35}
            textAnchor="middle"
            fill="#D9D9D9"
            title="Scores"
          >
            {t('STADISTICS_SCORE')}
          </text>
          <line x1={padding} y1={padding} x2={padding} y2={height - padding} stroke="#D9D9D9" />

          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((label) => (
            <text
              key={label}
              x={xScale(label)}
              y={height - padding + 20}
              textAnchor="middle"
              fill="#D9D9D9"
            >
              {label}
            </text>
          ))}
        </g>
        <g className="legend" transform={`translate(${0}, ${height - padding + 30})`}>
          <line x1="0" y1="2" x2="20" y2="2" stroke="red" strokeWidth="3" strokeDasharray="4" />
          <text
            x={25}
            y={5}
            textAnchor="start"
            className="text-base font-weight-500"
            fill="#999b99"
          >
            {t('MEAN')}
          </text>
          {specificScore >= 0 && (
            <>
              <circle cx={9} cy={20} r="4" fill="#17a2b8" />
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className="">{t('CANDIDATE_CHART_POSITION_DESCRIPTION')} </Tooltip>
                }
              >
                <text
                  x={25}
                  y={25}
                  textAnchor="start"
                  className="text-base font-weight-500"
                  fill="#999b99"
                >
                  {t('CANDIDATE_CHART_POSITION')}
                </text>
              </OverlayTrigger>
            </>
          )}
        </g>
        <g className="legend" transform={`translate(${0}, ${padding - 20})`}>
          {specificScore >= 0 && (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="">{`${t('SPECIFIC_SCORE')}: ${oneDecimal(
                    specificScore
                  )}%`}</Tooltip>
                }
              >
                <circle cx={specificScoreX} cy={specificScoreY - 20} r="6" fill="#17a2b8" />
              </OverlayTrigger>
            </>
          )}
        </g>
      </svg>
    </div>
  );
}
