import { TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import './TreeMultiSelect.scss';
import arrowIcon from '../../assets/dashboard/arrow-small.svg';
import { t } from '../../i18n/i18n';

const TreeMultiSelect = ({
  options = [],
  value = [],
  onChange = async () => {},
  className = '',
  style = {},
  textSize = '12px',
  label = '',
  width = '100%',
  labelPosition = { top: 5, left: 10 },
  labelName = 'name',
  dropdownWidth = 'max-content',
  dropdownStyle = {},
  treeCheckStrictly = false,
  openDrowdown,
  showSelectUnselectAll = false,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedValues, setSelectedValues] = useState(value);

  useEffect(() => {
    setOpen(false);
  }, [openDrowdown]);

  useEffect(() => {
    // Build the tree structure
    const buildTreeData = (nodes) => {
      const map = new Map();
      const roots = [];

      nodes.forEach((node) => {
        const newNode = {
          ...node,
          value: node?.id,
          title: node[labelName],
          key: node?.id,
          children: [],
        };
        map.set(node.id, newNode);
      });

      nodes.forEach((node) => {
        const parent = map.get(node.parentId);
        if (parent) {
          parent.children.push(map.get(node.id));
        } else {
          roots.push(map.get(node.id));
        }
      });

      const sortTree = (node) => {
        node.children.sort((a, b) => a.title.localeCompare(b.title));
        node.children.forEach(sortTree);
      };

      roots.forEach(sortTree);
      return roots;
    };

    const tree = buildTreeData(options);
    setTreeData(tree);
    setFilteredTreeData(tree);
  }, [options, labelName]);

  // Función para filtrar los datos del árbol en función de `searchText`
  const filterTreeData = (nodes, searchText) => {
    const filter = (nodes) =>
      nodes
        .map((node) => {
          const match = node.title.toLowerCase().includes(searchText.toLowerCase());
          const filteredChildren = filter(node.children || []);
          if (match || filteredChildren.length) {
            return { ...node, children: filteredChildren };
          }
          return null;
        })
        .filter((node) => node !== null);

    return filter(nodes);
  };

  useEffect(() => {
    if (searchText) {
      const filteredNodes = filterTreeData(treeData, searchText);
      setFilteredTreeData(filteredNodes);
      setOpen(true);

      const keysToExpand = [];
      const findExpandedKeys = (nodes) => {
        nodes.forEach((node) => {
          if (node.title.toLowerCase().includes(searchText.toLowerCase())) {
            keysToExpand.push(node.key);
            findExpandedKeys(node.children);
          } else if (node.children) {
            const filteredChildren = filterTreeData(node.children, searchText);
            if (filteredChildren.length > 0) {
              keysToExpand.push(node.key);
              findExpandedKeys(filteredChildren);
            }
          }
        });
      };
      findExpandedKeys(filteredNodes);
      setExpandedKeys(keysToExpand);
    } else {
      setFilteredTreeData(treeData);
      setExpandedKeys([]);
    }
  }, [searchText, treeData]);

  const onChangeEvent = (newValues = []) => {
    setSelectedValues(newValues);
    const selectedItems = treeData.filter((item) => newValues.includes(item?.id));
    onChange(newValues, selectedItems);
  };

  const handleSelectAll = () => {
    const allValues = options.map((item) => item.id);
    setSelectedValues(allValues);
    onChange(allValues, options);
  };

  const handleDeselectAll = () => {
    setSelectedValues([]);
    onChange([], []);
  };

  const tProps = {
    treeData: filteredTreeData,
    value: selectedValues,
    onChange: onChangeEvent,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    treeExpandedKeys: expandedKeys,
    onTreeExpand: setExpandedKeys,
  };

  return (
    <div
      style={{ width, position: 'relative', cursor: 'pointer' }}
      className={`tree-select-custom ${className}`}
    >
      <TreeSelect
        {...tProps}
        treeCheckStrictly={treeCheckStrictly}
        showCheckedStrategy="SHOW_ALL"
        className={className}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        open={open}
        value={selectedValues}
        showSearch={false}
        style={{
          ...style,
          border: open ? '1px solid #4987be' : '',
          width: '100%',
        }}
        dropdownStyle={{
          borderColor: '#4987be',
          border: '1px solid #4987be',
          fontWeight: 'bold',
          color: '#000',
          fontSize: textSize,
          overflow: 'auto',
          minWidth: dropdownWidth,
        }}
        dropdownRender={(menu) => (
          <div style={{ padding: '8px' }}>
            <div style={{ display: 'flex' }}>
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                style={{
                  width: '100%',
                  marginBottom: '8px',
                  marginRight: '0.25rem',
                  padding: '4px',
                  fontSize: textSize,
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
              />
              {showSelectUnselectAll && (
                <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '8px' }}>
                  <button
                    type="button"
                    onClick={handleSelectAll}
                    className="button-select-all"
                    style={{
                      fontSize: textSize,
                    }}
                  >
                    {t('LANGCODES_SELECT_ALL')}
                  </button>
                  <button
                    type="button"
                    onClick={handleDeselectAll}
                    className="button-unselect-all"
                    style={{
                      fontSize: textSize,
                    }}
                  >
                    {t('LANGCODES_DESELECT_ALL')}
                  </button>
                </div>
              )}
            </div>

            {menu}
          </div>
        )}
      />
      <span
        style={{
          position: 'absolute',
          top: labelPosition?.top ?? 5,
          left: labelPosition?.left ?? 10,
          userSelect: 'none',
          zIndex: 1,
          pointerEvents: 'none',
          fontSize: textSize,
        }}
      >
        {label}
      </span>
      <img
        src={arrowIcon}
        alt={t('ARROW_ALT')}
        className={`dropdown-select-arrow ${open && 'dropdown-select-arrow-open'}`}
        data-testid="multiple-select-arrow-img"
        style={{ position: 'absolute', top: 13, right: 10, pointerEvents: 'none' }}
      />
    </div>
  );
};

export default TreeMultiSelect;
