import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DebouncedButton from '../Button/Button';
import { ERROR, HOME } from '../../constants/routes';
import './MyError.scss';
import { t } from '../../i18n/i18n';

const MyError = () => {
  const history = useHistory();
  const { status } = useParams();
  const statuses = ['404', '500'];

  useEffect(() => {
    if (!statuses.includes(status)) {
      history.replace(`${ERROR}/404`);
    }
  }, []);

  return (
    statuses.includes(status) && (
      <div className="error-page-container d-flex flex-column py-5 mx-auto">
        <h2 className="font-weight-bold error-page-text text-center">
          {status === '404' || !status ? t('ERROR_PAGE_NOT_FOUND') : t('ERROR_WE_HAD_A_PROBLEM')}
        </h2>
        <div className="text-center">
          <DebouncedButton
            className="mr-2"
            text={t('ERROR_GO_BACK')}
            onClick={() => history.goBack()}
            size="sm"
          />
          <DebouncedButton
            text={t('ERROR_GO_TO_HOME')}
            onClick={() => history.push(HOME)}
            size="sm"
          />
        </div>
        <img src={`/errors/${status || '404'}.svg`} alt="error" className="w-100 mt-2" />
      </div>
    )
  );
};

export default MyError;
