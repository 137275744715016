import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import userIcon from '../../assets/sidebar/icons/User.svg';
import logo from '../../assets/alkemy/logo.png';
import closeIcon from '../../assets/header/close-icon.svg';
import menuBurger from '../../assets/header/menu-burger.svg';
import { t } from '../../i18n/i18n';
import Dropdown from '../Dropdown/Dropdown';
import DebouncedButton from '../Button/Button';
import { HOME } from '../../constants/routes';
import useSidebar from '../../hooks/useSidebar';
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage';
import { Window } from '../../utils/window';
import { auth0Client } from '../../auth0/auth0';
import 'intro.js/minified/introjs.min.css';
import './Header.scss';
import { setRefs } from '../../redux/actions/uxActions';
import useEnvironment from '../../hooks/useEnvironment';
import CalendarBasicIcon from '../../assets/login/CalendarBasicIcon';
import Video from '../../assets/header/Video';
import DropdownNotification from '../DropdownNotification/DropdownNotification';
import OnboardingIcon from '../../assets/header/Onboarding';

const Header = ({ approved = true, maintenance, open, setOpen }) => {
  const profile = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { collapsed, setCollapsed } = useSidebar();
  const dropdown = useRef(null);

  useEffect(() => {
    dispatch(setRefs({ dropdown }));
  }, [dropdown]);

  const { toggleEnvironment, environmentLoading } = useEnvironment();

  return (
    <header className="header d-flex align-items-center justify-content-between flex-wrap px-xs-2 px-1">
      <div className="d-flex align-items-center flex-wrap py-1">
        <Link to={HOME}>
          <img src={logo} className="logo mr-sm-4" alt={t('HEADER_LOGO')} />
        </Link>
        <div className="d-none d-md-inline header-vertical-line mr-sm-4" />
      </div>
      {!maintenance &&
        (approved ? (
          <div className="d-flex py-1 align-self-center align-self-sm-center align-content-center header-dropdown-max-height">
            <div
              className="d-flex justify-content-center align-items-center"
              id="HEADER_HELP_LINKS"
            >
              <a
                className="schedule-demo-btn-container hoverable-btn mb-1"
                target="_blank"
                rel="noreferrer"
                href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
              >
                <CalendarBasicIcon
                  className="calendar-basic-icon-margin"
                  fillClassname="calendar-basic-icon hoverable-btn"
                />
                <span className="d-none d-md-inline schedule-demo-btn  text-xs font-weight-700 ml-2 hoverable-btn">
                  {t('SCHEDULE_MEETING_ACTION')}
                </span>
              </a>
              <a
                className="schedule-demo-btn-container hoverable-btn ml-3"
                target="_blank"
                rel="noreferrer"
                href={process.env.RAZZLE_RUNTIME_DEMO_TUTORIALS_URL}
              >
                <Video classNames="mt-2 calendar-basic-icon hoverable-btn" />
                <span className="d-none d-md-inline schedule-demo-btn  text-xs font-weight-700 ml-1 hoverable-btn">
                  {t('TUTORIALS_ACTION')}
                </span>
              </a>
              <button
                className="schedule-demo-btn-container hoverable-btn ml-3 demo-onboarding"
                onClick={() => {
                  localStorage.setItem('isOnboarding', 'true');
                  window.location.href = '/';
                }}
              >
                <OnboardingIcon classNames="mt-2 demo-icon hoverable-btn" />
                <span className="d-none d-md-inline schedule-demo-btn  text-xs font-weight-700 ml-1">
                  {t('DEMO_ACTION')}
                </span>
              </button>
            </div>
            <div className="align-self-center">
              <DropdownLanguage className="dashboard-groups-select mx-3 text-xs" />
            </div>
            <div className="align-self-center">
              <DropdownNotification className="dashboard-groups-select mx-1" />
            </div>
            <Dropdown
              className="dashboard-groups-select d-none d-xl-block px-2 text-xs mt-1"
              placeholder={t('HEADER_SEE_ANOTHER_GROUP')}
              open={open}
              setOpen={setOpen}
              options={[
                {
                  icon: userIcon,
                  title: t('MENU_ITEM_PROFILE'),
                  className: 'dropdown-text  font-weight-bold text-xs',
                  link: '/profile',
                  ref: dropdown,
                  id: 'DROPDOWN',
                },
                {
                  icon: closeIcon,
                  title: t('BUTTON_LOGOUT'),
                  className: 'dropdown-text  font-weight-bold text-xs',
                  action: () => {
                    if (Window()) {
                      localStorage.clear();
                      auth0Client().logout({ returnTo: window.location.origin });
                    }
                  },
                },
              ]}
              profile={profile}
            />
            <img
              src={menuBurger}
              aria-hidden="true"
              onClick={() => setCollapsed(!collapsed)}
              className="d-block d-xl-none cursor-pointer p-2"
              alt={t('HEADER_MENU')}
            />
          </div>
        ) : (
          <DebouncedButton
            text={t('BUTTON_LOGOUT')}
            onClick={() => {
              if (Window()) {
                localStorage.clear();
                auth0Client().logout({ returnTo: window.location.origin });
              }
            }}
            load={false}
          />
        ))}
    </header>
  );
};
export default Header;
