import { combineReducers } from 'redux';
import { RESET_STATE } from '../actionTypes';
import buttonReducer from './buttonReducer';
import profilesReducer from './profilesReducer';
import userSummaryReducer from './userSummaryReducer';
import customEmailReducer from './customEmailReducer';
import authReducer from './authReducer';
import screeningsReducer from './screeningsReducer';
import teamsReducer from './teamsReducer';
import uxReducer from './uxReducer';
import evaluationReducer from './evaluationReducer';
import jobsReducer from './jobsReducer';

const appReducer = combineReducers({
  buttons: buttonReducer,
  profiles: profilesReducer,
  userSummary: userSummaryReducer,
  customEmails: customEmailReducer,
  auth: authReducer,
  screenings: screeningsReducer,
  evaluation: evaluationReducer,
  teams: teamsReducer,
  ux: uxReducer,
  jobs: jobsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
