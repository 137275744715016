export const oneDecimal = (num) => Math.round(num * 10) / 10;

export const percent = (num1, num2) => Math.round((num1 * 100) / num2);

export const getDecimalAsInt = (num) => Math.round((num % 1) * 10);

export const getIntegerPart = (num) => Math.trunc(num);

export const minTwoDigits = (num) =>
  num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

export const formatNumberWithThousandsSeparator = (num) => {
  const numStr = num.toString();
  let result = '';
  let count = 0;
  for (let i = numStr.length - 1; i >= 0; i -= 1) {
    result = numStr.charAt(i) + result;
    count += 1;
    if (count % 3 === 0 && i !== 0) {
      result = `.${result}`;
    }
  }
  return result;
};

export const nextMultiple = (value, multiple) => {
  const residue = value % multiple;
  return residue === 0 ? value : Math.round(value + multiple - residue);
};

/**
 * Fixes a number to be within the range of 0 to 100 (inclusive),
 * like a percentage. If the input is less than 0, it returns 0.
 * If the input is greater than 100, it returns 100.
 *
 * @param {number} num - The input number to be fixed.
 * @returns {number} - The fixed number within the range of 0 to 100.
 */
export const fixPercentage = (num) => Math.min(Math.max(num, 0), 100);

/**
 * Formats a number using the German locale with period (.) as the thousands separator.
 *
 * @param {number} number - The number to format.
 * @returns {string} The formatted number as a string.
 */
export function formatNumberGermanLocale(number) {
  if (typeof number !== 'number') return number;
  const formatter = new Intl.NumberFormat('de-DE');
  const formattedNumber = formatter.format(number);
  return formattedNumber;
}

export function formatNumberOfCandidates(candidates) {
  if (candidates <= 150) return '+100';
  if (candidates <= 250) return '+200';
  if (candidates <= 550) return '+500';
  if (candidates <= 1000) return '+1000';
  if (candidates <= 1050) return '+5000';
  return '+10000';
}

/**
 * Checks if a number is within a specified range.
 *
 * @param {Object} params - An object containing the number and range.
 * @param {number} params.num - The number to check.
 * @param {number} params.min - The lower bound of the range.
 * @param {number} params.max - The upper bound of the range.
 * @returns {boolean} - Returns true if num is between min and max (inclusive), otherwise false.
 */
export function isBetween(params) {
  const { num, min, max } = params;
  return num >= min && num <= max;
}
