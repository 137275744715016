import React, { useEffect, useRef } from 'react';
import VerticalDots from '../../assets/dashboard/VerticalDots';
import './VerticalDotsDropdown.scss';
import DebouncedButton from '../Button/Button';

const VerticalDotsDropdown = ({
  options,
  className,
  setOpenDropdown,
  openDropdown,
  disabled = false,
}) => {
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenDropdown]);

  return (
    <div ref={dropdownRef} className="rounded  font-weight-bold non-selectable">
      <div
        onClick={() => {
          if (!disabled) {
            setOpenDropdown(!openDropdown);
          }
        }}
        aria-hidden
        className={`mx-2 d-flex ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        disabled={disabled}
      >
        <VerticalDots className="menu-dots" />
      </div>
      <ul
        className={`vertical-dots-dropdown position-absolute bg-white overflow-auto m-0 ${className} ${
          openDropdown && 'open py-2'
        }`}
      >
        {options.map((item) => (
          <li
            data-testid={`li-${item.testid}`}
            key={item.testid}
            className={`py-1 px-2 w-100 cursor-pointer ${item.itemClass}`}
          >
            {item.customContent ? (
              item.customContent
            ) : (
              <DebouncedButton
                disabled={item.disabled}
                data-testid={item.testid}
                className="dropdown-btn d-flex align-items-center border-0 px-2 w-100"
                onClick={item.action}
                type="button"
              >
                <div className="row no-gutters w-100 justify-content-start align-items-center">
                  <div className="col-2">{item.icon}</div>
                  <div className="col-10">
                    <span className="pl-1  font-weight-500">{item.text}</span>
                  </div>
                </div>
              </DebouncedButton>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VerticalDotsDropdown;
