const Hr = () => (
  <svg
    width="177"
    height="177"
    viewBox="0 0 177 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="home-plan-image"
  >
    <circle cx="88.5" cy="88.5" r="88.5" fill="white" />
    <circle cx="88.6438" cy="88.6438" r="72.1438" stroke="#272727" />
    <path
      d="M42.2051 108.104L74.0459 40.123H83.8501L88.5262 50.1038L93.2862 60.2664L115.691 108.104H106.166L88.5262 70.4355L83.7661 60.2728L78.948 49.9869L51.7308 108.104H42.2051Z"
      fill="#272727"
    />
    <path
      d="M61.3152 108.104L93.1555 40.123H102.96L107.636 50.1038L112.396 60.2664L134.795 108.091L125.285 108.123L107.636 70.4355L102.876 60.2728L98.0577 49.9869L70.8404 108.104H61.3152Z"
      fill="#272727"
    />
    <path
      d="M86.256 120.923H87.072V132.123H86.256V120.923ZM78.768 132.123H77.952V120.923H78.768V132.123ZM86.352 126.795H78.672V126.075H86.352V126.795ZM90.9833 132.123V120.923H94.9993C95.9379 120.923 96.7433 121.072 97.4153 121.371C98.0873 121.67 98.6046 122.102 98.9673 122.667C99.3299 123.222 99.5113 123.899 99.5113 124.699C99.5113 125.478 99.3299 126.15 98.9673 126.715C98.6046 127.27 98.0873 127.702 97.4153 128.011C96.7433 128.31 95.9379 128.459 94.9993 128.459H91.4313L91.7993 128.075V132.123H90.9833ZM98.7593 132.123L95.8473 128.059H96.7753L99.6873 132.123H98.7593ZM91.7993 128.123L91.4313 127.739H94.9993C96.2046 127.739 97.1166 127.472 97.7353 126.939C98.3646 126.406 98.6793 125.659 98.6793 124.699C98.6793 123.728 98.3646 122.982 97.7353 122.459C97.1166 121.926 96.2046 121.659 94.9993 121.659H91.4313L91.7993 121.259V128.123Z"
      fill="#272727"
    />
  </svg>
);

export default Hr;
