import { LoadingOutlined } from '@ant-design/icons';
import { Radio, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import DebouncedButton from '../../Button/Button';
import { Currencies, PlanType, Regions, TimeFrequency } from '../../../constants/enums';
import { t } from '../../../i18n/i18n';
import { formatCurrency, getPlanPrice } from '../../../utils/plans/price';
import Feature from './Feature/Feature';
import Price from './Price/Price';

const Plan = (props) => {
  const {
    plan,
    onClickBuy,
    showBuyButton,
    loading,
    isInternationalPrice,
    setModalModality,
    setSelectedPlan,
    companySize,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [modality, setModality] = useState(TimeFrequency.ANNUAL);

  const plansToShow = plan?.data?.filter((x) => x.modality == modality);

  const onlyHasOneCompanySize = plansToShow?.length == 1;

  const planFilteredByCompanySize =
    plansToShow?.find((x) => x.companySizeId == companySize) ||
    plan?.data?.find((x) => x.companySizeId == companySize);

  const planToShow = onlyHasOneCompanySize ? plansToShow[0] : planFilteredByCompanySize;

  useEffect(() => {
    if (planToShow) setSelectedPlan(planToShow);
  }, [plan, companySize, planToShow]);
  useEffect(() => {
    if (planToShow) setSelectedPlan(planToShow);
  }, []);

  const planPricesToShow = planToShow?.prices ?? [];

  // Filtrar el objeto plan para obtener solo los elementos donde visible es diferente de 0
  const filteredFeatures = planToShow?.features?.filter((item) => item.feature?.visible !== 0);

  // Crear un nuevo objeto con las características filtradas
  const filteredPlan = { ...planToShow, features: filteredFeatures };
  filteredPlan?.features?.sort((a, b) => a.feature.order - b.feature.order);

  const MAX_FEATURES = 5;
  const initialFeatures = filteredPlan?.features?.slice(0, MAX_FEATURES);
  const hiddenFeatures = filteredPlan?.features?.slice(MAX_FEATURES);

  const priceInArs = planPricesToShow?.find((x) => x.currency == Currencies.ARS);
  const priceInUsd = planPricesToShow?.find((x) => x.currency == Currencies.USD);

  const priceToShow = isInternationalPrice ? priceInUsd : priceInArs;

  const plansWithSameCompanySize = plan?.data?.filter((x) => x.companySizeId == companySize);

  const monthlyPrice = plansWithSameCompanySize
    ?.find((x) => x.modality == TimeFrequency.MONTHLY)
    ?.prices?.find((x) =>
      isInternationalPrice ? x.currency == Currencies.USD : x.currency == Currencies.ARS
    );

  const annualPrice = plansWithSameCompanySize
    ?.find((x) => x.modality == TimeFrequency.ANNUAL)
    ?.prices?.find((x) =>
      isInternationalPrice ? x.currency == Currencies.USD : x.currency == Currencies.ARS
    );

  const annualMonthlyPayment = monthlyPrice?.priceInCents * 12;
  const annualPayment = annualPrice?.priceInCents * 12;

  const differenceBetweenPayments = annualMonthlyPayment - annualPayment;

  const companySizes = plansToShow
    ?.map((x) =>
      x.minCompanyMembers
        ? {
            label: `${!x.maxCompanyMembers ? '+' : ''}${x.minCompanyMembers} ${
              x.maxCompanyMembers ? `- ${x.maxCompanyMembers}` : ''
            } ${t('PLANS_PLAN_COMPANY_EMPLOYEES_LABEL')}`,
            value: x.minCompanyMembers,
          }
        : null
    )
    ?.filter((x) => x);

  return (
    <Accordion style={{ width: '100%' }}>
      <div className="d-flex flex-column align-items-center w-100">
        <span
          className=" text-xs font-weight-700"
          style={{ color: `#${plan.color}`, textAlign: 'center' }}
        >
          {plan.name}
        </span>
        <div
          className="d-flex flex-column align-items-center custom-column row m-0 w-100"
          style={{ border: `1px solid #${plan.color}` }}
        >
          <section className="p-2 d-flex flex-column align-items-center custom-column row m-0 w-100">
            <span className="py-2  text-sm font-weight-700 text-center text-nowrap w-100">
              {companySizes?.length ? (
                <div className="d-flex flex-column align-items-start">
                  <div className="my-2 w-100">
                    {annualMonthlyPayment ? (
                      <Radio.Group
                        value={modality}
                        onChange={(e) => {
                          setModality(e.target.value);
                          setModalModality(e.target.value);
                        }}
                        style={{
                          display: 'flex',
                          width: '100%',
                          borderRadius: '10px',
                        }}
                      >
                        <Radio.Button
                          ghost
                          value={TimeFrequency.ANNUAL}
                          className={`${
                            modality == TimeFrequency.ANNUAL
                              ? 'text-blue-principal border-dark-maya-blue'
                              : ''
                          } `}
                          style={{
                            fontWeight: modality == TimeFrequency.ANNUAL ? 'bold' : 'normal',
                            height: 'auto',
                            width: '100%',
                            lineHeight: differenceBetweenPayments ? 'normal' : '',
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                          }}
                        >
                          {t('PLANS_PLAN_MODALITY_ANNUAL_LABEL')}
                          <div className="text-xs">
                            {differenceBetweenPayments ? (
                              <div className="d-flex flex-row align-items-center justify-content-center">
                                <div className="mr-1">{t('PLANS_PLAN_PAYMENT_SAVE_LABEL')}</div>
                                <div>
                                  {` ${formatCurrency(
                                    {
                                      priceInCents: Math.abs(differenceBetweenPayments),
                                      currency: isInternationalPrice
                                        ? Currencies.USD
                                        : Currencies.ARS,
                                    },
                                    isInternationalPrice ? Regions.USA : Regions.ARGENTINA
                                  )}`}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Radio.Button>

                        <Radio.Button
                          ghost
                          value={TimeFrequency.MONTHLY}
                          className={`${
                            modality == TimeFrequency.MONTHLY
                              ? 'text-blue-principal border-dark-maya-blue'
                              : ''
                          } `}
                          style={{
                            fontWeight: modality == TimeFrequency.MONTHLY ? 'bold' : 'normal',
                            height: 'auto',
                            alignContent: 'start',
                            width: '100%',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                          }}
                        >
                          {t('PLANS_PLAN_MODALITY_MONTHLY_LABEL')}
                        </Radio.Button>
                      </Radio.Group>
                    ) : planToShow?.type == PlanType.SUBSCRIPTION ? (
                      <div className="" style={{ marginTop: '2em', marginBottom: '2em' }} />
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="my-4">
                {!planPricesToShow || !planPricesToShow.length
                  ? t('NEGOTIABLE_PRICE_MESSAGE')
                  : planPricesToShow.length && (
                      <>
                        {isInternationalPrice ? (
                          <Price price={getPlanPrice(priceInUsd)} currency={priceInUsd?.currency} />
                        ) : (
                          <Price
                            price={getPlanPrice(priceInArs, Regions.ARGENTINA)}
                            currency={priceInArs?.currency}
                          />
                        )}
                        <div>
                          {planToShow?.type == PlanType.PROJECT ? (
                            <div className="d-flex flex-column">
                              <span className=" text-xs font-weight-600">
                                {t('PAYMENT_UNIQUE_PAYMENT_LABEL')}
                              </span>
                              <span className=" text-xs font-weight-500 my-2">
                                {`${t('PAYMENT_PLAN_DURATION_LABEL')} ${
                                  priceToShow?.renewalFrequencyInDays
                                } ${t('PAYMENT_PLAN_DURATION_DAYS_LABEL')}`}
                              </span>
                            </div>
                          ) : (
                            <span className=" text-xs font-weight-600">
                              {t('PRICE_PER_MONTH_LABEL')}
                            </span>
                          )}
                        </div>
                        <div>
                          {modality == TimeFrequency.ANNUAL &&
                          planToShow?.type == PlanType.SUBSCRIPTION ? (
                            <span className=" text-xs font-weight-600">
                              {t('PAYMENT_UNIQUE_PAYMENT_TOTAL_LABEL')}
                            </span>
                          ) : (
                            <div
                              className=""
                              style={{ paddingTop: '0.75em', paddingBottom: '0.75em' }}
                            />
                          )}
                        </div>
                      </>
                    )}
              </div>
            </span>
            {showBuyButton ? (
              <DebouncedButton
                dataTestId="payment-buy-button"
                variant=""
                className="custom-column w-100 px-4 py-2 text-white hoverable-btn  text-xs font-weight-700 text-center border-0"
                customFont=""
                text={
                  loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 24,
                            color: 'white',
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    t('PLANS_BUY_PLAN_LABEL')
                  )
                }
                onClick={() => {
                  onClickBuy(planToShow?.id, modality);
                }}
                style={{ backgroundColor: `#${plan.color}` }}
                size="sm"
              />
            ) : (
              <DebouncedButton
                dataTestId="included-plan-button"
                variant=""
                className="custom-column w-100 px-4 py-2 text-white   text-xs font-weight-700 text-center border-0 cursor-normal"
                customFont=""
                text={t('PLANS_BY_DEFAULT')}
                disabled
                style={{ backgroundColor: `#${plan.color}` }}
                size="sm"
              />
            )}
          </section>

          {initialFeatures && initialFeatures.length
            ? initialFeatures.map((f) => (
                <Feature key={`feature-${f.id}`} plan={planToShow} planToFeature={f} />
              ))
            : null}
          {hiddenFeatures && hiddenFeatures.length ? (
            <div className="w-100">
              <Accordion.Collapse eventKey="0" id="collapsedFeatures">
                <>
                  {hiddenFeatures.map((f) => (
                    <Feature key={`feature-${f.id}`} plan={plan} planToFeature={f} />
                  ))}
                </>
              </Accordion.Collapse>
            </div>
          ) : null}
          {hiddenFeatures && hiddenFeatures.length ? (
            <div
              className="d-flex flex-column align-items-center text-center row m-0 py-2 px-4 w-100  text-xs font-weight-700 text-secondary"
              style={{ borderTop: `1px solid #${plan.color}` }}
            >
              <Accordion.Toggle
                eventKey="0"
                style={{ border: 'none', backgroundColor: 'white' }}
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                {isOpen ? (
                  <FiChevronUp color={`#${plan.color}`} size="2em" />
                ) : (
                  <FiChevronDown color={`#${plan.color}`} size="2em" />
                )}
              </Accordion.Toggle>
            </div>
          ) : null}
        </div>
      </div>
    </Accordion>
  );
};

Plan.propTypes = {};

export default Plan;
